import { useEffect, memo, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { message } from 'antd'

import request from '@/utils/request'
import { orderlyTypeMap, orderlyTypeTextMap } from '@/utils/consts'
import { getKeyByVal } from '@/utils/util'
import { useAppSelector, useAppDispatch } from '@/utils/hooks'
import { currentFence } from '@/redux/reducers/app'

import { patrolAreaOrderlyIcon, clusterMaxZoom } from '@/pages/dataCenter/center/map/consts'

function ClusterBubble(options) {
  TMap.DOMOverlay.call(this, options)
}

ClusterBubble.prototype = new TMap.DOMOverlay()

ClusterBubble.prototype.onInit = function (options) {
  this.content = options.content
  this.position = options.position
  this.orderlyResult = options.orderlyResult
}

ClusterBubble.prototype.onDestroy = function () {
  this.dom.removeEventListener('click', this.onClick)
  this.removeAllListeners()
}

ClusterBubble.prototype.onClick = function () {
  this.emit('click')
}

ClusterBubble.prototype.createDOM = function () {
  let dom = document.createElement('div')
  let digits = (this.content + '').length
  dom.classList.add('patrol-area-cluster-bubble')
  dom.classList.add('columnCenter')
  dom.classList.add('digits-' + digits)
  dom.classList.add('patrol-area-cluster-bubble-' + getKeyByVal(orderlyTypeMap, this.orderlyResult))
  dom.innerHTML = `
  <div style="font-size: 16px; line-height: 16px; margin-top: 1px">${this.content}</div>
  `
  // 监听点击事件，实现zoomOnClick
  this.onClick = this.onClick.bind(this)
  dom.addEventListener('click', this.onClick)
  return dom
}

ClusterBubble.prototype.updateDOM = function () {
  if (!this.map) {
    return
  }
  // 经纬度坐标转容器像素坐标
  let pixel = this.map.projectToContainer(this.position)

  // 使文本框中心点对齐经纬度坐标点
  let left = pixel.getX() - this.dom.clientWidth / 2 + 'px'
  let top = pixel.getY() - this.dom.clientHeight / 2 + 'px'
  this.dom.style.transform = `translate(${left}, ${top})`

  this.emit('dom_updated')
}

let infoWindow

const initInfoWindow = map => {
  if (!infoWindow) {
    infoWindow = new TMap.InfoWindow({
      map: map,
      zIndex: 1,
      position: new TMap.LatLng(39.984104, 116.307503),
      offset: { x: 0, y: -22 },
      enableCustom: true,
      content: '<div class="patrol-info-card"></div>',
    })
    infoWindow.close()
  }
}

const getStyleId = geometry => {
  const { orderlyResult } = geometry
  return getKeyByVal(orderlyTypeMap, orderlyResult) || 'orderly'
}

const getMarkerStyles = searched => {
  const icons = { ...patrolAreaOrderlyIcon }
  return Object.keys(icons).reduce((prev, next) => {
    prev[next] = new TMap.MarkerStyle({
      width: searched ? 60 : 40,
      height: searched ? 60 : 40,
      anchor: {
        x: searched ? 30 : 20,
        y: searched ? 30 : 20,
      },
      src: icons[next].icon,
    })
    return prev
  }, {})
}

const getOrderedKeyAreaInfo = keyAreaInfo => {
  const orderly = []
  const unOrderly = []
  keyAreaInfo.forEach(item => {
    if (item.orderlyResult === orderlyTypeMap.orderly || !item.orderlyResult) {
      orderly.push(item)
    } else if (item.orderlyResult === orderlyTypeMap.unorderly) {
      unOrderly.push(item)
    }
  })
  return [orderly, unOrderly]
}

const getAllGeometries = orderedKeyAreaInfo => {
  return orderedKeyAreaInfo.map((keyAreas, idx) => {
    const geometries = keyAreas.map(area => {
      const [lat, lng] = [area.centerPoint?.[1], area.centerPoint?.[0]]
      return {
        ...area,
        lat: lat,
        lng: lng,
        position: new TMap.LatLng(lat, lng),
      }
    })
    return geometries
  })
}

const getPolygonStyle = () => {
  return Object.keys(patrolAreaOrderlyIcon).reduce((prev, next) => {
    prev[next] = new TMap.PolygonStyle({
      color: patrolAreaOrderlyIcon[next].color, //面填充色
      showBorder: true, //是否显示拔起面的边线
      borderColor: patrolAreaOrderlyIcon[next].border,
      borderWidth: 2,
      borderDashArray: next.dashed ? [5, 5] : [0, 0],
    })
    return prev
  }, {})
}

const KeyAreaLayer = ({ map, keyAreaInfo, searched = false }) => {
  const dispatch = useAppDispatch()
  const [app] = useAppSelector(state => [state.app])

  const [popupContainer, setPopupContainer] = useState(null)
  const [selMarker, setSelMarker] = useState({})

  const allGeometries = useMemo(() => {
    const orderedKeyAreaInfo = getOrderedKeyAreaInfo(keyAreaInfo)
    const allGeometries = getAllGeometries(orderedKeyAreaInfo)
    return allGeometries
  }, [keyAreaInfo])

  useEffect(() => {
    if (map) {
      initInfoWindow(map)
    }
  }, [map])

  useEffect(() => {
    if (map && allGeometries.length) {
      return renderKeyAreaLayer()
    }
  }, [map, allGeometries])

  useEffect(() => {
    if (searched && keyAreaInfo.length) {
      const area = keyAreaInfo[0]
      const [lat, lng] = [area.centerPoint?.[1], area.centerPoint?.[0]]
      const geometry = {
        ...area,
        lat: lat,
        lng: lng,
        position: new TMap.LatLng(lat, lng),
      }
      const evt = {
        geometry: geometry,
      }

      map.easeTo({
        center: new TMap.LatLng(lat + 0.0003, lng),
        zoom: clusterMaxZoom + 1,
      })

      // setTimeout(() => {
      //   if (evt.geometry.overviewId) {
      //     queryKeyAreaDetail(evt)
      //   }
      // }, 500)
    }
  }, [searched, keyAreaInfo])

  const renderKeyAreaLayer = () => {
    const allClusterLayerClear = allGeometries.map((geometries, idx) => {
      return renderMarkerCluster(geometries)
    })
    return () => {
      if (allClusterLayerClear.length) {
        allClusterLayerClear.forEach(fn => fn())
      }
    }
  }

  const handleMarkerClick = evt => {
    if (!evt?.geometry?.overviewId) {
      message.info('暂无巡检信息')
      return
    }
    dispatch(currentFence({ selectFenceId: evt.geometry.fencingId }))
    queryKeyAreaDetail(evt)
  }

  const queryKeyAreaDetail = evt => {
    request('patrolCenterQueryKeyAreaDetail', {
      data: { entityId: app.currentEntity.entityId, overviewId: evt.geometry.overviewId },
    }).then(res => {
      if (res.code === 0 && res.data) {
        const popupDiv = '<div id="patrol-key-area-popup"></div>'
        infoWindow.show = true
        infoWindow.setMap(map)
        infoWindow.setOffset({ x: 0, y: searched ? -28 : -22 })
        infoWindow.setContent(popupDiv)
        infoWindow.open()
        setPopupContainer(document.getElementById('patrol-key-area-popup'))
        setSelMarker({
          ...res.data,
          fencingId: evt.geometry.fencingId,
          reportTime: evt.geometry.reportTime,
          fencingName: evt.geometry.fencingName,
          orderlyResult: evt.geometry.orderlyResult,
        })
        infoWindow.setPosition(evt.geometry.position)
      }
    })
  }

  const renderMarkerCluster = geometries => {
    let markerCluster = null
    let marker = null
    let fenceLayer = null
    let labelLayer = null
    let markerGeometries = []
    let clusterBubbleList = []

    // 重新创建点聚合
    markerCluster = new TMap.MarkerCluster({
      id: 'cluster' + Math.random(),
      map: map,
      enableDefaultStyle: false,
      minimumClusterSize: 3,
      geometries: geometries,
      zoomOnClick: true,
      gridSize: 120,
      averageCenter: false,
      maxZoom: 18,
    })

    markerCluster.on('cluster_changed', function (e) {
      if (clusterBubbleList.length) {
        clusterBubbleList.forEach(function (item) {
          item.setMap(null)
          item.destroy()
        })
        clusterBubbleList = []
      }
      markerGeometries = []

      let clusters = markerCluster ? markerCluster.getClusters() : []
      clusters.forEach(function (item) {
        if (item.geometries.length > 1) {
          let clusterBubble = new ClusterBubble({
            map,
            position: item.center,
            content: item.geometries.length,
            orderlyResult: item.geometries[0].orderlyResult,
          })
          clusterBubble.on('click', () => {
            map.fitBounds(item.bounds)
          })
          clusterBubbleList.push(clusterBubble)
        } else {
          const geometry = item.geometries[0]
          markerGeometries.push({
            ...geometry,
            position: item.center,
            styleId: getStyleId(geometry),
          })
        }
      })

      if (marker) {
        marker.setGeometries(markerGeometries)
      } else {
        marker = new TMap.MultiMarker({
          map,
          styles: getMarkerStyles(searched),
          geometries: markerGeometries,
          zIndex: searched ? 5 : 1,
        })
        marker.on('click', handleMarkerClick)
      }

      const fenceGeometries = markerGeometries.map(geometry => {
        return {
          styleId: getKeyByVal(orderlyTypeMap, geometry.orderlyResult) || 'orderly',
          paths: geometry.region,
        }
      })
      if (fenceLayer) {
        fenceLayer.setGeometries(fenceGeometries)
      } else {
        fenceLayer = new TMap.MultiPolygon({
          id: 'parking-layer' + Math.random(),
          map: map,
          geometries: fenceGeometries,
          styles: getPolygonStyle(),
        })
      }

      const labelGeometries = markerGeometries.map(geometry => {
        return {
          styleId: 'label',
          position: geometry.position,
          content: geometry.fencingName,
        }
      })
      if (labelLayer) {
        labelLayer.setGeometries(labelGeometries)
      } else {
        labelLayer = new TMap.MultiLabel({
          id: 'label-layer' + Math.random(),
          map: map,
          geometries: labelGeometries,
          styles: {
            label: new TMap.LabelStyle({
              color: '#BCCBE0',
              size: 12,
              offset: { x: 0, y: 20 },
              angle: 0,
              alignment: 'center',
              verticalAlignment: 'middle',
            }),
          },
        })
      }
    })

    return () => {
      if (clusterBubbleList.length) {
        clusterBubbleList.forEach(function (item) {
          item.setMap(null)
          item.destroy()
        })
        clusterBubbleList = []
      }
      if (markerCluster) {
        markerCluster.setGeometries([])
        markerCluster.setMap(null)
        markerCluster = null
      }
      if (marker) {
        marker.setGeometries([])
        marker.off('click', handleMarkerClick)
        marker.setMap(null)
        marker = null
      }
      if (fenceLayer) {
        fenceLayer.setGeometries([])
        fenceLayer.setMap(null)
        fenceLayer = null
      }
      if (labelLayer) {
        labelLayer.setGeometries([])
        labelLayer.setMap(null)
        labelLayer = null
      }
      if (infoWindow && infoWindow.show) {
        closeBikeInfoWindow()
      }
    }
  }

  const infoOnmousemove = e => {
    e?.stopPropagation && e.stopPropagation()
  }

  const closeBikeInfoWindow = () => {
    infoWindow.show = false
    infoWindow?.close()
    infoWindow?.setMap(null)
    setPopupContainer(null)
    dispatch(currentFence({ selectFenceId: undefined }))
  }

  const renderInfoWindowContent = () => {
    return (
      <div
        className="mtFontLight relative fz16 grayFont07 patrol-info-card"
        onMouseMove={infoOnmousemove}
      >
        <div className="patrol-fence-name">{selMarker.fencingName}</div>
        <div className="mb12">
          <span className="label">巡查结果</span>
          <span
            className="value"
            style={{ color: selMarker.orderlyResult === orderlyTypeMap.unorderly ? '#ff4d4f' : '' }}
          >
            {orderlyTypeTextMap[selMarker.orderlyResult]}
          </span>
        </div>
        <div className="mb12">
          <span className="label">巡查时间</span>
          <span className="value">{selMarker.reportTime}</span>
        </div>
        <div className="mb12">
          <span className="label">有效巡视数</span>
          <span className="value">{selMarker.effectiveTimes}</span>
        </div>
        <div className="mb12">
          <span className="label">秩序整齐次数</span>
          <span className="value">{selMarker.orderlyTimes}</span>
        </div>
        <div className="mb12">
          <span className="label">秩序整齐占比</span>
          <span className="value">{selMarker.proportion}%</span>
        </div>
        <div
          style={{
            width: 15,
            height: 15,
            position: 'absolute',
            right: 3,
            top: 2,
            cursor: 'pointer',
          }}
          onClick={closeBikeInfoWindow}
        >
          <svg
            style={{ fill: '#aaa', overflow: 'hidden' }}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M579.888 512l190.064-190.064a48 48 0 0 0-67.888-67.872L512 444.112 321.936 254.064a48 48 0 1 0-67.872 67.872L444.112 512 254.064 702.064a48 48 0 1 0 67.872 67.872L512 579.888l190.064 190.064a48 48 0 0 0 67.872-67.888L579.888 512z"
              fill=""
            ></path>
          </svg>
        </div>
        <div className="triangle"></div>
      </div>
    )
  }

  return <>{popupContainer !== null && createPortal(renderInfoWindowContent(), popupContainer)}</>
}

export default memo(KeyAreaLayer)
