import OrderStatistics from './OrderStatistics'
import PatrolResult from './PatrolResult'

import './index.less'

const Left = () => {
  return (
    <div
      id="left-box"
      style={{ border: '1px solid rgba(75, 210, 222, 0.4)', borderRadius: '12px' }}
    >
      <OrderStatistics></OrderStatistics>
      <PatrolResult></PatrolResult>
    </div>
  )
}

export default Left
