import React, { useMemo } from 'react'
import { Breadcrumb } from 'antd'
import { useLocation, Link } from 'react-router-dom'

import { findTreeNode } from '@/utils/util'

import type { MenuProps } from 'antd'

type MenuItem = Required<MenuProps>['items'][number]

interface Props {
  route: Route
}

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  return {
    label,
    key,
    icon,
    children,
    type,
  } as MenuItem
}

const getMenus = (routes: Routes): any => {
  return routes.map(route => {
    if (route.name) {
      return getItem(
        route.routes ? route.name : <Link to={route.path}>{route.name}</Link>,
        route.path,
        null,
        route.routes ? getMenus(route.routes) : undefined
      )
    }
  })
}

const IBreadcrumb: React.FC<Props> = ({ route }) => {
  const location = useLocation()
  const pathSnippets = location.pathname.split('/').filter(i => i)

  const getRoute = (url: string) => {
    return findTreeNode([route], 'path', url, 'routes')
  }

  const breadcrumbItems = useMemo(() => {
    return pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
      const route = getRoute(url)
      return route?.routes ? (
        <Breadcrumb.Item
          dropdownProps={{ overlayStyle: { zIndex: 9999 } }}
          key={url}
          menu={{ items: getMenus(route.routes) }}
        >
          {route?.name}
        </Breadcrumb.Item>
      ) : (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{route?.name}</Link>
        </Breadcrumb.Item>
      )
    })
  }, [pathSnippets])

  return <Breadcrumb className="mb16">{breadcrumbItems}</Breadcrumb>
}

export default IBreadcrumb
