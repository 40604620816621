import PassengerCount from './PassengerCount'
import PassengerTrend from './PassengerTrend'

const Left = () => {
  return (
    <div
      id="left-box"
      style={{ border: '1px solid rgba(75, 210, 222, 0.4)', borderRadius: '12px' }}
    >
      <PassengerCount></PassengerCount>
      <PassengerTrend></PassengerTrend>
    </div>
  )
}

export default Left
