/**
 * 角色类型选项框
 */
import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

import request from '@/utils/request'

import type { SelectProps } from 'antd/es/select'
import type { FormItemProps } from 'antd/es/form'

export interface RoleTypeSelectProps extends SelectProps {
  formItemProps?: FormItemProps
  childProps?: SelectProps
}

const RoleTypeSelect: React.FC<RoleTypeSelectProps> = ({ childProps = {}, formItemProps = {} }) => {
  const [options, setOptions] = useState<Options>([])

  const queryList = () => {
    request('commonQueryRoleTypeOpts').then(res => {
      const { data = [], code } = res
      code == 0 && data?.length && setOptions(res.data)
    })
  }

  useEffect(() => {
    queryList()
  }, [])

  return (
    <Form.Item {...formItemProps}>
      <Select
        allowClear
        showSearch
        optionFilterProp="label"
        placeholder="请选择"
        options={options}
        {...childProps}
      ></Select>
    </Form.Item>
  )
}

export default RoleTypeSelect
