import { useState, useEffect, useRef } from 'react'
import { Modal } from 'antd'

import { MapPoiReset } from '@/components'

import './index.less'

interface Props {
  text?: string
  center: LngLat
  onCancel?: () => void
}

const initZoom = 16

const MapPosition: React.FC<Props> = ({ text = '查看位置', center, onCancel }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [visible, setVisible] = useState(false)
  const [map, setMap] = useState<any>(null)
  const markerLayerRef = useRef<any>(null)

  useEffect(() => {
    if (containerRef.current && visible) {
      const mapClear = initMap()
      return () => {
        mapClear?.()
      }
    }
  }, [visible])

  useEffect(() => {
    if (map) {
      const markerClear = initMarker()
      return () => {
        markerClear?.()
      }
    }
  }, [map])

  useEffect(() => {
    if (map && visible && center) {
      setMarkerGeometries()
    }
  }, [center, visible, map])

  const initMap = () => {
    const map = new TMap.Map(containerRef.current!, {
      center: new TMap.LatLng(center?.[1] || 39.910993, center?.[0] || 116.397617),
      zoom: initZoom,
      // @ts-ignore
      zooms: [10, 20],
      scrollable: false,
    })
    setMap(map)
    return () => {
      setMap(null)
    }
  }

  const initMarker = () => {
    markerLayerRef.current = new TMap.MultiMarker({
      map: map,
      styles: {
        marker: new TMap.MarkerStyle({
          width: 20,
          height: 30,
          anchor: { x: 10, y: 30 },
        }),
      },
      geometries: [],
    })
    return () => {
      markerLayerRef.current.setMap(null)
      markerLayerRef.current = null
    }
  }

  const setMarkerGeometries = () => {
    const geometries = [{ position: new TMap.LatLng(center[1], center[0]) }]
    markerLayerRef.current.setMap(map).setGeometries(geometries)
  }

  const handleCancel = () => {
    setVisible(false)
    onCancel?.()
  }

  return (
    <>
      <a className="pointer" onClick={() => setVisible(true)}>
        {text}
      </a>
      {visible && (
        <Modal
          title="详情"
          width={800}
          keyboard={false}
          maskClosable={false}
          open={visible}
          onOk={handleCancel}
          onCancel={handleCancel}
        >
          <div className="map-position">
            <div className="map-position-container" ref={containerRef}></div>
            <MapPoiReset zoom={initZoom} center={center} map={map}></MapPoiReset>
          </div>
        </Modal>
      )}
    </>
  )
}

export default MapPosition
