import { useEffect, useState } from 'react'
import CountUp from 'react-countup'

import ContentBox from '../../contentBox'

import './index.less'

const subwayViolationStatistics = require('_root/mock/subwayCenter/subwayCenterShowTop.json')

const SingleContent = () => {
  const [enterSubwayToday, setEnterSubwayToday] = useState(
    subwayViolationStatistics.data.inflowCount.value
  )

  const [outSubwayToday, setOutSubwayToday] = useState(
    subwayViolationStatistics.data.operateCarCount.value
  )
  const [operatingGrossToday, setOperatingGrossToday] = useState(
    subwayViolationStatistics.data.outflowCount.value
  )
  const [ridingQuantityToday, setRidingQuantityToday] = useState(
    subwayViolationStatistics.data.rideUserCount.value
  )
  const [prevEnterSubwayToday, setPrevEnterSubwayToday] = useState(0)
  const [prevOutSubwayToday, setPrevOutSubwayToday] = useState(0)
  const [prevOperatingGrossToday, setPrevOperatingGrossToday] = useState(0)
  const [PrevRidingQuantityToday, setPrevRidingQuantityToday] = useState(0)

  useEffect(() => {
    const returnMathNum = (num: number) => Math.floor(Math.random() * num)
    const timer = setInterval(() => {
      setEnterSubwayToday((prevCount: any) => {
        const n = returnMathNum(10001)
        setPrevEnterSubwayToday(n)
        const newValue = prevCount + n
        return newValue < 999999999 ? newValue : prevCount
      })
      setOutSubwayToday((prevCount: any) => {
        const n = returnMathNum(1000)
        const newValue = prevCount + n
        setPrevOutSubwayToday(n)
        return newValue < 999999999 ? newValue : prevCount
      })
      setOperatingGrossToday((prevCount: any) => {
        const n = returnMathNum(10001)
        const newValue = prevCount + n
        setPrevOperatingGrossToday(n)
        return newValue < 999999999 ? newValue : prevCount
      })
      setRidingQuantityToday((prevCount: any) => {
        const n = returnMathNum(2000)
        setPrevRidingQuantityToday(n)
        const newValue = prevCount + n
        return newValue < 999999999 ? newValue : prevCount
      })
    }, 3000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const centerTopDiv = (objDetail: any, quantity: number, prevNum: number) => {
    const numberString = quantity.toString()
    const groups = []
    const statrArrs: string[] = []
    const startNum = (+numberString - prevNum).toString()
    for (let i = numberString.length; i > 0; i -= 3) {
      const group = numberString.slice(Math.max(0, i - 3), i)
      groups.unshift(group)
    }
    for (let i = startNum.length; i > 0; i -= 3) {
      const statrArr = startNum.slice(Math.max(0, i - 3), i)
      statrArrs.unshift(statrArr)
    }
    return (
      <div className="centerTop" style={{ width: '100%' }}>
        <div className="centerTopText">
          {objDetail.label} ({objDetail.measure})
        </div>
        <div className="centerTopNum  mt10">
          {groups.map((item, index) => {
            return <>{typeface(item, statrArrs[index])}</>
          })}
        </div>
      </div>
    )
  }

  const typeface = (num: string, startNum: string) => {
    return (
      <div style={{ position: 'relative' }}>
        <span className="typeface centerCenter">
          <CountUp style={{ marginTop: '5px' }} start={+startNum} end={+num} duration={1} />
        </span>
        <div className="typeface-intercept-line"></div>
      </div>
    )
  }

  return (
    <div className="centerShow" style={{ zIndex: '10' }}>
      <ContentBox
        showBiz={false}
        showTitle={false}
        style={{
          width: '100%',
          border: '1px solid rgba(75, 210, 222, 0.4)',
        }}
      >
        <div className="centerSign-left"></div>
        <div className="centerSign-right"></div>
        <div className="centerSign-gradual"></div>
        <div className="betweenCenter">
          {centerTopDiv(
            subwayViolationStatistics.data.inflowCount,
            enterSubwayToday,
            prevEnterSubwayToday
          )}
          {centerTopDiv(
            subwayViolationStatistics.data.outflowCount,
            operatingGrossToday,
            prevOperatingGrossToday
          )}
          {centerTopDiv(
            subwayViolationStatistics.data.operateCarCount,
            outSubwayToday,
            prevOutSubwayToday
          )}
          {centerTopDiv(
            subwayViolationStatistics.data.rideUserCount,
            ridingQuantityToday,
            PrevRidingQuantityToday
          )}
          {/* {Object.keys(subwayViolationStatistics.data).map((item, index) => {
            return (
              <div key={index} className="centerShowTop">
                {centerTopDiv(subwayViolationStatistics.data[item])}
              </div>
            )
          })} */}
        </div>
      </ContentBox>
    </div>
  )
}

export default SingleContent
