import { useState, useRef, useEffect, useContext } from 'react'
import throttle from 'lodash/throttle'
import * as echarts from 'echarts'
import ReactDOMServer from 'react-dom/server'

import { Context } from '../index'
import { useAppSelector, useAppDispatch, useTimingRequest } from '@/utils/hooks'
import request from '@/utils/request'
import { businessLineMap } from '@/utils/consts'
import { currentArea } from '@/redux/reducers/app'
import type { PatrolMapAreaData } from '../type'

import Legend from './Legend'
import AreaMapControl from './AreaMapControl'

import './index.less'

// 无实体渐变色
const areaColor = {
  type: 'linear',
  x: 0,
  y: 0,
  x2: 1,
  y2: 1,
  colorStops: [
    {
      offset: 0,
      color: '#465A7D', // 0% 处的颜色
    },
    {
      offset: 1,
      color: '#465A7D', // 100% 处的颜色
    },
  ],
  global: false, // 缺省为 false
}

// 无实体hover背景色
const areaColorHover = {
  type: 'linear',
  x: 0,
  y: 0,
  x2: 1,
  y2: 1,
  colorStops: [
    {
      offset: 0,
      color: '#596B8A', // 0% 处的颜色
    },
    {
      offset: 1,
      color: '#596B8A', // 100% 处的颜色
    },
  ],
  global: false, // 缺省为 false
}
// 有实体渐变色
const areaHighlight = {
  type: 'linear',
  x: 0,
  y: 0,
  x2: 0,
  y2: 1,
  colorStops: [
    {
      offset: 0,
      color: '#3865D0', // 0% 处的颜色
    },
    {
      offset: 1,
      color: '#3865D0', // 100% 处的颜色
    },
  ],
  global: false, // 缺省为 false
}

// 有实体hover背景色
const areaHighlightHover = {
  type: 'linear',
  x: 0,
  y: 0,
  x2: 0,
  y2: 1,
  colorStops: [
    {
      offset: 0,
      color: '#2965FF', // 0% 处的颜色
    },
    {
      offset: 1,
      color: '#2965FF', // 100% 处的颜色
    },
  ],
  global: false, // 缺省为 false
}

const ChinaMap = () => {
  const dispatch = useAppDispatch()
  const { statisticIndicatorHeight } = useContext(Context)
  const containerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<any>(null)
  const mapDataRef = useRef(0)

  const [app] = useAppSelector(state => [state.app])

  const [subscribed, setSubscribed] = useState(true)
  const [data, setData] = useState<any>(null)
  const [mapId, setMapId] = useState<any>(null)
  const [mapInfo, setMapInfo] = useState<Record<string, PatrolMapAreaData>>({})

  const bikeMapAreaData = mapInfo
  const spockMapAreaData = mapInfo

  useEffect(() => {
    return () => {
      setSubscribed(false)
    }
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      initChart()
    }
  }, [containerRef])

  useEffect(() => {
    if (data && mapId && subscribed) {
      setMapOption()
    }
  }, [data, mapId, mapInfo, app.currentBiz, subscribed])

  useEffect(() => {
    if (app?.currentArea.adCode && app.currentEntity.entityId && subscribed) {
      queryMapData(++mapDataRef.current)
    }
  }, [app?.currentArea.adCode, app.currentEntity.entityId, subscribed])

  useTimingRequest(() => {
    if (app.currentEntity.entityId && app.currentArea.adCode && app.currentBiz.businessType) {
      queryMapInfo()
    }
  }, [app.currentEntity.entityId, app.currentArea.adCode])

  const queryMapInfo = () => {
    const data = {
      entityId: app.currentEntity.entityId,
      adCode: app.currentArea.adCode,
    }
    request('patrolCenterQueryGeoRegionInfo', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setMapInfo(res.data)
      }
    })
  }

  const initChart = () => {
    if (!chartRef.current) {
      chartRef.current = echarts.init(containerRef.current as any)
      chartRef.current.on('click', handleChartClick)
      window.addEventListener(
        'resize',
        throttle(function () {
          chartRef.current?.resize()
        }, 100)
      )
    }
  }

  const queryMapData = (currentFlag: number) => {
    chartRef.current?.showLoading({
      maskColor: 'transparent',
      color: '#fff',
      textColor: '#fff',
    })
    const data = {
      adCode: app?.currentArea.adCode,
      entityId: app.currentEntity.entityId,
    }
    return request('dataCenterRegion', { data }).then(res => {
      if (res.code === 0 && res.data) {
        if (currentFlag === mapDataRef.current) {
          setData(res.data)
          setMapId(data.adCode)
        }
      }
      chartRef.current?.hideLoading()
      return res
    })
  }

  const setMapOption = () => {
    const defaultMapObject = {
      tooltip: {
        // show: !!dataCenterRs.todayStatistic,
        show: true,
        trigger: 'item',
        textStyle: {
          color: '#fff',
        },
        // position: 'inside',
        backgroundColor: '#424b5c',
        className: 'echarts-chinamap-tooltip',
        borderColor: 'transparent',
        formatter: function (params: any) {
          if (params.data) {
            return getToolTipTemplate(params)
          } else {
            return params.name
          }
        },
      },
      geo: {
        map: mapId,
        zoom: 1.2,
        layoutCenter: ['50%', '50%'],
        label: {
          normal: {
            show: false,
          },
          emphasis: {
            show: false,
          },
        },
        tooltip: {
          show: false,
        },
        itemStyle: {
          normal: {
            areaColor: '#000',
            // borderColor: geoBorderColor,
            borderWidth: 0, //设置外层边框
            shadowBlur: 0,
            shadowOffsetY: 20,
            shadowOffsetX: 0,
            // shadowColor: '#151E2D',
            // opacity: 0.15,
          },
        },
        regions: [
          {
            name: '南海诸岛',
            itemStyle: {
              normal: {
                areaColor: areaColor,
                shadowOffsetY: 4,
              },
            },
          },
        ],
      },
      series: [
        {
          type: 'map',
          map: mapId,
          coordinateSystem: 'geo',
          layoutCenter: ['50%', '50%'],
          zoom: 1.2,
          selectedMode: false,
          label: {
            show: true,
            normal: {
              show: true,
              textStyle: {
                color: '#BCCBE0', //省份字体颜色
                fontFamily: 'mtFontLight',
                fontSize: '14px',
                // textShadow: '0 4px 10px rgba(0,14,76,0.20)',
              },
            },
            emphasis: {
              textStyle: {
                color: '#fff',
              },
            },
          },
          itemStyle: {
            normal: {
              areaColor: '#6179A8',
            },
            emphasis: {
              areaColor: '#6179A8',
            },
          },
          data: data.features.map((item: any) => {
            const hasEntity = isAreaHasEntity(item.properties.id || item.properties.adCode)
            return {
              name: item.properties.name,
              id: item.properties.id || item.properties.adCode,
              parent: item.properties.parent,
              center: item.properties.center,
              lastLevel: !item.properties.childrenNum || item.properties.childrenNum === 0,
              lat: item.properties.lat,
              lng: item.properties.lng,
              level: item.properties.level,
              hasEntity: hasEntity,
              label: {
                normal: {
                  offset: getLabelOffset(item.properties.name),
                  textStyle: {
                    color: hasEntity ? '#fff' : '#BCCBE0', //省份字体颜色
                    fontSize: hasEntity ? '14px' : '12px',
                    fontFamily: hasEntity ? 'mtFont' : 'mtFontLight',
                    textShadowColor: hasEntity ? '#277FD0' : 'transparent',
                    textShadowBlur: 4,
                    textShadowOffsetY: 4,
                  },
                },
              },
              itemStyle: {
                repeat: 'repeat',
                normal: {
                  // 区分有实体和无实体的背景色
                  areaColor: hasEntity ? areaHighlight : areaColor,
                  borderColor: hasEntity ? '#268BFF' : '#7190C3',
                  opacity: hasEntity ? 1 : 1,
                },
                emphasis: {
                  borderColor: hasEntity ? '#268BFF' : '#7190C3',
                  areaColor: hasEntity ? areaHighlightHover : areaColorHover,
                  opacity: 1,
                },
              },
            }
          }),
        },
      ],
    }
    const mapObject = Object.assign(defaultMapObject, {})
    echarts.registerMap(mapId, data)
    chartRef.current?.setOption(mapObject)
    chartRef.current?.resize()
  }

  const isAreaHasEntity = (id: number) => {
    const areaData =
      app.currentBiz.businessType === businessLineMap.spock ? spockMapAreaData : bikeMapAreaData
    return areaData[id] && !!areaData[id].hasEntity
  }

  const getLabelOffset = (name = '') => {
    if (name.includes('澳门')) {
      return [-30, 20]
    } else if (name.includes('香港')) {
      return [0, 10]
    } else if (name.includes('河北')) {
      return [0, 15]
    } else if (name.includes('内蒙')) {
      return [0, 40]
    } else if (name.includes('黑龙江')) {
      return [20, 20]
    }
    return [0, 0]
  }

  const getToolTipTemplate = (params: any) => {
    const { data = {} } = params
    // 秩序不分业务线，这里统一用单业务情况下展示
    if (app.currentBiz.businessType > 10) {
      const bikeAreaData = bikeMapAreaData[data.id] || {}
      const spockAreaData = spockMapAreaData[data.id] || {}
      const items = [
        {
          label: '有序区域',
          ...formatDoubleValue('spock', spockAreaData?.unorderlyCount),
          ...formatDoubleValue('bike', bikeAreaData?.unorderlyCount),
        },
        {
          label: '重点区域总数',
          ...formatDoubleValue('spock', spockAreaData?.unorderlyCount),
          ...formatDoubleValue('bike', bikeAreaData?.unorderlyCount),
        },
      ]

      const dom = (
        <div className="chinaMap-tooltip">
          <div className="single-tooltip-title startCenter">
            <div className="">{params.name}</div>
          </div>
          <div className="divider"></div>
          <div className="betweenCenter">
            <div className="columnStartStart" style={{ marginRight: 16 }}>
              <div className="tooltip-indicator">数据指标</div>
              {items.map((item, idx) => (
                <div key={idx} className="single-tooltip-item">
                  <span className="single-tooltip-label">{item?.label}</span>
                </div>
              ))}
            </div>
            <div style={{ marginRight: 16 }}>
              <div className="tooltip-indicator">电单车</div>
              {items.map((item: any, idx) => (
                <div key={idx} className="single-tooltip-item">
                  <span className="single-tooltip-value">{item?.spock || 0}</span>
                </div>
              ))}
            </div>
            <div>
              <div className="tooltip-indicator">单车</div>
              {items.map((item: any, idx) => (
                <div key={idx} className="single-tooltip-item">
                  <span className="single-tooltip-value">{item.bike || 0}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
      return ReactDOMServer.renderToString(dom)
    } else {
      const mapAreaData =
        app.currentBiz.businessType === businessLineMap.bike ? bikeMapAreaData : spockMapAreaData
      const areaData = mapAreaData[data.id] || {}
      const { total, unorderlyCount } = areaData || {}
      const items = [
        {
          label: '无序区域',
          ...formatSingleValue(unorderlyCount, '个'),
        },
        {
          label: '重点区域总数',
          ...formatSingleValue(total, '个'),
        },
      ]
      const dom = (
        <div className="chinaMap-tooltip">
          <div className="single-tooltip-title startCenter">
            <div>{params.name}</div>
          </div>
          <div className="divider"></div>
          <div className="startStart">
            <div className="columnStartStart" style={{ marginRight: 16 }}>
              <div className="tooltip-indicator">数据指标</div>
              {items.map((item, idx) => (
                <div key={idx} className="single-tooltip-item">
                  <span className="single-tooltip-label">{item?.label}</span>
                </div>
              ))}
            </div>
            <div>
              <div className="tooltip-indicator">数量</div>
              {items.map((item, idx) => (
                <div key={idx} className="single-tooltip-item">
                  <span className="single-tooltip-value">{item?.value || 0}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
      return ReactDOMServer.renderToString(dom)
    }
  }

  const formatDoubleValue = (
    valueKey: string,
    value?: number,
    measureKey?: string,
    measure?: any
  ) => {
    // 大于百万的情况
    if (value && value > 1000000) {
      value = Math.round(value / 10000)
      measure = '万' + measure
    }
    return { [valueKey]: value, [measureKey as string]: measure }
  }

  const formatSingleValue = (value = 0, measure = '') => {
    // 大于百万的情况
    if (value > 1000000) {
      value = Math.round(value / 10000)
      measure = '万' + measure
    }
    return { value, measure }
  }

  const handleChartClick = (params: any) => {
    if (params && params.data) {
      const {
        data: { name, id, lastLevel, parent = {}, hasEntity, level, center },
      } = params
      // 不是最后一级，并且区域有实体可下钻
      if (!lastLevel && hasEntity) {
        dispatch(
          currentArea({
            adCode: id + '',
            adName: name,
            level,
            lng: center.lng || center[0],
            lat: center.lat || center[1],
          })
        )
      } else {
        // 不可下钻且最后一级，显示腾讯地图
        if (lastLevel) {
          dispatch(
            currentArea({
              lng: center.lng || center[0], // 北京市的center可能是array，也可能是object
              lat: center.lat || center[1],
              level: level,
              adCode: id + '',
              showMap: true,
            })
          )
        }
      }
    }
  }

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <div className="patrol-china-map" ref={containerRef}></div>
      <div
        className="legend-box"
        style={{
          // top: !dataCenterRs.todayStatistic ? '12px' : statisticIndicatorHeight + 12 + 'px',
          top: statisticIndicatorHeight + 12 + 'px',
        }}
      >
        <Legend></Legend>
      </div>
      <div className="control-box">
        <AreaMapControl></AreaMapControl>
      </div>
    </div>
  )
}

export default ChinaMap
