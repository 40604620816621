import React, { Suspense, useMemo } from 'react'
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Skeleton } from 'antd'

import getRoutes from '@/routes'
import { useUserResources, useAppSelector } from '@/utils/hooks'

import './App.less'

const App: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.app)
  const userResources = useUserResources(resources => resources)

  const routes = useMemo(() => {
    return getRoutes(userResources, userInfo)
  }, [userResources, userInfo])

  return (
    <Router>
      <Suspense fallback={<Skeleton active></Skeleton>}>
        <Switch>
          <Redirect exact from="/" to="/dataCenter" />
          {routes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              render={props => <route.component {...props} routes={route.routes} />}
            />
          ))}
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
