import { orderTypeMap } from './consts'

const alarmDetail = require('_root/mock/subwayCenter/subwayCenterAlarmDetail.json').data
const alarmList = require('_root/mock/subwayCenter/subwayCenterAlarmList.json').data
const order_detail_img = require('_root/assets/img/order_detail.jpg')
const analysis_arrow = require('_root/assets/img/analysis_arrow.png')

// 地铁站点详情
function createInfoWindow(map) {
  const infoWindow = new TMap.InfoWindow({
    map: map,
    zIndex: 1,
    position: new TMap.LatLng(39.984104, 116.307503),
    offset: { x: 0, y: -10 },
    enableCustom: true,
    content: '<div class="info-card"></div>',
  })
  infoWindow.close()
  return infoWindow
}

let infoWindow

let handleViewAnalysisClickFn
let clickedStop

export const getStopInfoWindow = map => {
  if (!infoWindow) {
    infoWindow = createInfoWindow(map)
  }
  return infoWindow
}

window.handleStopInfoWindowClose = function () {
  infoWindow && infoWindow.close()
}

window.stopInfoOnmousemove = function (e) {
  e?.stopPropagation && e.stopPropagation()
}

window.handleViewAnalysis = function (e) {
  e?.stopPropagation && e.stopPropagation()
  handleViewAnalysisClickFn?.(clickedStop)
}

window.handleSubwayInfoClick = function (e) {
  e?.stopPropagation && e.stopPropagation()
}

const getInfoContent = ({ stop }) => {
  // 这里mock站点的淤积类型，后期使用接口数据
  let mockAlarmDetail = alarmList.find(item => item.stationName === stop.station_name)
  if (!mockAlarmDetail) {
    mockAlarmDetail = alarmDetail
  } else {
    mockAlarmDetail = { ...alarmDetail, ...mockAlarmDetail }
  }
  return `
    <div class="relative fz16 grayFont07 stop-alarm-win" onclick="handleSubwayInfoClick(event)" onmousemove="stopInfoOnmousemove(event)">
      <div class="startCenter" style="margin-bottom: 14px">
        <span class="label" style="font-size: 20px; color: #ffffffe6;">${stop.station_name}</span>
        <div class="pointer startCenter" onclick="handleViewAnalysis(event)">
          <div style="color: #00E0E0; font-size: 14px;">查看诊断分析</div>
          <img style="height: 12px; width: 12px;" src=${analysis_arrow}>
        </div>
      </div>
      <div style="position: absolute; top: 53px; width: 100%; left: 0px; height: 0.5px; background-color: #1b2332;"></div>
      <div class="mb12" style="display: ${stop.orderType ? '' : 'none'}">
        <span class="label">站点状态</span>
        <span class="value br4" style="color: ${
          stop.orderType === orderTypeMap.bikeShortage
            ? '#FFD400'
            : stop.orderType === orderTypeMap.bikeDeposit
            ? '#FF4040'
            : ''
        };">
        ${
          stop.orderType === orderTypeMap.bikeShortage
            ? '车辆不足'
            : stop.orderType === orderTypeMap.bikeDeposit
            ? '车辆溢出'
            : '车辆正常'
        }</span>
      </div>
      <div class="mb12">
        <span class="label">预测时间</span>
        <span class="value">${mockAlarmDetail.predictTime || '-'}</span>
      </div>
      <div class="mb12">
        <span class="label">当前单车量</span>
        <span class="value">${mockAlarmDetail.currentBikeCount || '-'}</span>
      </div>
      <div class="mb12">
        <span class="label">用车需求预测</span>
        <span class="value">${mockAlarmDetail.predictBikeCount || '-'}</span>
      </div>
      <div class="mb12">
        <span class="label">处理企业</span>
        <span class="value">${mockAlarmDetail.disposeCompanyName || '-'}</span>
      </div>
      <div class="mb12">
        <span class="label">工单类型</span>
        <span class="value">${mockAlarmDetail.orderTypeName || '-'}</span>
      </div>
      <div class="mb12">
        <span class="label">工单状态</span>
        <span style="display: inline-block">
          <span class="value">${mockAlarmDetail.orderStatusName || '-'}</span>
          <img class="mt5" style="display: ${
            mockAlarmDetail.disposePicture ? 'block' : 'none'
          }; height: 72px; aspect-ratio: 3/2; object-fit: cover" src=${order_detail_img}>
        </span>
      </div>
      <div style="width: 15px; height: 15px; position: absolute; right: 3px; top: 2px; cursor: pointer;" onclick="handleStopInfoWindowClose()">
        <svg style="fill: #aaa;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path d="M579.888 512l190.064-190.064a48 48 0 0 0-67.888-67.872L512 444.112 321.936 254.064a48 48 0 1 0-67.872 67.872L444.112 512 254.064 702.064a48 48 0 1 0 67.872 67.872L512 579.888l190.064 190.064a48 48 0 0 0 67.872-67.888L579.888 512z" fill=""></path>
        </svg>
      </div>
      <div class="triangle"></div>
    </div>
  `
}

export const setStopInfoWindow = (map, opt, onViewAnalysisClick) => {
  const infoWindow = getStopInfoWindow(map)
  const { position, properties } = opt
  clickedStop = properties
  handleViewAnalysisClickFn = onViewAnalysisClick
  if (properties.infoWinStationOffset) {
    infoWindow.setOffset({ x: 0, y: -26 })
  } else {
    infoWindow.setOffset({ x: 0, y: -16 })
  }
  infoWindow.setMap(map)
  infoWindow.setPosition(position)
  infoWindow.setContent(getInfoContent({ stop: properties }))
  infoWindow.open()
}
