/**
 * 区县筛选器
 */
import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

import request from '@/utils/request'

import type { FormItemProps } from 'antd/es/form'
import type { SelectProps } from 'antd/es/select'

const { Item } = Form
const { Option } = Select

interface DistrictSelectProps {
  data?: {
    entityId?: Entity['entityId']
  }
  formItemProps?: FormItemProps
  childProps?: SelectProps
}

const DistrictSelect: React.FC<DistrictSelectProps> = ({ formItemProps, childProps, data }) => {
  const [regionList, setRegionList] = useState<{ adCode: string; adName: string }[]>([])

  useEffect(() => {
    if (data) {
      getDistrictList()
    }
  }, [data])

  const getDistrictList = () => {
    request('entityQuerySubDistrict', { data }).then(res => {
      const { data = [], code } = res
      code == 0 && data?.length && setRegionList(data)
    })
  }

  return (
    <Item {...formItemProps}>
      <Select allowClear showSearch {...childProps}>
        {regionList.map((item: any, index) => (
          <Option key={index} value={item.adCode}>
            {item.adName}
          </Option>
        ))}
      </Select>
    </Item>
  )
}

export default DistrictSelect
