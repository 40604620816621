import React, { useState, useReducer, useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { Input, Select, Form, Tooltip, Button } from 'antd'
import pick from 'lodash/pick'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'

import {
  heatStatusMap,
  bikeStatusMap,
  businessLineMap,
  parkingAreaMap,
  operateStatusMap,
  bikeStatusTextMap,
  heatStatusTextMap,
  businessLineTextMap,
  parkingAreaTextMap,
  operateStatusTextMap,
} from '@/utils/consts'
import { useUserResources, useAppSelector } from '@/utils/hooks'
import { getBizArrFromBizType, selectBizFromBizType, getKeyByVal } from '@/utils/util'
import request from '@/utils/request'
import { iconMap, parkingIcon, exitEnterZoom, stopStatusIcon, stationStatusIcon } from './consts'
import { setStopInfoWindow } from './StopAlarmInfoWin'

interface Props {
  map: TMap.Map
  onSearch: (param: SearchParam) => void
  onChange: (state: LayerParam) => void
  onViewAnalysis: (stop: any) => void
  stopData: any[]
}

export interface SearchParam {
  type: ValueOf<typeof searchType>
  value: string
  bikeId?: string
  bikePlate?: string
}
export interface LayerParam {
  bikeStatus: BikeStatus
  brandCodeList: string[]
  bikeType?: BusinessType
  areaTypeList: ParkingAreaType[]
  operateStatus?: OperateStatus
  heatStatus?: HeatStatus
}

export const searchType = {
  bikeId: '1',
  bikeNum: '2',
  parkingId: '3',
  parkingName: '4',
  subwayStation: '5',
}

const layerControl = {
  layer_control: require('_root/assets/img/layer_control.png'),
  layer_legend: require('_root/assets/img/layer_legend.png'),
  bike_search: require('_root/assets/img/bike_search.png'),
}

const initialState: LayerParam = {
  bikeStatus: 3,
  brandCodeList: ['0'],
  areaTypeList: [],
}

const searchStations = require('_root/mock/subwayCenter/subwayCenterStationSearch.json').data

const reducer = (state: LayerParam, action: { type: string; payload: LayerParam }) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const initialFold = {
  // searchFold: true,
  legendFold: true,
  layerFold: true,
}

const LayerControl: React.FC<Props> = ({ map, onChange, onSearch, onViewAnalysis, stopData }) => {
  const [form] = Form.useForm()
  const [app] = useAppSelector(state => [state.app])
  const [dataCenterRs] = useUserResources(resources => [resources.dataCenter])
  const [state, dispatch] = useReducer(reducer, initialState)

  const [controlFold, setControlFold] = useState(initialFold)
  const [brandList, setBrandList] = useState<any>([])
  const [searchFold, setSearchFold] = useState(true)

  const { legendFold, layerFold } = controlFold
  const { bikeType, bikeStatus, brandCodeList, areaTypeList, operateStatus, heatStatus } = state

  useEffect(() => {
    if (app.currentBiz.businessType) {
      handleClick({ bikeType: selectBizFromBizType(app.currentBiz.businessType) })
    }
  }, [app.currentBiz.businessType])

  useEffect(() => {
    queryBrandList()
  }, [app.currentEntity.entityId])

  useEffect(() => {
    const listener = (e: any) => {
      if (e.target?.id !== 'bike-search-form' && !searchFold) {
        // setSearchFold(true)
      }
    }
    document.body.addEventListener('click', listener)
    return () => {
      document.body.removeEventListener('click', listener)
    }
  }, [searchFold])

  useEffect(() => {
    state.bikeType && onChange(state)
  }, [state])

  const searchOpts = useMemo(() => {
    return searchStations.map((item: any) => ({
      label: item.station_name,
      value: item.station_id,
    }))
  }, [searchStations])

  const queryBrandList = () => {
    const data = { entityId: app.currentEntity.entityId }
    request('companyBrandListByUserType', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setBrandList([{ brandCode: '0', brandName: '全部' }].concat(res.data))
      }
    })
  }

  const handleControlClick = (name: keyof typeof controlFold) => {
    setSearchFold(true)
    setControlFold({ ...initialFold, [name]: !controlFold[name] })
  }

  const handleClick = (payload: any) => {
    dispatch({ type: 'update', payload })
  }

  const renderControlDesk = () => {
    const operationResource = {
      [operateStatusMap.operator]: true,
      [operateStatusMap.operationCar]: true,
    }
    const parkingResource = {
      [parkingAreaMap.parking]: true,
      [parkingAreaMap.noParking]: true,
      [parkingAreaMap.operateArea]: true,
    }
    const data = [
      {
        title: '车辆类型',
        show: true,
        btns: getBizArrFromBizType(app.currentBiz.businessType).map(val => ({
          name: businessLineTextMap[val],
          value: val,
          className: val == bikeType ? 'manage-desk-btn-highlight' : '',
          onClick: () => handleClick({ bikeType: val }),
        })),
      },
      {
        title: '车辆状态',
        show: false,
        btns: Object.values(bikeStatusMap).map(val => ({
          name: bikeStatusTextMap[val],
          value: val,
          className: val == bikeStatus ? 'manage-desk-btn-highlight' : '',
          onClick: () => handleClick({ bikeStatus: val }),
        })),
      },
      {
        title: '企业品牌',
        show: false,
        multiple: true,
        btns: brandList.map((val: any) => ({
          name: val.brandName,
          value: val.brandCode,
          // className: val.brandCode == brandCode ? 'manage-desk-btn-highlight' : '',
          className: brandCodeList?.includes(val.brandCode) ? 'manage-desk-btn-highlight' : '',
          // onClick: () => handleClick({ brandCode: val.brandCode }),
          onClick: () => {
            handleClick({
              brandCodeList: brandCodeList?.includes(val.brandCode)
                ? brandCodeList.filter((item: any) => item != val.brandCode)
                : [...brandCodeList, val.brandCode],
            })
          },
        })),
      },
      {
        title: '区域点位',
        show: !!dataCenterRs.parkingAreaShow,
        btns: Object.values(pick(parkingAreaMap, 'parking')).map(val => ({
          name: parkingAreaTextMap[val],
          value: val,
          validate: true,
          show: !!parkingResource[val],
          className: areaTypeList?.includes(val) ? 'manage-desk-btn-highlight' : '',
          onClick: () =>
            handleClick({
              areaTypeList: areaTypeList?.includes(val)
                ? areaTypeList.filter((item: any) => item != val)
                : [...areaTypeList, val],
            }),
        })),
      },
      {
        title: '运维力量',
        show: false,
        btns: Object.values(operateStatusMap).map(val => ({
          name: operateStatusTextMap[val],
          value: val,
          validate: true,
          show: !!operationResource[val],
          className: classnames(val == operateStatus ? 'manage-desk-btn-highlight' : ''),
          onClick: () => handleClick({ operateStatus: val }),
        })),
      },
      {
        title: '热力图',
        show: false,
        btns: Object.values(heatStatusMap).map(val => ({
          name: heatStatusTextMap[val],
          value: val,
          className: classnames(val == heatStatus ? 'manage-desk-btn-highlight' : ''),
          onClick: () => handleClick({ heatStatus: val }),
        })),
      },
    ]
    return data.map((item, i) =>
      item.show ? (
        <div key={i} className="startStart" style={{ marginTop: i === 0 ? 0 : 12 }}>
          <div className="mr12" style={{ width: 56, marginTop: 6 }}>
            {item.title}
          </div>
          <div className="startCenter" style={{ width: 306, flexWrap: 'wrap' }}>
            {item.btns.map((btn: any, idx: number) =>
              !btn.validate || (btn.validate && btn.show) ? (
                <div
                  key={i + '-' + idx}
                  className={`manage-desk-btn centerCenter pointer ${btn.className}`}
                  onClick={btn.onClick}
                  style={{ marginRight: (idx + 1) % 3 === 0 ? 0 : 12, marginTop: idx > 2 ? 12 : 0 }}
                >
                  {btn.name?.length > 5 ? (
                    <Tooltip title={btn.name}>{btn.name.slice(0, 5) + '...'}</Tooltip>
                  ) : (
                    btn.name
                  )}
                </div>
              ) : null
            )}
          </div>
        </div>
      ) : null
    )
  }

  const handleLayerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    handleControlClick('layerFold')
  }

  const handleBikeSearch = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    // handleControlClick('searchFold')
    setSearchFold(false)
  }

  const handleLegendClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    handleControlClick('legendFold')
  }

  const renderLegendDesk = () => {
    const isDoubleBiz = app.currentBiz.businessType === businessLineMap.double
    const data = [
      {
        key: 'subway',
        title: '地铁图例',
        icons: Object.values(Object.assign({}, stopStatusIcon, stationStatusIcon)),
      },
      { key: 'parking', title: '运营-关锁', icons: [] },
      { key: 'riding', title: '运营-开锁', icons: [] },
      {
        key: 'operation',
        // 运维情况区域管理
        title: '区域管理',
        icons: Object.values(parkingIcon),
      },
    ]
    const rawOrders = ['spock_parking', 'bike_parking', 'spock_riding', 'bike_riding']
    const orders = isDoubleBiz
      ? rawOrders
      : rawOrders.filter(item =>
          item.includes(getKeyByVal(businessLineMap, app.currentBiz.businessType)!)
        )

    const brands = brandList.slice(1)?.map((item: any) => item.brandCode?.toLowerCase())

    const iconMapKeys = Object.keys(iconMap).filter(
      key => !!brands.find((brand: string) => key.includes(brand))
    )

    const run = (order: (typeof orders)[0], icons: any[]) => {
      iconMapKeys.forEach(key => {
        if (key.indexOf(order) > -1) {
          icons.push(iconMap[key as keyof typeof iconMap])
        }
      })
    }
    orders.forEach(order => {
      const dataItem = data.find(item => order.includes(item.key))
      run(order, dataItem!.icons)
    })
    return (
      <div className="startStretch">
        <div className="legend-horizon-divider"></div>
        {data.map((item, i) => (
          <React.Fragment key={item.key}>
            <div className="columnStartStart">
              <div style={{ marginBottom: 21 }}>{item.title}</div>
              <div>
                {item.icons.map((icon: any, idx) => (
                  <div
                    key={item.key + icon.label}
                    className="startCenter"
                    style={{ marginBottom: idx === item.icons.length - 1 ? 0 : 8 }}
                  >
                    <img style={{ width: 28, height: 28, marginRight: 4 }} src={icon.icon}></img>
                    <span style={{ color: '#BCCBE0' }}>
                      {['parking', 'riding'].includes(item.key)
                        ? icon.label.slice(0, -2)
                        : icon.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="legend-vertical-divider"
              style={{ display: i === data.length - 1 ? 'none' : '' }}
            ></div>
          </React.Fragment>
        ))}
      </div>
    )
  }

  const handleSearch = () => {
    const { type, value } = form.getFieldsValue()
    const param = { value, type }
    if ([searchType.subwayStation].includes(type)) {
      // 选中搜索地铁站，只需要定位到地铁站即可
      handleSubwaySearch(param)
    } else {
      // 选中搜索其他条件，需要执行搜索条件
      onSearch(param)
    }
  }

  const handleSubwaySearch = (param: any) => {
    const { value } = param
    if (!value) {
      return
    }
    // 站点
    const stop = stopData.find(stop => stop.station_id === value)
    const { lat, lon } = stop
    const center = new TMap.LatLng(lat, lon)
    // @ts-ignore
    map.easeTo({
      center: new TMap.LatLng(+lat + 0.0035, lon),
      zoom: exitEnterZoom,
    })
    // 显示信息弹窗
    const opt = { position: center, properties: { ...stop } }
    setStopInfoWindow(map, opt, onViewAnalysis)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (!e.target.value) {
    //   onBikeSearch({ value: '' })
    // }
  }

  return (
    <div className="relative endCenter">
      <div style={{ marginRight: 8 }}>
        {searchFold ? (
          <div className="layer-control-item centerCenter pointer" onClick={handleBikeSearch}>
            <img style={{ width: 24 }} src={layerControl.bike_search}></img>
            <div className="fz16 ml5 mr5" style={{ width: 32 }}>
              搜索
            </div>
          </div>
        ) : (
          <Form
            form={form}
            id="bike-search-form"
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            <Form.Item noStyle>
              <Input.Group className="bike-search-input-group">
                <span onDoubleClick={() => setSearchFold(true)}>
                  <Form.Item name="type" noStyle initialValue={searchType.subwayStation}>
                    <Select
                      placeholder="请选择"
                      size="large"
                      className="custom-select bike-search-select"
                      popupClassName="custom-select-dropdown"
                      options={[
                        // { label: '车辆ID', value: searchType.bikeId, rs: true },
                        // {
                        //   label: '车牌号',
                        //   value: searchType.bikeNum,
                        //   rs: dataCenterRs.showBikeLicenseCode,
                        // },
                        // {
                        //   label: '停车点ID',
                        //   value: searchType.parkingId,
                        //   rs: dataCenterRs.parkingAreaShow,
                        // },
                        // {
                        //   label: '停车点名称',
                        //   value: searchType.parkingName,
                        //   rs: dataCenterRs.parkingAreaShow,
                        // },
                        { label: '地铁站名', value: searchType.subwayStation, rs: true },
                      ].filter(item => !!item.rs)}
                      // onDoubleClick={() => setSearchFold(true)}
                    ></Select>
                  </Form.Item>
                </span>
                <Form.Item name="value" noStyle>
                  {/* <Input.Search
                    size="large"
                    style={{ width: 220 }}
                    // prefix={<SearchOutlined />}
                    placeholder="请输入"
                    onChange={handleChange}
                    onSearch={handleSearch}
                    className="bike-search-input"
                  /> */}
                  <Select
                    allowClear
                    showArrow={false}
                    bordered={false}
                    style={{ width: 220, color: '#fff' }}
                    onChange={handleChange}
                    className="custom-select subway-search-select"
                    popupClassName="custom-select-dropdown"
                    placeholder="请选择"
                    clearIcon={
                      <CloseOutlined style={{ color: '#fff', backgroundColor: '#1B2333' }} />
                    }
                    options={searchOpts}
                  />
                </Form.Item>
                <Button
                  onClick={handleSearch}
                  style={{ background: '#1b2333', border: 0 }}
                  icon={<SearchOutlined style={{ color: '#fff' }} />}
                />
              </Input.Group>
            </Form.Item>
          </Form>
        )}
      </div>
      <div style={{ marginRight: 8 }}>
        <div className="layer-control-item centerCenter pointer" onClick={handleLegendClick}>
          <img style={{ width: 24 }} src={layerControl.layer_legend}></img>
          <div className="fz16 ml5 mr5" style={{ width: 32 }}>
            图例
          </div>
        </div>
        {legendFold ? null : (
          <div className="manage-desk" style={{ right: 100 }}>
            {renderLegendDesk()}
          </div>
        )}
      </div>
      <div>
        <div className="layer-control-item centerCenter pointer" onClick={handleLayerClick}>
          <img style={{ width: 24 }} src={layerControl.layer_control}></img>
          <div className="fz16 ml5 mr5" style={{ width: 32 }}>
            图层
          </div>
        </div>
        {layerFold ? null : <div className="manage-desk">{renderControlDesk()}</div>}
      </div>
    </div>
  )
}

export default LayerControl
