import { useEffect } from 'react'
import throttle from 'lodash/throttle'

import Left from './left'
import Right from './right'
import Center from './center'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import { windowResize } from '@/redux/reducers/app'

import './index.less'

const DataCenter = () => {
  const dispatch = useAppDispatch()
  const [app] = useAppSelector(state => [state.app])

  const { fullScreen } = app

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    body.setAttribute('style', 'background: #10131a')
    return () => {
      body.setAttribute('style', 'background: #fff')
    }
  }, [])

  useEffect(() => {
    window.addEventListener(
      'resize',
      throttle(function () {
        dispatch(windowResize())
      }, 300)
    )
    dispatch(windowResize())
  }, [])

  return (
    <div className="subway-center flex1 nobg">
      <div className="startStart relative subway-center-adapt-box">
        <div className="subway-center-left" style={{ zIndex: fullScreen ? 10 : 10 }}>
          <Left></Left>
        </div>
        <div className="subway-center-center">
          <Center></Center>
        </div>
        <div className="subway-center-right" style={{ zIndex: fullScreen ? 10 : 10 }}>
          <Right></Right>
        </div>
      </div>
    </div>
  )
}

export default DataCenter
