import { useEffect, useMemo, useState } from 'react'
import { Carousel, Image } from 'antd'
import { Link } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons'

import { useAppSelector, useTimingRequest, useUserResources } from '@/utils/hooks'
import ContentBox from '../contentBox'
import { orderlyTypeMap, orderlyTypeTextMap } from '@/utils/consts'
import request from '@/utils/request'

import { PatrolItem } from './types'

const PatrolResult = () => {
  const [app] = useAppSelector(state => [state.app])
  const [manageCenterResource] = useUserResources(resources => [resources.manageCenter])
  const [orderlyPatrolData, setOrderlyPatrolData] = useState<PatrolItem[]>([])

  useTimingRequest(() => {
    if (app.currentArea.adCode) {
      workOrderStatistics()
    }
  }, [
    app.currentFence.fenceId,
    app.currentFence.selectFenceId,
    app.currentArea.adCode,
    app.currentEntity.entityId,
  ])

  const workOrderStatistics = () => {
    const data = {
      entityId: app.currentEntity.entityId,
      fencingId: app.currentFence.selectFenceId || app.currentFence.fenceId,
      adCode: app.currentArea.adCode,
    }
    request('patrolCenterDetailsQueryList', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setOrderlyPatrolData(res.data)
      }
    })
  }

  const carouselData = useMemo(() => {
    const result = []
    for (let i = 0; i < orderlyPatrolData.length; i += 6) {
      result.push(orderlyPatrolData.slice(i, i + 6))
    }
    return result
  }, [orderlyPatrolData])

  const carouselDiv = (content: PatrolItem[]) => {
    return (
      <div className="patrol-orderly-parent">
        {content.map((item: any, index: number) => {
          return (
            <div className="patrol-orderly-children" key={index}>
              <Image width="100%" height="90px" src={item.picture} className="patrol-orderly-img" />
              <div className="patrol-orderly-text">
                <span
                  style={{
                    color: item.orderlyResult === orderlyTypeMap.orderly ? '#47C3CF' : '#E24E37',
                  }}
                  className="patrol-orderly-status"
                >
                  {orderlyTypeTextMap[item.orderlyResult as OrderlyType]}
                </span>
                <span className="patrol-orderly-brand">{item.relationBrandName}</span>
                <span className="patrol-orderly-text-separate"></span>
                <span>{item.address}</span>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <ContentBox title="巡查结果" style={{ marginTop: '4px' }}>
      <div>
        {manageCenterResource.keyRegionPerform && (
          <Link to={'/manageCenter/orderlyManage/keyRegionPerform'} className="view-detail">
            查看详情 <RightOutlined style={{ fontSize: 12 }} />
          </Link>
        )}
      </div>
      {carouselData.length ? (
        <div className="patrol-orderly">
          <Carousel dotPosition="left" autoplay dots={false}>
            {carouselData.map((item: PatrolItem[], index: number) => {
              return <div key={index}>{carouselDiv(item)}</div>
            })}
          </Carousel>
        </div>
      ) : (
        <div className="patrol-orderly-empty">近期暂无巡检记录</div>
      )}
    </ContentBox>
  )
}

export default PatrolResult
