import React, { useEffect, useState } from 'react'
import { Form, Tree } from 'antd'

import request from '@/utils/request'
import { useAppSelector } from '@/utils/hooks'
import { transformTreeData } from '@/utils/util'

import type { FormItemProps } from 'antd/es/form'
import type { TreeProps } from 'antd/es/tree'

export interface ResourceTreeProps {
  formItemProps?: FormItemProps
  childProps?: TreeProps
  onQueryDataSuccess?(data: TreeData): void
}

const ResourceTree: React.FC<ResourceTreeProps> = ({ childProps, formItemProps }) => {
  const [app] = useAppSelector(state => [state.app])
  const [treeData, setTreeData] = useState([])

  const queryList = () => {
    const data = { entityId: app.currentEntity.entityId, roleType: app.userInfo.roleType }
    request('commonQueryResourcesByEntity', { data }).then(res => {
      const { data = [], code } = res
      code == 0 && data?.length && setTreeData(transformTreeData(data, 'label', 'value'))
    })
  }

  useEffect(() => {
    if (!childProps?.treeData) {
      queryList()
    }
  }, [childProps?.treeData])

  return (
    <Form.Item valuePropName="checkedKeys" trigger="onCheck" {...formItemProps}>
      <Tree checkable blockNode treeData={treeData} {...childProps} />
    </Form.Item>
  )
}

export default ResourceTree
