import { useState, useRef, useEffect } from 'react'
import throttle from 'lodash/throttle'
import { Select } from 'antd'
import * as echarts from 'echarts'

import ContentBox from '../contentBox'
import { isNumber } from '@/utils/util'
import request from '@/utils/request'
import { useAppSelector, useTimingRequest } from '@/utils/hooks'

import { ChartData, SeriesItem } from './types'

const PatrolOrderStatistics = () => {
  const [app] = useAppSelector(state => [state.app])
  const containerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<any>(null)
  const [patrolData, setPatrolData] = useState<ChartData[]>()
  const [period, setPeriod] = useState<number>(1)

  const validateValue = (val: any) => {
    return isNumber(val) ? val : '--'
  }

  useEffect(() => {
    if (patrolData) {
      chartRef.current = echarts.dispose(containerRef.current as any)
      !chartRef.current && initChart()
      setOption(patrolData)
    }
  }, [patrolData])

  useTimingRequest(() => {
    if (app.currentArea.adCode) {
      queryOrderStatistics()
    }
  }, [
    app.currentFence.fenceId,
    app.currentFence.selectFenceId,
    app.currentArea.adCode,
    app.currentEntity.entityId,
    period,
  ])

  const initChart = () => {
    chartRef.current = echarts.init(containerRef.current as any)
    window.addEventListener(
      'resize',
      throttle(function () {
        chartRef.current?.resize()
      }, 100)
    )
  }

  const queryOrderStatistics = () => {
    const data = {
      fencingId: app.currentFence.selectFenceId || app.currentFence.fenceId,
      entityId: app.currentEntity.entityId,
      adCode: app.currentArea.adCode,
      period: period,
    }
    request('patrolCenterDetailsLineChart', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setPatrolData(res.data)
      }
    })
  }

  const handleChange = (value: number) => {
    setPeriod(value)
  }

  const setOption = (data: any) => {
    const xAisData = data.x
    let disorderly: SeriesItem | undefined
    let patrolNum: SeriesItem | undefined
    data.series.forEach((item: SeriesItem) => {
      if (item.key === 'notOrderlyCount') {
        disorderly = { ...item }
      } else {
        patrolNum = { ...item }
      }
    })
    const option = {
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(66, 75, 92, 0.2)',
          },
        },
        backgroundColor: '#424B5C',
        className: 'custom-echart-tooltip',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
        formatter: function (params: any) {
          const s1 = params[0] || {}
          const s2 = params[1] || {}
          const s1Display = s1.name ? '' : 'none'
          const s2Display = s2.name ? '' : 'none'
          return `
          <div>
            <span style="display:block;margin-bottom:10px;font-size:14px;">${s1.name}</span>
            <div style="display: ${s1Display};">
              <span style="display:inline-block;margin-right:4px;width:3px;height:8px;background-color: 
          ${s1.color};"></span>
              <span style="color: #BCCBE0;">${s1.seriesName}</span>
              <span style="display:inline-block;margin-right:20px;"></span>
              <span style="float:right;font-family: AvenirLight;">
                ${validateValue(s1.value)}
              </span>
              <span style="display:block;margin-bottom:8px;"></span>
            </div>
            <div style="display: ${s2Display}">
              <span style="display:inline-block;margin-right:4px;width:3px;height:8px;background-color: 
          ${s2.color};"></span>
              <span style="color: #BCCBE0;">${s2.seriesName}</span>
              <span style="display:inline-block;margin-right:20px;"></span>
              <span style="float:right;font-family: AvenirLight;">
                ${validateValue(s2.value)}
              </span>
              <span style="display:block;"></span>
            </div>
         </div>`
        },
      },
      legend: {
        itemWidth: 10,
        itemHeight: 3,
        bottom: 0,
        textStyle: { color: '#BCCBE0' },
        data: [
          {
            name: '秩序散乱次数',
            itemStyle: {
              color: '#00CCDE',
            },
          },
          {
            name: '有效巡视次数',
            itemStyle: {
              color: '#3F8DED',
            },
          },
        ],
      },
      xAxis: [
        {
          type: 'category',
          name: '',
          data: xAisData,
        },
      ],
      yAxis: [
        {
          name: '巡视次数(次)',
          nameLocation: 'middle',
          nameGap: 34,
          axisLine: {
            lineStyle: {
              color: '#748399',
            },
          },
          axisLabel: {
            color: '#DEDEDE',
            formatter: function (value: any) {
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + 'W'
              } else if (value >= 10000000) {
                value = value / 10000000 + 'KW'
              }
              return value
            },
          },
          splitLine: {
            lineStyle: {
              color: '#748399' + 70,
            },
          },
        },
      ],
      grid: {
        top: '10px',
        left: '24px',
        right: '8%',
        bottom: 30,
        containLabel: true,
      },
      series: [
        {
          name: '秩序散乱次数',
          type: 'bar',
          data: disorderly?.data,
          stack: 'stack1',
          itemStyle: {
            normal: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#4BD2DE',
                  },
                  {
                    offset: 1,
                    color: '#00CCDE',
                  },
                ],
              },
              borderRadius: [2, 8, 0, 0],
            },
          },
        },
        {
          name: '有效巡视次数',
          type: 'line',
          data: patrolNum?.data,
          stack: 'stack2',
          itemStyle: {
            borderRadius: 2,
            color: '#3F8DED',
          },
          barWidth: 20,
        },
      ],
    }
    chartRef.current?.setOption(option)
  }
  return (
    <ContentBox
      title="秩序巡视详情统计"
      showTitleDecoration={true}
      style={{
        width: '100%',
      }}
    >
      <div style={{ position: 'absolute', right: 20, top: 15 }}>
        <Select
          defaultValue={1}
          allowClear={false}
          style={{ width: 96 }}
          className="custom-select subway-custom-select"
          popupClassName="custom-select-dropdown subway-custom-select-dropdown"
          options={[
            { label: '今日', value: 1 },
            { label: '近7日', value: 2 },
            { label: '近30日', value: 3 },
          ]}
          onChange={handleChange}
        ></Select>
      </div>
      <div
        ref={containerRef}
        style={{
          width: '100%',
          height: '270px',
          color: '#fff',
          marginTop: 40,
        }}
      ></div>
    </ContentBox>
  )
}

export default PatrolOrderStatistics
