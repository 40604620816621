import React, { useState } from 'react'

interface AlarmData {
  id: number
  orderType: number
  orderTypeName: string
  stationName: string
  currentBikeCount: string
  predictBikeCount: string
  predictTime: string
}

interface Props {
  data: AlarmData[]
}

const alarm_notice_url = require('_root/assets/img/alarm_notice.png')
const alarm_arrow_url = require('_root/assets/img/alarm_arrow.png')

const AlarmList: React.FC<Props> = ({ data }) => {
  const [showList, setShowList] = useState(false)

  const renderLegendDesk = () => {
    const colums = [
      {
        key: 'orderTypeName',
        title: '',
        render(text: string, record: any) {
          return (
            <div className="startCenter">
              <span
                className="alarm-bage"
                style={{
                  backgroundColor:
                    record.orderType === 1 ? '#FFD400' : record.orderType === 2 ? '#CC3131' : '',
                }}
              ></span>
              <span
                style={{
                  color:
                    record.orderType === 1 ? '#FFD400' : record.orderType === 2 ? '#CC3131' : '',
                }}
              >
                {text}
              </span>
            </div>
          )
        },
      },
      {
        key: 'stationName',
        title: '地铁站',
        render(text: string) {
          return <span style={{ color: '#BCCBE0' }}>{text}</span>
        },
      },
      {
        key: 'currentBikeCount',
        title: '当前单车量',
      },
      {
        key: 'predictBikeCount',
        title: '用车需求预测',
      },
      {
        key: 'predictTime',
        title: '预测时间',
      },
    ]

    return (
      <div>
        {/* header */}
        <div className="betweenCenter" style={{ color: '#BCCBE0' }}>
          {colums.map((item: any, i) => (
            <div
              key={i}
              style={{ height: 34, width: item.width ? item.width : 100 / colums.length + '%' }}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div
          style={{ height: 1, backgroundColor: 'rgba(116, 131, 153, 0.2)', marginBottom: 11 }}
        ></div>
        {/* body */}
        <div>
          {data.map((alarm: AlarmData, idx) => (
            <div
              key={alarm.id}
              className="betweenCenter"
              style={{ marginBottom: idx === data.length - 1 ? 0 : 12 }}
            >
              {colums.map((item: any, idx) => {
                return (
                  <div
                    key={idx}
                    style={{ width: item.width ? item.width : 100 / colums.length + '%' }}
                  >
                    {item.render ? (
                      item.render(alarm[item.key as keyof AlarmData] as any, alarm, data)
                    ) : (
                      <span>{alarm[item.key as keyof AlarmData]}</span>
                    )}
                  </div>
                )
              })}
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="subway-alarm">
      <div className="betweenCenter alarm-tip br4 mb10">
        <div className="startCenter">
          <img src={alarm_notice_url} width={16} className="mr10"></img>
          <span>警告信息：有{data.length}个地铁站存在车辆需求供应不足和车辆溢出情况</span>
        </div>
        <div className="pointer centerCenter" onClick={() => setShowList(!showList)}>
          <span className="mr5">{showList ? '收起' : '展开'}</span>
          <img
            src={alarm_arrow_url}
            className={`alarm-arrow ${showList ? 'alarm-arrow-up' : ''}`}
          ></img>
        </div>
      </div>
      {showList && <div className="alarm-list">{renderLegendDesk()}</div>}
    </div>
  )
}

export default AlarmList
