import moment, { Moment } from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import findKey from 'lodash/findKey'
import pick from 'lodash/pick'
import isObject from 'lodash/isObject'
import isNil from 'lodash/isNil'
import throttle from 'lodash/throttle'
import Aegis from 'aegis-web-sdk'

import {
  businessLineMap,
  businessLineTextMap,
  regExpMap,
  ResourceMap,
  roleTypeTextMap,
  roleTypeMap,
  oneDayStamp,
  oneHourStamp,
} from './consts'

import type { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface'

export const IS_LOCAL = window.location.origin.indexOf('localhost') > -1

export const delay = (ms: number): Promise<undefined> => new Promise(res => setTimeout(res, ms))

export const isFalsy = (val: unknown): val is Falsy => !val

export const isNullish = (val: unknown) => isNil(val)

export const isNumber = (val: number | string): val is number =>
  regExpMap.posIntAndZero.test(val + '')

export const getNamespace = (type: string) => /[A-Z]+/.exec(type)?.[0].toLocaleLowerCase() as string

// 时间格式相关方法
export const formatDate = (date: moment.Moment, format = 'YYYY-MM-DD'): string => {
  return date ? date.format(format) : ''
}

export const formatDateShowTime = (date: string | number | Date | moment.Moment): string => {
  return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
}

export const momentDate = (date: string | number | Date): Moment => {
  return moment(date)
}

export const getDateTimestamp = (date: Date, withMillisecond = true) => {
  // withMillisecond 是否取毫秒
  if (withMillisecond) {
    return date ? moment(date).valueOf() : undefined
  } else {
    const time = new Date(date)
    return Date.parse(time as any) // Date.parse() 不取毫秒
  }
}

export const currentTime = () => {
  const time = moment().format('ll HH:mm').slice(5)
  const day = moment().format('dddd').replace('星期', '周')
  return time.split(' ')[0] + ' ' + day + ' ' + time.split(' ')[1]
}

export const getCurHour = () => +moment().format('HH')

export const getNextHour = () => +moment().format('HH') + 1

export const getTextWithDivider = (before: string, after?: string): string => {
  before = before || ''
  after = after || ''
  const divide = before && after ? '/' : ''
  return before + divide + after
}

export const getTextWithSuffix = (text = '', suffix = ''): string => {
  return text === null || text === undefined ? '' : text + suffix
}

export const getKeyByVal = <T extends Record<string, unknown>>(obj: T, value: ValueOf<T>) => {
  return Object.keys(obj).find(key => obj[key] === value)
}

export const getLabelByValue = (obj: any, value: any, key = 'label') => {
  return obj[findKey(obj, ['value', value]) as keyof typeof obj]?.[key]
}

export const getOptsByTextMap = <T extends { [key: string]: any } = { [key: string]: any }>(
  textMap: T
): Options => {
  return Object.keys(textMap).map(key => {
    return {
      label: textMap[key],
      value: +key,
    }
  })
}

// export const getRoleTypeOpts = () => getOptsByTextMap(roleTypeTextMap)

export const getRoleTypeOpts = (roleType: RoleType) => {
  if (roleType === roleTypeMap.managerRole) {
    return getOptsByTextMap(roleTypeTextMap)
  } else if (roleType === roleTypeMap.platformRole) {
    return getOptsByTextMap(
      pick(roleTypeTextMap, [roleTypeMap.platformRole, roleTypeMap.enterpriseRole])
    )
  } else if (roleType === roleTypeMap.enterpriseRole) {
    return getOptsByTextMap(pick(roleTypeTextMap, roleTypeMap.enterpriseRole))
  }
  return []
}

export const triggerResize = () => {
  setTimeout(function () {
    //手动触发窗口resize事件
    if (document.createEvent as any) {
      const event = new Event('resize')
      window.dispatchEvent(event)
    } else if (document.createEventObject as any) {
      window.fireEvent('onresize')
    }
  })
}

export const listenResize = (fn: () => void, throttleTime = 100) => {
  const throttleFn = throttle(function () {
    typeof fn === 'function' && fn?.()
  }, throttleTime)
  window.addEventListener('resize', throttleFn)
  return () => window.removeEventListener('resize', throttleFn)
}

export const trimFormInput = (event: React.ChangeEvent<HTMLInputElement>) =>
  event.target.value ? event.target.value.trim() : ''

export const normFile = (e: UploadChangeParam<UploadFile>) => {
  let result = []
  if (Array.isArray(e)) {
    result = e
  } else {
    result = e ? e.fileList : []
  }
  return result.filter((item: any) => item.status !== undefined)
}

export const getFileList = (attachments: string[]): UploadFile[] => {
  if (!attachments || !attachments.length) {
    return []
  }
  return attachments.map((url, idx) => ({
    uid: '-' + (idx + 1),
    name: url,
    status: 'done',
    url: url,
  }))
}

export const getEleHeightByClassName = (className: string) => {
  const ele = document.getElementsByClassName(className)[0]
  if (!ele) {
    return
  }
  const range = document.createRange()
  range.selectNodeContents(ele)
  const rect = range.getBoundingClientRect()
  return rect.height
}

export const toLocaleString = (num: any): string => {
  return typeof num === 'number' ? (+num).toLocaleString() : '--'
}

export const getBizArrFromBizType = (biz?: BusinessType | null, needDouble = true) => {
  if (!biz) {
    return []
  } else if (biz === businessLineMap.double) {
    return needDouble
      ? Object.values(businessLineMap)
      : Object.values(businessLineMap).filter(item => item !== businessLineMap.double)
  } else {
    return [biz]
  }
}

export const getBizOptions = (businessType: BusinessType, needDoubleBiz: boolean) => {
  return getBizArrFromBizType(businessType, needDoubleBiz).map(item => ({
    label: businessLineTextMap[item as keyof typeof businessLineTextMap],
    value: item,
  }))
}

export const selectBizFromBizArr = (bizArr: BusinessType[]) =>
  bizArr.length > 1 ? businessLineMap.double : bizArr[0]

export const selectBizFromBizType = (biz: BusinessType, needDouble = true) => {
  return selectBizFromBizArr(getBizArrFromBizType(biz, needDouble))
}

export const getFirstRoutePath = (routes: Routes): Route['path'] | undefined => {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i]
    if (route.routes) {
      return getFirstRoutePath(route.routes)
    } else {
      return route.path
    }
  }
}

export const filterTreeNode = <T extends DataNode = DataNode>(
  tree: T[],
  key = 'resource',
  childName = 'children'
): T[] => {
  const loop = (tree: T[]): T[] => {
    return tree
      .map(node => {
        if (node[childName as keyof T]) {
          return { ...node, [childName]: loop(node[childName as keyof T] as T[]) }
        }
        if (node[key as keyof T]) {
          return node
        }
        return null as any
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)
  }
  return loop(tree)
}

export const filterRoutes = (routes: Routes): Routes => {
  const arr: Routes = []
  routes.forEach(route => {
    if (route.resource === undefined || route.resource) {
      arr.push(route)
      if (route.routes) {
        route.routes = filterRoutes(route.routes)
      }
    }
  })
  return arr
}

export const getBoundsSwNe = (map: any) => {
  if (!map) {
    return {}
  }
  const bounds = map.getBounds()
  const sw = bounds.getSouthWest()
  const ne = bounds.getNorthEast()
  return { sw, ne }
}

export const getBoundsPolygon = (map: any) => {
  const { sw = {}, ne = {} } = getBoundsSwNe(map)
  const polygon =
    sw.lng +
    ',' +
    ne.lat +
    ';' +
    ne.lng +
    ',' +
    ne.lat +
    ';' +
    ne.lng +
    ',' +
    sw.lat +
    ';' +
    sw.lng +
    ',' +
    sw.lat
  return polygon
}

export const safeJsonParse = (jsonStr: string, dataType = {}) => {
  let data
  if (jsonStr) {
    try {
      data = JSON.parse(jsonStr)
    } catch (err) {
      data = dataType
    }
  } else {
    data = dataType
  }
  return data
}

export const changeLngLat = (arry = [], isLatLng = false): any => {
  const arr = []
  if (typeof arry[0] == 'number') {
    const latLng = isLatLng ? [arry[0], arry[1]] : [arry[1], arry[0]]
    return new TMap.LatLng(latLng[0], latLng[1])
  } else {
    for (let i = 0; i < arry.length; i++) {
      arr.push(changeLngLat(arry[i], isLatLng))
    }
  }
  return arr
}

/**
 * resourceIds 所有的子节点
 */
export const getAllParentIds = <T extends DataNode = DataNode, R extends React.Key = React.Key>(
  tree: T[],
  resourceIds: R[]
): R[] => {
  const plainedTree = plainTree(tree)
  const allParentIds: React.Key[] = []
  resourceIds = resourceIds || []
  const walk = (plainedTree: T[], resourceIds: R[]) => {
    // 找到上一层父节点
    const parentIds: React.Key[] = []
    resourceIds.forEach((id: any) => {
      const parentId = plainedTree.find((node: any) => node.key === id)?.parentId
      if (parentId) {
        parentIds.push(parentId)
      }
    })
    allParentIds.push(...parentIds)
    if (parentIds?.length) {
      walk(plainedTree, Array.from(new Set(parentIds)) as R[])
    }
  }
  walk(plainedTree, resourceIds)
  return [...(new Set(allParentIds) as any)]
}

export const plainTree = <T extends DataNode = DataNode>(treeData: T[]): T[] => {
  let clonedData = cloneDeep(treeData)
  const resultArr: T[] = []
  let i = 0
  while (i < clonedData.length) {
    const data = clonedData[i]
    if (data.children) {
      clonedData = clonedData.concat(data.children as T[])
    }
    delete data.children
    resultArr.push(data)
    i++
  }
  return resultArr
}

export const getFindUserResoureceFn =
  (userResources: UserResources) => (id: UserResource['id']) => {
    // if (IS_LOCAL) {
    //   return {}
    // }
    return userResources.find((item: UserResource) => item.id === id) || null
  }

export const validateModuleResource = (resource?: Record<string, UserResource | null>) => {
  return resource && Object.values(resource).some(item => !!item)
}

export const validateAllResource = (resourceMap: ResourceMap) => {
  return Object.keys(resourceMap).some(resourceKey =>
    validateModuleResource(resourceMap[resourceKey as keyof typeof resourceMap])
  )
}

export const sliceTrendTitle = (title?: string) => {
  if (!title) {
    return ''
  }
  return title.length > 4 ? title.slice(0, 4) : title
}

export const findTreeNode = <T, K = string, V = unknown>(
  tree: T[],
  key: K,
  value: V,
  childName = 'children'
): T | undefined => {
  if (!tree?.length) {
    return
  }
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node[key as keyof T] === value) {
      return node
    } else if (node[childName as keyof T]) {
      const route = findTreeNode(node[childName as keyof T] as T[], key, value, childName)
      if (route) {
        return route
      }
    }
  }
}

export const transformArea = <T extends AreaData = AreaData>(
  data: T[] = [],
  transformer?: (node: T) => Record<string, unknown>
): T[] => {
  const walk = (data: T[], fatherChain?: string, fatherLevel?: string): T[] => {
    return data.map(item => {
      let newItem = {
        ...item,
        label: item.adName,
        title: item.adName,
        value: item.adCode,
        key: item.adCode,
        chain: fatherChain ? fatherChain + ',' + item.adCode : item.adCode,
        levelChain: fatherLevel ? fatherLevel + ',' + item.level : item.level,
      }
      if (transformer && isObject(transformer?.(item))) {
        newItem = { ...newItem, ...transformer?.(item) }
      }
      if (item.children) {
        return { ...newItem, children: walk(item.children as T[], item.chain, item.levelChain) }
      }
      return newItem
    })
  }
  return walk(data)
}

export const transformTreeData = <T extends DataNode = DataNode>(
  treeDataList: T[],
  transformer?: ((e: T) => Record<string, unknown>) | string,
  title = 'entityName',
  key = 'entityId'
): T[] => {
  if (typeof transformer === 'string' && key === 'entityId') {
    key = title
    title = transformer
  }
  const walk = (treeDataList: T[], parentId?: T['parentId']): T[] => {
    return treeDataList.map(item => {
      let newItem = {
        ...item,
        label: item[title as keyof T],
        title: item[title as keyof T],
        value: item[key as keyof T],
        key: item[key as keyof T],
        parentId: parentId || item.parentId,
      }
      if (typeof transformer === 'function' && isObject(transformer?.(item))) {
        newItem = { ...newItem, ...transformer?.(item) }
      }
      if (item.children) {
        return {
          ...newItem,
          children: walk(item.children as T[], item[key as keyof T] as T['parentId']),
        }
      }
      return newItem
    })
  }
  return walk(treeDataList)
}

export const tranformToOptions = <T extends Record<string, unknown> | DataNode>(
  data: T[] = [],
  labelKey = 'entityName',
  valueKey = 'entityId'
): Options => {
  return data.map(item => ({
    ...item,
    label: item[labelKey as keyof T],
    value: item[valueKey as keyof T],
  })) as Options
}

export const addFirstChildLatLng = <T extends AreaData = AreaData>(regions: T[] = []) => {
  if (!regions.length) {
    return
  }
  // 由于区域筛选器的全部选项没有经纬度，将第一个子节点的经纬度设置为该节点的经纬度
  const node = regions[0]
  if (node.adCode === '1' && !node.lat && node.children?.length) {
    const firstChildren = node.children[0]
    node.lat = firstChildren.lat
    node.lng = firstChildren.lng
  }
}

export const toFixed = <T extends number | string>(num?: T, len = 3) => {
  return num ? Number(num).toFixed(len) : num
}

export const markName = (name: string): string => {
  let userName = ''
  if (!name) return name
  const len = name.length
  if (len == 2) {
    userName = name.substring(0, 1) + '*'
  } else if (len >= 3) {
    userName =
      name.substring(0, 1) +
      Array(len - 2)
        .fill('*')
        .join('') +
      name.substring(len - 1)
  }
  return userName
}

export const initAegis = (reportId: string) => {
  if (!['pre', 'production'].includes(process.env.BASE_ENV)) {
    return
  }
  const userMap = {
    admin: 'e7e5YIlmQzxbP2GZE3', // 管理员
    client: '2eX9OFKw3R7qaLxEZq', // 平台用户
  }
  let id = ''
  if (reportId) {
    id = reportId
  } else {
    const isAdmin = /^(www|st|admin)(?=(.\w+)?.(gxcxjg|bikesupervision).com)/.test(location.host)
    id = isAdmin ? userMap.admin : userMap.client
  }
  if (!window.aegis && typeof Aegis === 'function') {
    window.aegis = new Aegis({
      id: id, // 上报 id
      uin: '', // 用户唯一 ID（可选）
      reportApiSpeed: true,
      reportAssetSpeed: true,
      spa: true,
      env: process.env.BASE_ENV,
      hostUrl: 'https://rumt-zh.com',
    })
  }
}

export const setAegisOpt = (opt = {}) => {
  const aegis = window.aegis
  if (aegis) {
    aegis.setConfig(opt)
  }
}

// report上报
export const reportAegis = ({ msg, level, ext1, trace }: any) => {
  const aegis = window.aegis
  if (aegis) {
    aegis.report({
      msg: msg,
      level: level || Aegis.logType.REPORT,
      ext1: ext1 || 'report-ext1',
      trace: trace || 'report-trace',
    })
  }
}

// 自定义上报事件
export const reportEventAegis = ({ name, ext1 }: any) => {
  const aegis = window.aegis
  if (aegis) {
    aegis.reportEvent({
      name: name, // 必填
      ext1: ext1 || 'event-ext1',
    })
  }
}

export const getFullScreenEle = () => {
  // const elem = document.getElementsByClassName('layout')[0]
  const elem = document.body
  return elem as any
}

export const isLngLatCoordinate = (str: string) => {
  const reg =
    /^([-+]?(0(\.\d{1,10})?|([1-9](\d)?)(\.\d{1,10})?|1[0-7]\d{1}(\.\d{1,10})?|180\.0{1,10}))+,+([-+]?((0|([1-8]\d?))(\.\d{1,10})?|90(\.0{1,10})?))$/
  return reg.test(str)
}

export function isInvalidData(data: any) {
  if (data === undefined || data === null || data === '') {
    return true
  }
  return false
}

export function lsSetItem(key: string, value: string) {
  localStorage?.setItem(key, value)
}

export function lsGetItem(key: string) {
  return localStorage?.getItem(key)
}

/**
 * 将时间戳转换为天、小时、分钟
 * @param timestamp 时间戳，单位为毫秒
 * @returns 返回一个对象，包含天、小时、分钟
 */
export const convertTimestampToDHM = (timestamp: number) => {
  const days = Math.floor(moment.duration(timestamp).asDays())
  const daysStamp = days * oneDayStamp
  const hours = Math.floor(moment.duration(timestamp - daysStamp).asHours())
  const hoursStamp = hours * oneHourStamp
  const minutes = Math.floor(moment.duration(timestamp - daysStamp - hoursStamp).asMinutes())
  return {
    days,
    hours,
    minutes,
  }
}

/**
 * 将时间戳转换为分钟
 * @param timestamp 时间戳，单位为毫秒
 * @returns 返回转换后的分钟数
 */
export const convertTimestampToMinutes = (timestamp: number): number => {
  return Math.floor(moment.duration(timestamp).asMinutes())
}

// 判断是否存在重名
export const hasDuplicateName = (names: any, key: string): boolean => {
  const nameSet = new Set()
  for (const item of names) {
    const value = item[key]
    if (!isInvalidData(value)) {
      if (nameSet.has(value)) {
        return true
      }
      nameSet.add(value)
    }
  }
  return false
}
