import React from 'react'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import locale from 'antd/lib/locale/zh_CN'
import 'moment/locale/zh-cn'

import { setTheme } from '@/utils/theme'
import { defaultSubjectColor, defaultMapBackgroundColor } from '@/utils/consts'
import { initAegis } from '@/utils/util'
import request from '@/utils/request'
import ErrorBoundary from './ErrorBoundary'

import App from './App'
import store from '../redux/store'

const setAegis = () => {
  request('appGetEntityByUrl').then(res => {
    if (res.code === 0 && res.data) {
      initAegis(res.data.reportId)
    }
  })
}

setTheme(defaultSubjectColor, defaultMapBackgroundColor)

moment.locale('zh-cn')

setAegis()

export default (props: React.PropsWithChildren<any>) => {
  return class Index extends React.Component {
    render() {
      return (
        <ErrorBoundary>
          <Provider {...props} store={store}>
            <ConfigProvider locale={locale}>
              <App {...props} />
            </ConfigProvider>
          </Provider>
        </ErrorBoundary>
      )
    }
  }
}
