import { useRef, useState, useEffect } from 'react'
import { Select } from 'antd'
import throttle from 'lodash/throttle'
import * as echarts from 'echarts'

import ContentBox from '../contentBox'
import request from '@/utils/request'
import { useAppSelector, useTimingRequest } from '@/utils/hooks'

import type { ColorMap, WordCostTimeStatistics } from './types'

type EChartsOption = echarts.EChartsOption

const defaultCompanyOpts = [{ label: '全部', value: -999 }]

const periodOpts = [
  { label: '今日', value: 1 },
  { label: '近7日', value: 2 },
  { label: '近30日', value: 3 },
]

const colorMap: ColorMap = {
  1: '#EB5038',
  2: '#E0812D',
  3: '#4BD2DE',
}

const OrderStatistics = () => {
  const [app] = useAppSelector(state => [state.app])
  const [companyOpts, setCompanyOpts] = useState<Options>(defaultCompanyOpts)
  const [costTimeStatistics, setCostTimeStatistics] = useState<WordCostTimeStatistics[]>([])
  const [searchParam, setSearchParam] = useState({
    period: periodOpts[0].value || null,
    companyId: defaultCompanyOpts[0].value || null,
  })
  const containerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<any>(null)

  useEffect(() => {
    if (app.currentEntity.entityId) {
      queryCompanyOpts()
    }
  }, [app.currentEntity.entityId])

  // 监听实体
  useTimingRequest(() => {
    if (app.currentEntity.entityId && app.currentArea.adCode) {
      workOrderStatistics()
    }
  }, [
    app.currentEntity.entityId,
    app.currentArea.adCode,
    app.currentFence.fenceId,
    app.currentFence.selectFenceId,
  ])

  const queryCompanyOpts = () => {
    const data = {
      entityId: app.currentEntity.entityId,
      isCheckUserType: false,
    }
    request('commonQueryParkCompanyOpts', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setCompanyOpts([...res.data] || [])
      }
    })
  }

  const workOrderStatistics = (newParam: Record<string, number> = {}) => {
    const data = {
      ...searchParam,
      ...newParam,
      fencingId: app.currentFence.selectFenceId || app.currentFence.fenceId,
      entityId: app.currentEntity.entityId,
      adCode: app.currentArea.adCode,
      isCheckUserType: false,
    }
    // 工单统计
    request('patrolCenterTaskOrderStatistics', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setSearchParam(data)
        containerRef.current && (containerRef.current.style.height = '230px')
        !chartRef.current && initChart()
        setOption(res.data)
      }
    })
    //工单耗时
    request('patrolCenterCostTimeStatistics', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setCostTimeStatistics(res.data)
      }
    })
  }

  const initChart = () => {
    chartRef.current = echarts.init(containerRef.current as any)
    window.addEventListener(
      'resize',
      throttle(function () {
        chartRef.current?.resize()
      }, 100)
    )
  }
  const setOption = (content: any) => {
    const count = content?.reduce((pre: number, cur: { value: number | string }) => {
      return pre + Number(cur.value || 0)
    }, 0)
    const option: EChartsOption = {
      tooltip: {
        trigger: 'item',
        show: true,
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(66, 75, 92, 0.2)',
          },
        },
        backgroundColor: '#424B5C',
        className: 'custom-echart-tooltip',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
        formatter: function (params: any) {
          return (
            '<div>' +
            '<span style="display:inline-block;margin-right:4px;width:3px;height:8px;background-color:' +
            params.color +
            ';"></span>' +
            ' ' +
            '<span style="color: #BCCBE0;">' +
            params.name +
            '</span>' +
            '<span style="display:inline-block;margin-right:20px;"></span>' +
            '<span style="float:right;font-family: AvenirLight;">' +
            params.value +
            '</span>' +
            '</div>'
          )
        },
      },
      title: {
        text: '工单总计(单)',
        subtext: count + '',
        textStyle: {
          fontFamily: 'Avenir',
          fontWeight: 900,
          fontSize: 14,
          color: 'rgba(255, 255, 255, 0.9)',
        },
        subtextStyle: {
          fontFamily: 'Avenir',
          color: 'rgba(255, 255, 255, 0.9)',
          fontWeight: 400,
          fontSize: 36,
        },
        itemGap: 18,
        textAlign: 'center',
        textVerticalAlign: 'middle',
        left: '66%',
        top: '44%',
      },
      legend: {
        width: '40',
        top: '20',
        left: '30',
        itemWidth: 8,
        itemHeight: 8,
        itemGap: 0,
        itemStyle: {
          borderWidth: 0,
        },
        textStyle: {
          color: '#646D7D',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: 28,
          rich: {
            text: {
              color: '#FFFFFF',
              fontSize: 16,
              fontWeight: 400,
              fontFamily: 'Avenir',
              padding: [28, 0, 10, -6],
            },
            1: {
              color: '#EB5038',
              fontSize: 14,
              fontWeight: 400,
              padding: [28, 0, 0, 0],
              fontFamily: 'PingFangSC-Regular',
            },
            2: {
              color: '#E0812D',
              fontSize: 14,
              fontWeight: 400,
              padding: [28, 0, 0, 0],
              fontFamily: 'PingFangSC-Regular',
            },
            3: {
              color: '#4BD2DE',
              fontSize: 14,
              fontWeight: 400,
              padding: [28, 0, 0, 0],
              fontFamily: 'PingFangSC-Regular',
            },
          },
        },
        formatter: function (val) {
          const data = content
          let value = ''
          for (let i = 0; i < data.length; i++) {
            if (data[i].name == val) {
              value =
                '{' +
                data[i].status +
                '|' +
                data[i].label +
                '}' +
                '\n' +
                ' {' +
                'text' +
                '|' +
                data[i].value +
                '}'
            }
          }
          return value
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['75%', '90%'],
          center: ['50%', '53%'],
          itemStyle: {
            borderRadius: 4,
            borderWidth: 2,
            borderColor: '#1b2333',
            color: (params: any) => colorMap[params.data.status],
          },
          label: {
            show: false,
            position: 'center',
          },
          minAngle: 10,
          left: '35%',
          top: 0,
          bottom: 0,
          data: content,
        },
      ],
    }
    chartRef.current?.setOption(option)
    chartRef.current?.resize()
  }

  const renderContent = () => {
    return (
      <div>
        <p className="order-statistics">工单统计</p>
        <div
          ref={containerRef}
          style={{
            width: '100%',
          }}
        ></div>
      </div>
    )
  }
  const renderOrderTime = () => {
    return (
      <div style={{ marginBottom: '20px' }}>
        <p className="order-statistics">工单耗时</p>
        <div className="order-time">
          {costTimeStatistics.map((item: WordCostTimeStatistics, index: number) => {
            return (
              <div key={index} className="order-time-statistics">
                <p className="order-time-brand">{item.label}</p>
                <p className="order-time-minute">{item.value}</p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const handleBrandChange = (value: number) => {
    workOrderStatistics({ companyId: value })
  }

  const handleChange = (value: number) => {
    workOrderStatistics({ period: value })
  }

  return (
    <ContentBox
      showTitleDecoration={true}
      showBiz={false}
      title="秩序工单统计"
      style={{ position: 'relative' }}
    >
      <div style={{ position: 'absolute', right: 20, top: 15 }}>
        <Select
          defaultValue={defaultCompanyOpts[0].value}
          allowClear={false}
          style={{ width: 96 }}
          className="custom-select mr10 subway-custom-select"
          popupClassName="custom-select-dropdown subway-custom-select-dropdown"
          options={companyOpts}
          onChange={handleBrandChange}
        ></Select>
        <Select
          defaultValue={1}
          allowClear={false}
          style={{ width: 96 }}
          className="custom-select subway-custom-select"
          popupClassName="custom-select-dropdown subway-custom-select-dropdown"
          options={periodOpts}
          onChange={handleChange}
        ></Select>
      </div>
      {renderContent()}
      {renderOrderTime()}
    </ContentBox>
  )
}

export default OrderStatistics
