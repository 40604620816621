import { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { message } from 'antd'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

import AlarmList from './AlarmList'
import MapControl from './MapControl'
import LayerControl from './LayerControl'
import DiagnosisModal from './DiagnosisModal'
import {
  drawSubwayLine,
  drawSubwayStop,
  drawSubwayStation,
  drawSubwayExitEnter,
  drawStationStatus,
} from './SubwayLine'
import { drawRegionArea, drawBikeArea, drawParkingArea } from './LayerRender'
import { getBoundsPolygon } from '@/utils/util'
import { DATACENTER } from '@/redux/actionTypes'
import { businessLineMap, districtLevelMap, roleTypeMap } from '@/utils/consts'
import { useAppSelector, useAppDispatch, useUserResources } from '@/utils/hooks'
import { cityZoom, streetZoom, bikeZoom, stationZoom, exitEnterZoom } from './consts'
import {
  updateBikeInfo,
  updateCityInfo,
  updateParkingAreaInfo,
  initParkingAreaInfo,
  initBikeInfo,
  initCityInfo,
} from '@/redux/reducers/dataCenter'
import { updateFullScreen } from '@/redux/reducers/app'
import { searchType } from './LayerControl'

import type { LayerParam, SearchParam } from './LayerControl'
import type { MapBikeInfo, ParkingArea } from '@/redux/reducers/dataCenter'

import './index.less'

const stopData = require('_root/mock/subwayCenter/subwayCenterStop.json').data
const lineData = require('_root/mock/subwayCenter/subwayCenterLine.json').data
const stationData = require('_root/mock/subwayCenter/subwayCenterStation.json').data
const alarmList = require('_root/mock/subwayCenter/subwayCenterAlarmList.json').data
const exitEnterData = require('_root/mock/subwayCenter/subwayCenterExitEnter.json').data

const Map = () => {
  const mapRef = useRef<any>()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const stationClearRef = useRef<any>(null)
  const exitEnterClearRef = useRef<any>(null)

  const dispatch = useAppDispatch()
  const [app, dataCenter] = useAppSelector(state => [state.app, state.dataCenter])
  const [dataCenterRs] = useUserResources(resource => [resource.dataCenter])

  const [layerParam, setLayerParam] = useState<LayerParam>({} as LayerParam)
  const [searchParam, setSearchParam] = useState<SearchParam>({} as SearchParam)
  const [analysisVisible, setAnalysisVisible] = useState<boolean>(false)
  const [selStop, setSelStop] = useState<any>(null)
  const { fullScreen } = app

  const baseParam = useMemo(() => {
    return {
      entityId: app.currentEntity.entityId,
      adCode: app.currentArea.adCode,
      businessType: app.currentBiz.businessType,
    }
  }, [app])

  const getPolygon = useCallback(() => {
    return mapRef.current ? getBoundsPolygon(mapRef.current) : ''
  }, [mapRef.current])

  const getZoom = useCallback(() => {
    return mapRef.current?.getZoom()
  }, [mapRef.current])

  const setZoom = useCallback(
    zoom => {
      mapRef.current?.setZoom(zoom)
    },
    [mapRef.current]
  )

  useEffect(() => {
    if (containerRef.current && app.currentArea && !mapRef.current) {
      mapRef.current = new TMap.Map(containerRef.current, {
        // center: new TMap.LatLng(
        //   app.currentArea?.lat || 30.654925,
        //   app.currentArea?.lng || 104.081488
        // ),
        zoom: 10,
        // @ts-ignore
        zooms: [10, 20],
        scrollable: true,
        showControl: false,
        mapStyleId: 'style 5 大屏',
      })
    }
  }, [containerRef, app.currentArea])

  // 监听行政区变化，重置中心点
  useEffect(() => {
    let isSubscribed = true
    if (app.currentArea.lat && app.currentArea.lng && mapRef.current) {
      setTimeout(() => {
        // @ts-ignore
        const center = new TMap.LatLng(app.currentArea.lat, app.currentArea.lng)
        isSubscribed && mapRef.current.setCenter(center)
      }, 0)
    }
    return () => {
      isSubscribed = false
    }
  }, [app.currentArea])

  useEffect(() => {
    if (mapRef.current) {
      const handleZoomChange = debounce(viewChange, 300)
      mapRef.current.on('dragend', viewChange)
      mapRef.current.on('zoom', handleZoomChange)
      window.addEventListener('resize', handleZoomChange)
      return () => {
        mapRef.current.off('dragend', viewChange)
        mapRef.current.off('zoom', handleZoomChange)
        window.removeEventListener('resize', handleZoomChange)
      }
    }
  }, [app.currentArea, layerParam, searchParam])

  // =================================== 请求数据 start ===================================

  // 搜索框输入条件搜索
  useEffect(() => {
    if (searchParam?.value) {
      paramSearch(true)
    } else {
      clearAllInfo()
      viewChange()
    }
  }, [searchParam])

  useEffect(() => {
    // 进入地图有两种情况
    // 一是从行政地图下钻，这时候的level是市级，需要查询该市下各区县分布的数据
    // 二是从区域筛选框选择的区、街道，这时候的level是市级以下，需要查询车辆位置的数据
    if (app.currentArea.level === districtLevelMap.city) {
      // cityInfo
      if (getZoom() !== cityZoom) {
        setZoom(cityZoom)
      } else {
        viewChange()
      }
    } else if (app.currentArea.level >= districtLevelMap.area) {
      // bikeInfo
      if (app.currentArea.level === districtLevelMap.area && getZoom() !== streetZoom) {
        // level到区，可能是左上角选的区，也可能是点击区的气泡
        // 左上角选的，需要将zoom设置到 streetZoom
        // 点击区的气泡，需要将zoom向下钻到 streetZoom
        setZoom(app.currentArea.zoom || streetZoom)
      } else if (app.currentArea.level === districtLevelMap.street && getZoom() !== bikeZoom) {
        // level到区，可能是左上角选的街道，也可能是点击街道的气泡
        // 左上角选的，需要将zoom设置到 bikeZoom
        // 点击区的气泡，需要将zoom向下钻到 bikeZoom，来自于 app.currentArea.zoom
        setZoom(app.currentArea.zoom || bikeZoom)
      } else {
        viewChange()
      }
    }
  }, [app.currentArea])

  // 点击图层车辆类型、车辆状态、企业品牌
  useEffect(() => {
    if (layerParam.bikeType) {
      queryBikeOrCityInfo()
    }
  }, [layerParam.bikeType, layerParam.bikeStatus, layerParam.brandCodeList])

  // 点击图层区域点位
  useEffect(() => {
    if (!isEmpty(layerParam.areaTypeList)) {
      queryParkingAreaInfo()
    } else {
      clearParkingInfo()
    }
  }, [layerParam.areaTypeList])

  // 区域范围
  useEffect(() => {
    if (app.currentArea.adCode) {
      queryAreaRegion()
    }
  }, [app.currentArea])

  // =================================== 绘制区域 start ===================================
  // 地铁线路
  useEffect(() => {
    // 能进入当前页面，已具备地铁资源点，这里无需再判断资源点
    if (lineData?.length && mapRef.current) {
      return drawSubwayLine({ map: mapRef.current, lineData })
    }
  }, [lineData])

  // 地铁站点
  useEffect(() => {
    if (!isEmpty(alarmList) && !isEmpty(stationData) && !isEmpty(stopData) && mapRef.current) {
      return drawSubwayStop({
        map: mapRef.current,
        stopData,
        stationData,
        alarmList,
        onViewAnalysis: handelViewAnalysis,
      })
    }
  }, [stopData, stationData, alarmList])

  // 站台状态
  useEffect(() => {
    if (!isEmpty(alarmList) && !isEmpty(stationData) && !isEmpty(stopData) && mapRef.current) {
      return drawStationStatus({
        map: mapRef.current,
        alarmList,
        stationData,
        onViewAnalysis: handelViewAnalysis,
        stopData,
      })
    }
  }, [alarmList, stationData, stopData])

  const renderStationLayer = () => {
    if (stationData?.length && mapRef.current) {
      if (getZoom() >= stationZoom && !stationClearRef.current) {
        stationClearRef.current = drawSubwayStation({
          map: mapRef.current,
          stationData,
          alarmList,
        })
      } else {
        if (getZoom() < stationZoom && stationClearRef.current) {
          stationClearRef.current()
          stationClearRef.current = null
        }
      }
    }
  }

  const renderExitEnterLayer = () => {
    if (exitEnterData?.length && mapRef.current) {
      if (getZoom() >= exitEnterZoom && !exitEnterClearRef.current) {
        exitEnterClearRef.current = drawSubwayExitEnter({
          map: mapRef.current,
          exitEnterData,
        })
      } else {
        if (getZoom() < exitEnterZoom && exitEnterClearRef.current) {
          exitEnterClearRef.current()
          exitEnterClearRef.current = null
        }
      }
    }
  }

  // 区县聚合信息
  useEffect(() => {
    // return addAreaDonut({
    //   map: mapRef.current,
    //   cityInfo: dataCenter.cityInfo,
    //   layerParam,
    //   dispatch,
    // })
  }, [dataCenter.cityInfo, layerParam])

  // 行政区划范围信息
  useEffect(() => {
    return drawRegionArea({ map: mapRef.current, areaInfo: dataCenter.areaInfo })
  }, [dataCenter.areaInfo])

  // 绘制 车辆位置
  useEffect(() => {
    return drawBikeArea({
      bikeInfo: dataCenter.bikeInfo,
      map: mapRef.current,
      dataCenterResource: dataCenterRs,
    })
  }, [dataCenter.bikeInfo])

  // 绘制 停车点
  useEffect(() => {
    return drawParkingArea({
      map: mapRef.current,
      parkingAreaInfo: dataCenter.parkingAreaInfo,
      dataCenterResource: dataCenterRs,
    })
  }, [dataCenter.parkingAreaInfo])

  // =================================== 清除图层区域 ===================================

  const clearParkingInfo = () => {
    dispatch(updateParkingAreaInfo(initParkingAreaInfo))
  }

  const clearCityInfo = () => {
    dispatch(updateCityInfo(initCityInfo))
  }

  const clearBikeInfo = () => {
    dispatch(updateBikeInfo(initBikeInfo))
  }

  const clearAllInfo = () => {
    clearCityInfo()
    clearBikeInfo()
    clearParkingInfo()
  }

  // =================================== 请求数据方法 ===================================

  const queryBikeOrCityInfo = () => {
    // 当前zoom大于bikeZoom，查询车辆信息
    if (getZoom() >= bikeZoom) {
      queryBikeInfo()
    } else {
      clearBikeInfo()
    }
    // else {
    //   // 当前zoom大于bikeZoom，分两种情况
    //   // 一是当前行政区为市、区查询聚合信息
    //   // 二是当前行政区为街道，需要查询车辆信息
    //   if (cityAreaLevel.includes(app.currentArea.level)) {
    //     clearBikeInfo()
    //     queryCityInfo()
    //   } else if (getZoom() > minZoom) {
    //     clearCityInfo()
    //     queryBikeInfo()
    //   }
    // }
  }

  // 执行条件搜索
  const paramSearch = (toCenter?: boolean) => {
    if ([searchType.bikeId, searchType.bikeNum].includes(searchParam?.type)) {
      return searchBike(toCenter)
    } else if ([searchType.parkingId, searchType.parkingName].includes(searchParam?.type)) {
      return searchParkingArea(toCenter)
    }
  }

  const viewChange = () => {
    // 拖动屏幕搜索
    if (searchParam?.value) {
      return paramSearch()
    }
    // 渲染站台进出口
    renderExitEnterLayer()
    // 渲染地铁站点
    renderStationLayer()
    // 地图缩放 需要控制站点名是否显示
    queryBikeOrCityInfo()
    queryParkingAreaInfo()
  }

  const queryParkingAreaInfo = () => {
    const polygon = getPolygon()
    if (polygon && !isEmpty(layerParam.areaTypeList)) {
      dispatch({
        type: DATACENTER.QUERY_PARKING_AREA_INFO,
        payload: {
          ...baseParam,
          layerParam,
          polygon,
          zoom: getZoom() >= streetZoom ? 1 : 0,
        },
      })
    }
  }

  const queryCityInfo = () => {
    // 关闭请求城市聚合信息
    // if (!isEmpty(layerParam)) {
    //   dispatch({
    //     type: DATACENTER.QUERY_CITY_INFO,
    //     payload: {
    //       ...baseParam,
    //       layerParam,
    //     },
    //   })
    // }
  }

  const queryBikeInfo = () => {
    const polygon = getPolygon()
    if (polygon && !isEmpty(layerParam)) {
      // dispatch({
      //   type: DATACENTER.QUERY_BIKE_INFO,
      //   payload: {
      //     ...baseParam,
      //     polygon,
      //     layerParam,
      //   },
      // })
      // 当前地铁附近车辆使用mock数据，后续会改用真实接口
      dispatch({
        type: DATACENTER.QUERY_SUBWAY_BIKE_INFO,
        payload: {
          // ...baseParam,
          // polygon,
          // layerParam,
          subway: 'ALL',
        },
      })
    }
  }

  const queryAreaRegion = () => {
    if (app.currentArea.adCode) {
      dispatch({
        type: DATACENTER.QUERY_AREA_REGION,
        payload: {
          adCode: app.currentArea.adCode,
          entityId: app.currentEntity.entityId,
        },
      })
    }
  }

  const searchBike = (toCenter?: boolean) => {
    clearAllInfo()
    dispatch({
      type: DATACENTER.QUERY_SEARCH_BIKE,
      payload: {
        companyId:
          app.userInfo.roleType === roleTypeMap.enterpriseRole ? app.userInfo.companyId : undefined,
        ...(searchParam.type === searchType.bikeId
          ? { bikeId: searchParam.value }
          : { bikePlate: searchParam.value }),
        ...baseParam,
        polygon: getPolygon(),
      },
      cb: (position?: MapBikeInfo[0]) => {
        if (position) {
          toCenter && mapRef.current.setCenter(new TMap.LatLng(position.lat, position.lng))
        } else {
          message.warning('暂无搜索结果')
        }
      },
    })
  }

  const searchParkingArea = (toCenter?: boolean) => {
    clearAllInfo()
    dispatch({
      type: DATACENTER.QUERY_SEARCH_PARKING_AREA,
      payload: {
        ...baseParam,
        parkingPointValue: searchParam.value,
        polygon: getPolygon(),
      },
      cb: (parkingArea?: ParkingArea) => {
        if (parkingArea) {
          toCenter &&
            mapRef.current.setCenter(
              new TMap.LatLng(parkingArea.centerPoint[1], parkingArea.centerPoint[0])
            )
        } else {
          message.warning('暂无搜索结果')
        }
      },
    })
  }

  const handleLayerControlChange = (state: LayerParam) => {
    setLayerParam(state)
  }

  const handleSearch = (param: SearchParam) => {
    setSearchParam(param)
  }

  const handelViewAnalysis = (stop: any) => {
    setSelStop(stop)
    setAnalysisVisible(true)
  }

  return (
    <div className="subway-center-map">
      {/* <Spin
        tip={<span style={{ color: '#fff' }}>loading...</span>}
        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />}
        spinning={loading.datacenter}
        // wrapperClassName={classnames('map-spin', { 'full-map-spin1': fullScreen })}
        wrapperClassName={`map-spin ${fullScreen ? 'full-map-spin1' : ''}`}
      > */}
      <div className="map-container" ref={containerRef}></div>
      <div
        className={classnames('layer-control-container', {
          'layer-control-container-double': app.currentBiz.businessType === businessLineMap.double,
        })}
        style={{ top: 118 }}
      >
        <AlarmList data={alarmList}></AlarmList>
        <LayerControl
          map={mapRef.current}
          stopData={stopData}
          onViewAnalysis={handelViewAnalysis}
          onSearch={handleSearch}
          onChange={handleLayerControlChange}
        ></LayerControl>
      </div>
      <div className="bottom-control betweenEnd">
        <div></div>
        <MapControl
          fullScreen={fullScreen}
          map={mapRef.current}
          onFullScreenClick={isFull => dispatch(updateFullScreen(isFull))}
        ></MapControl>
      </div>
      <DiagnosisModal
        visible={analysisVisible}
        polygon={getPolygon()}
        stationInfo={selStop}
        onCancel={() => setAnalysisVisible(false)}
      ></DiagnosisModal>
      {/* </Spin> */}
    </div>
  )
}

export default Map
