import { useEffect, useState, useRef } from 'react'
import { Select } from 'antd'

import { useAppSelector, useUserResources, useTimingRequest } from '@/utils/hooks'
import { roleTypeMap } from '@/utils/consts'
import ContentBox from '../contentBox'
import request from '@/utils/request'

import throttle from 'lodash/throttle'
import * as echarts from 'echarts'
type EChartsOption = echarts.EChartsOption

const PassengerTrend = () => {
  const [app] = useAppSelector(state => [state.app])
  const [period, setPeriod] = useState<string>('1')
  const containerRef = useRef<HTMLDivElement>(null)
  const containerTwoRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<any>(null)
  const chartTwoRef = useRef<any>(null)
  const [dataCenterRs] = useUserResources(resources => [resources.dataCenter])

  useTimingRequest(
    () => {
      if (app.currentEntity.entityId) {
        subwayPassengerTrend()
      }
    },
    [app.currentEntity.entityId],
    10000
  )

  const initChart = (item: any) => {
    item.value.current = echarts.init(item.key.current as any)
    window.addEventListener(
      'resize',
      throttle(function () {
        item.value.current?.resize()
      }, 100)
    )
  }

  const setOption = (data: any, item: any) => {
    const seriesData: any[] = []
    const legendData: any[] = []
    let xData: any[] = []
    data.series?.forEach((item: any) => {
      legendData.push({
        name: item?.name,
        icon: 'roundRect',
        itemStyle: {
          color: item?.color,
        },
      })
      xData = Object.keys(item.data)
      seriesData.push({
        color: item.color,
        data: Object.values(item.data),
        name: item.name,
        type: 'line',
        animationDuration: 3000,
        showSymbol: false,
      })
    })
    const option: EChartsOption = {
      title: {
        top: '15',
        left: 'left',
        text: data.title,
        textStyle: {
          fontSize: '16px',
          color: 'rgba(255, 255, 255, 0.9)',
        },
        subtext: '单位(人)',
        subtextStyle: {
          color: '#DEDEDE',
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(66, 75, 92, 0.2)',
          },
        },
        backgroundColor: '#424B5C',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
        formatter: function (params: any) {
          let str = ''
          params &&
            params.forEach((item: any) => {
              str +=
                '<div>' +
                '<span style="display:inline-block;margin-right:4px;width:3px;height:8px;border-radius:1px;background-color:' +
                item.color +
                ';"></span>' +
                ' ' +
                '<span style="color: #BCCBE0;">' +
                item.seriesName +
                '</span>' +
                '<span style="display:inline-block;margin-right:20px;"></span>' +
                '<span style="float:right;font-family: AvenirLight;">' +
                item.value +
                '</span>' +
                '</div>'
            })
          return (
            '<div>' +
            '<span style="display:block;margin-bottom:10px;font-size:14px;">' +
            params[0].name +
            '</span>' +
            str +
            '</div>'
          )
        },
      },
      legend: {
        itemWidth: 8,
        itemHeight: 2,
        bottom: 0,
        textStyle: { color: '#BCCBE0' },
        data: legendData,
      },
      xAxis: {
        axisLabel: {
          color: '#DEDEDE',
        },
        data: xData,
      },
      yAxis: [
        {
          axisLabel: {
            color: '#DEDEDE',
            formatter: function (value: any) {
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + 'W'
              } else if (value >= 10000000) {
                value = value / 10000000 + 'KW'
              }
              return value
            },
          },
          splitNumber: 4,
          splitLine: {
            lineStyle: {
              color: '#748399',
              width: 0.4,
            },
          },
        },
      ],
      grid: {
        top: 75,
        bottom: 30,
        left: 1,
        right: 18,
        containLabel: true,
      },
      series: seriesData,
    }
    item.current?.setOption(option)
    setTimeout(() => {
      item.current?.resize()
    }, 3000)
  }

  const subwayPassengerTrend = () => {
    const subwayCenterPassengerTrend = require('_root/mock/subwayCenter/subwayCenterPassengerTrend.json')
    const arr = [
      {
        key: containerRef,
        value: chartRef,
      },
      {
        key: containerTwoRef,
        value: chartTwoRef,
      },
    ]
    arr.forEach((item: any, index: number) => {
      if (item.key.current) {
        item.key.current!.style.height = '301px'
      }
      item.value.current = echarts.dispose(item.key.current as any)
      !item.value.current && initChart(item)
      setOption(
        index === 0
          ? subwayCenterPassengerTrend.data?.passengerFlow || []
          : subwayCenterPassengerTrend.data?.rideCount || [],
        item.value
      )
    })
  }

  const handleChange = (value: string) => {
    setPeriod(value)
    subwayPassengerTrend()
  }

  const renderContent = () => {
    return (
      <>
        <div ref={containerRef} style={{ width: '100%' }}></div>
        <div ref={containerTwoRef} style={{ width: '100%' }}></div>
      </>
    )
  }

  return (
    <ContentBox
      title="地铁站客流趋势"
      style={{ position: 'relative', marginTop: 4, minHeight: 'calc(100vh - 414px)' }}
    >
      <div style={{ position: 'absolute', right: 20, top: 15 }}>
        <Select
          defaultValue="1"
          allowClear={false}
          style={{ width: 96 }}
          className="custom-select subway-custom-select"
          popupClassName="custom-select-dropdown subway-custom-select-dropdown"
          options={[
            { label: '今日', value: '1' },
            { label: '近7日', value: '2' },
            { label: '近30日', value: '3' },
          ]}
          onChange={handleChange}
        ></Select>
      </div>
      {renderContent()}
    </ContentBox>
  )
}

export default PassengerTrend
