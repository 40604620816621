import greenTheme from './green'
import blueTheme from './blue'
import blackTheme from './black'

// 主题色
const themeMap = {
  green: greenTheme,
  blue: blueTheme,
  black: blackTheme,
}

// 地图色
const mapThemeMap = {
  black: {
    css: {
      '--theme-body-bg': '#10131a', // 页面背景色
      '--theme-body-bg-image': `radial-gradient(
        39% 54% at 50% 45%,
        rgba(78, 105, 141, 0.6) 0%,
        rgba(33, 47, 66, 0.4) 54%,
        rgba(33, 47, 66, 0.3) 70%,
        rgba(21, 23, 33, 0.5) 100%
      )`, // 页面背景图
    },
    mapStyleId: 'style 6', // 地图样式id
  },
  grey: {
    css: {
      '--theme-body-bg': '#2D4759',
      '--theme-body-bg-image': `radial-gradient(39% 54% at 50% 45%, #638399 0%, #456073 53%, rgba(45, 71, 89, 0) 100%)`,
    },
    mapStyleId: 'style 7',
  },
  white: {
    css: {
      '--theme-body-bg': '#98A4B3',
      '--theme-body-bg-image': `radial-gradient(39% 54% at 50% 45%, #B7C0CB 0%, #98A4B3 49%, rgba(152, 164, 179, 0) 100%)`,
    },
    mapStyleId: 'style 8',
  },
}

export { themeMap, mapThemeMap }
