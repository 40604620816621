import React, { Fragment, useState } from 'react'
import { Typography } from 'antd'

const { Paragraph } = Typography

const textStyle: any = {
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  whiteSpace: 'normal',
}

interface Props {
  rows?: number
  content: React.ReactNode
}

const ParagraphExpand: React.FC<Props> = props => {
  const { rows = 2, content } = props
  const [fold, setFold] = useState(false)
  const [key, setKey] = useState(0)

  const onExpand = () => {
    setFold(true)
  }

  const onCollapse = (e: any) => {
    e.preventDefault()
    setFold(false)
    setKey(key => key + 1)
  }

  return (
    <Fragment>
      <div key={key}>
        <Paragraph
          ellipsis={{ rows, expandable: true, onExpand: onExpand }}
          style={{ marginBottom: 0, ...textStyle }}
        >
          {content}
        </Paragraph>
      </div>
      {fold && (
        <a href=":;" onClick={onCollapse}>
          收起
        </a>
      )}
    </Fragment>
  )
}

export default ParagraphExpand
