import React, { useState, useEffect, useMemo } from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

import type { MenuProps } from 'antd'

interface Props {
  routes: Routes
}

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  return {
    label,
    key,
    icon,
    children,
    type,
  } as MenuItem
}

const Aside: React.FC<Props> = ({ routes }) => {
  const path = location.hash.replace('#', '')
  const [openKeys, setOpenKeys] = useState<string[]>()
  const [selectedKeys, setSelectedKeys] = useState<string[]>()
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    const urlKey = /[A-z, /]+/.exec(path) || []
    const menuPath = /\/(\w+)\/(\w+)/.exec(path) || []
    setSelectedKeys([urlKey[0]] as string[])
    setOpenKeys([menuPath[0]] as string[])
  }, [path])

  const items = useMemo(() => {
    const mapItems: any = (routes: Routes) => {
      return routes.map(route => {
        if (route.name) {
          return getItem(
            route.routes ? route.name : <Link to={route.path}>{route.name}</Link>,
            route.path,
            route.icon,
            route.routes ? mapItems(route.routes) : undefined
          )
        }
      })
    }
    return mapItems(routes)
  }, [routes])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  return (
    <div className="manage-aside">
      <span onClick={toggleCollapsed} className="manage-aside-collapsed">
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </span>
      <Menu
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        mode="inline"
        inlineCollapsed={collapsed}
        style={{ width: collapsed ? 80 : 200 }}
        onOpenChange={keys => {
          setOpenKeys(keys.slice(-1) as string[])
        }}
        items={items}
      ></Menu>
    </div>
  )
}

export default Aside
