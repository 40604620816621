import { useEffect, useState, useRef } from 'react'
import { Input, message } from 'antd'

import { isLngLatCoordinate } from '@/utils/util'

const Search = Input.Search

const LocationSearch = ({ map }) => {
  const markersRef = useRef(null)
  const geocoderRef = useRef(null)
  const infoWindowListRef = useRef(Array(10))

  useEffect(() => {
    if (map) {
      geocoderRef.current = new TMap.service.Geocoder()
      markersRef.current = new TMap.MultiMarker({
        map: map,
        geometries: [],
      })
    }
    return () => {
      clearInfoWindow()
      clearMarkers()
    }
  }, [map])

  const handleSearch = value => {
    const coordinate = value.trim()

    if (!coordinate) {
      return message.warn('请输入坐标搜索！')
    } else if (!isLngLatCoordinate(coordinate)) {
      return message.error(
        '请输入正确的坐标，坐标格式必须是经度+半角逗号+纬度，例如：116.397617,39.910993'
      )
    }
    onCoordinateSearch(coordinate)
  }

  const clearInfoWindow = () => {
    infoWindowListRef.current.forEach(infoWindow => {
      infoWindow.close()
    })
    infoWindowListRef.current.length = 0
  }

  const clearMarkers = () => {
    markersRef.current.setGeometries([])
  }

  const onCoordinateSearch = coordinate => {
    const list = coordinate.split(',') || []
    const lat = list[1] || 39.910993
    const lng = list[0] || 116.397617
    const centerPoint = new TMap.LatLng(lat, lng)

    clearMarkers()

    markersRef.current.updateGeometries([
      {
        id: '0',
        position: centerPoint,
      },
    ])
    map.setCenter(centerPoint)

    geocoderRef.current.getAddress({ location: centerPoint }).then(res => {
      const infoWindow = new TMap.InfoWindow({
        map: map,
        position: centerPoint,
        content: `<p>地址：${res.result.address}</p>`,
        offset: { x: 0, y: -50 },
      })
      clearInfoWindow()
      infoWindowListRef.current.push(infoWindow)
    })

    markersRef.current.on('click', e => {
      infoWindowListRef.current[Number(e.geometry.id)].open()
    })
  }

  return (
    <Search
      enterButton
      size="default"
      onSearch={handleSearch}
      className="input-item map-search-input"
      placeholder="请输入坐标搜索"
    />
  )
}

export default LocationSearch
