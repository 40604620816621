/**
 * 按围栏id搜索围栏点位
 */
import React, { useState, useEffect } from 'react'
import { Input, message } from 'antd'

import request from '@/utils/request'
import { safeJsonParse } from '@/utils/util'

const Search = Input.Search

const FenceSearch = ({ map }) => {
  const [fences, setFences] = useState([])

  useEffect(() => {
    if (fences.length && map) {
      const layer = renderFences(fences)
      return () => map.remove(layer)
    }
  }, [fences, map])

  const renderFences = fences => {
    const polygons = []
    fences.forEach(fence => {
      const region =
        typeof fence.region === 'string' ? safeJsonParse(fence.region, {}) : fence.region || {}
      const coordinates = region.coordinates || []

      if (coordinates.length > 0) {
        const polygon = drawPolygon(coordinates, fence)
        polygons.push(polygon)
      }
    })
    polygons.length && map.setFitView(polygons)
    return polygons
  }

  const drawPolygon = (path, fence) => {
    const color = fence.color || '#F05B48'
    const polygon = new AMap.Polygon({
      map: map,
      path: path,
      zIndex: fence.zIndex || 10, // 多边形覆盖物的叠加顺序；地图上存在多个多边形覆盖物叠加时，通过该属性使级别较高的多边形覆盖物在上层显示；默认zIndex：10
      extData: fence, // 用户自定义属性，支持任意数据类型
      fillColor: color, // 填充色
      fillOpacity: 0.2, // 填充透明度
      strokeColor: color, // 线颜色
      strokeStyle: 'solid', // 轮廓线样式，实线:solid，虚线:dashed
      strokeWeight: 2, // 线宽
      strokeOpacity: 1, // 线透明度
    })
    return polygon
  }

  const handleSearch = (value = '') => {
    const id = value.trim()
    if (!id) {
      return message.error('请输入围栏ID，再按回车键搜索围栏！')
    }
    if (!/^\d+$/.test(id)) {
      return message.error('输入的围栏ID不合法，围栏ID必须为数字！')
    }
    onFenceSearch(id)
  }

  const onFenceSearch = id => {
    const params = { id }
    request('getLbsGeoFenceById', { params, method: 'get' }).then(res => {
      if (res.code === 0) {
        const jsonData = res.data
        const fences = (jsonData && [jsonData]) || []
        if (fences.length < 1) {
          message.warn('当前搜索的围栏不存在！')
        } else {
          setFences(fences)
        }
      }
    })
  }

  return (
    <Search
      enterButton
      style={{ width: '100%', border: 0 }}
      size="default"
      onSearch={handleSearch}
      placeholder="请输入围栏ID查询围栏"
    />
  )
}

export default FenceSearch
