import { toFixed } from '@/utils/util'
import { parkingAreaMap } from '@/utils/consts'
import { getKeyByVal } from '@/utils/util'

import { parkingIcon, streetZoom } from './consts'

function createInfoWindow(map) {
  const infoWindow = new TMap.InfoWindow({
    map: map,
    zIndex: 1,
    position: new TMap.LatLng(39.984104, 116.307503),
    offset: { x: 1, y: -22 },
    enableCustom: true,
    content: '<div class="info-card"></div>',
  })
  infoWindow.close()
  return infoWindow
}

let infoWindow

window.closeSubwayParkingInfoWindow = function () {
  if (window.reloadContent) {
    window.reloadContent = null
  }
  infoWindow?.close()
}
window.subwayParkingInfoOnmousemove = function (e) {
  e?.stopPropagation()
}

function getContent(fenceInfo, dataCenterResource) {
  const domStr = `
    <div class="bike-content-info info-card fz16" onmousemove="subwayParkingInfoOnmousemove(event)">
      <div class="close" onclick="closeSubwayParkingInfoWindow()">
        <svg style="fill:#aaa;overflow:hidden" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M579.888 512l190.064-190.064a48 48 0 0 0-67.888-67.872L512 444.112 321.936 254.064a48 48 0 1 0-67.872 67.872L444.112 512 254.064 702.064a48 48 0 1 0 67.872 67.872L512 579.888l190.064 190.064a48 48 0 0 0 67.872-67.888L579.888 512z" fill=""></path></svg>
      </div>
      <div class="triangle"></div>
      <div class="startStart mb12">
        <div style="width:100px" class="mtFontLight gray mr10">停车点名称</div><div class="grayFont07">${
          fenceInfo.fencingName
        }
      </div>
      </div>
      <div class="startStart mb12">
        <div style="width:100px" class="mtFontLight gray mr10">停车点ID</div>
        <div class="grayFont07">${fenceInfo.fencingId}</div>
      </div>
      <div class="startStart mb12">
        <div style="width:100px" class="mtFontLight gray mr10">停车点位置</div>
        <div class="grayFont07">${
          toFixed(fenceInfo.position?.lng, 6) + '/' + toFixed(fenceInfo.position?.lat, 6)
        }</div>
      </div>
      <div class="startStart mb12">
        <div style="width:100px" class="mtFontLight gray mr10">停放车辆数</div>
        <div class="grayFont07">${fenceInfo.depositionCount}</div>
      </div>
      <div class="startStart mb12">
        <div style="width:100px" class="mtFontLight gray mr10">停车点容量</div>
        <div class="grayFont07">${fenceInfo.fencingCapacity}</div>
      </div>
      <div class="startStart mb12">
        <div style="width:100px" class="mtFontLight gray mr10">淤积程度</div>
        <div class="grayFont07">${fenceInfo.depositionName}</div>
      </div>
    </div>
  `
  return domStr
}

export const setParkingPointInfo = (window.setParkingPointInfo = function (
  evt,
  map,
  dataCenterResource
) {
  if (!infoWindow) {
    infoWindow = createInfoWindow(map)
  }
  const data = { ...evt.geometry }
  // 停车点显示详情，禁停区不显示
  if (data.fencingId) {
    const position = new TMap.LatLng(evt.geometry.position.lat, evt.geometry.position.lng)
    infoWindow?.setMap(map)
    infoWindow?.open()
    infoWindow?.setPosition(position)
    infoWindow?.setContent(getContent(data, dataCenterResource))
  }
  return () => {
    infoWindow?.setMap(null)
    infoWindow = null
  }
})

const getPolygonStyle = () => {
  return Object.keys(parkingIcon).reduce((prev, next) => {
    prev[next] = new TMap.PolygonStyle({
      color: parkingIcon[next].color, //面填充色
      showBorder: true, //是否显示拔起面的边线
      borderColor: parkingIcon[next].border,
      borderWidth: 2,
      borderDashArray: next.dashed ? [5, 5] : [0, 0],
    })
    return prev
  }, {})
}

const getMarkerStyle = () => {
  return Object.keys(parkingIcon).reduce((prev, next) => {
    prev[next] = new TMap.MarkerStyle({
      width: 32,
      height: 32,
      anchor: { x: 14, y: 18 },
      src: parkingIcon[next].icon,
    })
    return prev
  }, {})
}

const drawParkingAreaLayer = (map, allParkingCoords, dataCenterResource) => {
  const polygons = []
  const markers = []
  let clearInfoWindowFn
  allParkingCoords.forEach((parkingCoords, i) => {
    const exceedStreetZoom = map.getZoom() >= streetZoom
    // const defParkingPath = [
    //   new TMap.LatLng(40.041054, 116.272303),
    //   new TMap.LatLng(40.039419, 116.272721),
    //   new TMap.LatLng(40.039764, 116.274824),
    //   new TMap.LatLng(40.041374, 116.274491),
    // ]
    // const defGeometries = [
    //   {
    //     id: 'polygonTen',
    //     styleId: 'polygon2',
    //     paths: defParkingPath,
    //     properties: {
    //       title: '',
    //     },
    //   },
    // ]
    const polygon = new TMap.MultiPolygon({
      id: 'parking-layer' + i,
      map: map,
      styles: getPolygonStyle(),
      // geometries: !exceedStreetZoom
      //   ? defGeometries
      //   : parkingCoords.map(coordinate => ({
      //       id: 'polygon',
      //       styleId: getKeyByVal(parkingAreaMap, coordinate.areaType),
      //       paths: coordinate.region,
      //       properties: {
      //         title: 'polygon',
      //       },
      //     })),
    })
    if (exceedStreetZoom) {
      const geometries = parkingCoords.map(coordinate => ({
        id: 'polygon',
        styleId: getKeyByVal(parkingAreaMap, coordinate.areaType),
        paths: coordinate.region,
        properties: {
          title: 'polygon',
        },
      }))
      polygon.setGeometries(geometries)
    }
    // 添加marker
    const marker = new TMap.MultiMarker({
      id: 'parking-marker-layer' + i,
      map: map,
      styles: getMarkerStyle(),
      geometries: parkingCoords.map(item => ({
        ...item,
        id: 'parking-marker' + i,
        styleId: getKeyByVal(parkingAreaMap, +item.areaType),
        position: new TMap.LatLng(item.centerPoint[1], item.centerPoint[0]),
        properties: {
          title: 'marker',
        },
      })),
    })
    marker.on('click', function (evt) {
      clearInfoWindowFn = setParkingPointInfo(evt, map, dataCenterResource)
    })
    markers.push(marker)
    polygons.push(polygon)
  })
  return () => {
    let layers = [polygons, markers]
    layers.map(polygonsMarkers => {
      polygonsMarkers.map(layer => {
        layer.setMap(null)
      })
    })
    clearInfoWindowFn?.()
  }
}

export default drawParkingAreaLayer
