import request from '@/utils/request'
import {
  bikeStatusTextMap,
  bikeCardStatusTextMap,
  businessLineMap,
  bikeStatusMap,
  brandCodeTextMap,
} from '@/utils/consts'
import { formatDateShowTime, getKeyByVal } from '@/utils/util'

import { iconMap } from './consts'

// 基于DOMOverlay实现聚合点气泡
function ClusterBubble(options) {
  TMap.DOMOverlay.call(this, options)
}

ClusterBubble.prototype = new TMap.DOMOverlay()

ClusterBubble.prototype.onInit = function (options) {
  this.content = options.content
  this.position = options.position
  this.brandCode = options.brandCode
  this.brandName = options.brandName || ''
}

// 销毁时需要删除监听器
ClusterBubble.prototype.onDestroy = function () {
  this.dom.removeEventListener('click', this.onClick)
  this.removeAllListeners()
}

ClusterBubble.prototype.onClick = function () {
  this.emit('click')
}

// 创建气泡DOM元素
ClusterBubble.prototype.createDOM = function () {
  let dom = document.createElement('div')
  let digits = (this.content + '').length
  dom.classList.add('bike-cluster-bubble')
  dom.classList.add('columnCenter')
  dom.classList.add('digits-' + digits)
  dom.classList.add('bike-cluster-bubble-' + this.brandCode?.toLowerCase())
  dom.innerHTML = `
  <div style="font-size: 12px; line-height: 12px">${this.brandName}</div>
  <div style="font-size: 16px; line-height: 16px; margin-top: 1px">${this.content}</div>
  `
  // 监听点击事件，实现zoomOnClick
  this.onClick = this.onClick.bind(this)
  dom.addEventListener('click', this.onClick)
  return dom
}

ClusterBubble.prototype.updateDOM = function () {
  if (!this.map) {
    return
  }
  // 经纬度坐标转容器像素坐标
  let pixel = this.map.projectToContainer(this.position)

  // 使文本框中心点对齐经纬度坐标点
  let left = pixel.getX() - this.dom.clientWidth / 2 + 'px'
  let top = pixel.getY() - this.dom.clientHeight / 2 + 'px'
  this.dom.style.transform = `translate(${left}, ${top})`

  this.emit('dom_updated')
}

let infoWindow

window.closeSubwayInfoWindow = function () {
  infoWindow && infoWindow.close()
}
window.subwayInfoOnmousemove = function (e) {
  e?.stopPropagation && e.stopPropagation()
}

function getInfoWindowContent(geometry, dataCenterRs) {
  return `
    <div class="mtFontLight relative fz16 grayFont07 info-card" id="infoTip" onmousemove="subwayInfoOnmousemove(event)">
      <div class="mb12" style="display: ${geometry.violationInfo ? '' : 'none'}">
        违规告警 ${geometry.violationInfo}
      </div>
      <div class="mb12" style="display: ${dataCenterRs.showBikeId ? '' : ''}">
        车辆编号 ${geometry.bikeId || '-'}
      </div>
      <div class="mb12" style="display: ${dataCenterRs.showBikeLicenseCode ? '' : 'none'}">
        车辆牌照 ${geometry.licenseCode || '-'}
      </div>
      <div class="mb12" style="display: ${dataCenterRs.showBikeLicenseCode ? 'none' : 'none'}">
        牌照状态 ${bikeCardStatusTextMap[geometry.cardStatus] || ''}
      </div>
      <div class="mb12" style="display: ${dataCenterRs.showBikeFrameId ? '' : 'none'}">
        车架号 ${geometry.frameId || '-'}
      </div>
      <div style="width: 15px; height: 15px; position: absolute; right: 3px; top: 2px; cursor: pointer;" onclick="closeSubwayInfoWindow()">
        <svg style="fill: #aaa;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path d="M579.888 512l190.064-190.064a48 48 0 0 0-67.888-67.872L512 444.112 321.936 254.064a48 48 0 1 0-67.872 67.872L444.112 512 254.064 702.064a48 48 0 1 0 67.872 67.872L512 579.888l190.064 190.064a48 48 0 0 0 67.872-67.888L579.888 512z" fill=""></path>
        </svg>
      </div>
      <div class="triangle"></div>
    </div>
  `
  // return (
  //   '<div class="mtFontLight relative fz16 grayFont07 info-card" id="infoTip" onmousemove="subwayInfoOnmousemove(event)">' +
  //   '<div class="mb12" style="display: ' +
  //   (geometry.violationInfo ? '' : 'none') +
  //   '">违规告警 ' +
  //   geometry.violationInfo +
  //   '</div>' +
  //   '<div class="mb12" style="display: ' +
  //   (dataCenterRs.showBikeId ? '' : '') +
  //   '">车辆编号 ' +
  //   (geometry.bikeId || '-') +
  //   '</div>' +
  //   '<div class="mb12" style="display: ' +
  //   (dataCenterRs.showBikeLicenseCode ? '' : 'none') +
  //   '">车辆牌照 ' +
  //   (geometry.licenseCode || '-') +
  //   '</div>' +
  //   '<div class="mb12" style="display: ' +
  //   (dataCenterRs.showBikeLicenseCode ? 'none' : 'none') +
  //   '">牌照状态 ' +
  //   (bikeCardStatusTextMap[geometry.cardStatus] || '') +
  //   '</div>' +
  //   '<div style="display: ' +
  //   (dataCenterRs.showBikeFrameId ? '' : 'none') +
  //   '">车架号 ' +
  //   (geometry.frameId || '-') +
  //   '</div>' +
  //   '<div style="width: 15px; height: 15px; position: absolute; right: 3px; top: 2px; cursor: pointer;" onclick="closeSubwayInfoWindow()"><svg style="fill: #aaa;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
  //   '<path d="M579.888 512l190.064-190.064a48 48 0 0 0-67.888-67.872L512 444.112 321.936 254.064a48 48 0 1 0-67.872 67.872L444.112 512 254.064 702.064a48 48 0 1 0 67.872 67.872L512 579.888l190.064 190.064a48 48 0 0 0 67.872-67.888L579.888 512z" fill=""></path>' +
  //   '</svg></div>' +
  //   '<div class="triangle"></div>' +
  //   '</div>'
  // )
}

function getBikeInfoContent(bikeInfo, position) {
  // const bikeInfoItem = [
  //   { label: 'bike ID', value: bikeInfo.bikeId },
  //   { label: '状态', value: bikeStatusTextMap[bikeInfo.bikeStatus] },
  //   { label: '经度', value: (bikeInfo.bikeReportLngLat || [])[0] },
  //   { label: '纬度', value: (bikeInfo.bikeReportLngLat || [])[1] },
  //   { label: '上报时间', value: formatDateShowTime(bikeInfo.locationTime) },
  // ]
  // const lockInfoItem = [
  //   { label: '最近开锁时间', value: formatDateShowTime(bikeInfo.unlockTime) },
  //   { label: '最近开锁位置', value: bikeInfo.unlockLongitude + '/' + bikeInfo.unlockLatitude },
  //   { label: '最近关锁时间', value: formatDateShowTime(bikeInfo.lockTime) },
  //   { label: '最近关锁位置', value: bikeInfo.lockLongitude + '/' + bikeInfo.lockLatitude },
  // ]
  // const dom = (
  //   <div className="bike-content-info">
  //     <div className="startStart">
  //       <div>
  //         <div className="fz20 grayFont mb20">车辆信息</div>
  //         {bikeInfoItem.map((item, idx) => (
  //           <div key={idx} className="startStart mb12">
  //             <div style={{ width: '72px' }} className="fz18 mtFontLight gray mr10">
  //               {item.label}
  //             </div>
  //             <div className="fz18 grayFont07">{item.value}</div>
  //           </div>
  //         ))}
  //       </div>
  //       <div className="ml20">
  //         <div className="fz20 grayFont mb20">车锁信息</div>
  //         {lockInfoItem.map((item, idx) => (
  //           <div key={idx} className="startStart mb12">
  //             <div style={{ width: '108px' }} className="fz18 mtFontLight gray mr10">
  //               {item.label}
  //             </div>
  //             <div className="fz18 grayFont07">{item.value}</div>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //     <div
  //       style={{ color: '#4DBEFF' }}
  //       className="centerCenter mt20 pointer fz14"
  //       onClick={() => setBikeInfoContent({ geometry: { bikeId: bikeInfo.bikeId, position } })}
  //     >
  //       <ReloadOutlined />
  //       <span className="ml5">刷新信息</span>
  //     </div>
  //   </div>
  // )
  // console.log(ReactDOMServer.renderToString(dom), 99999)
  const geometry = JSON.stringify({ geometry: { bikeId: bikeInfo.bikeId, position } })
  const domStr =
    '<div class="bike-content-info" onmousemove="subwayInfoOnmousemove(event)"><div class="startStart"><div><div class="fz20 grayFont mb20">车辆信息</div><div class="startStart mb12"><div style="width:72px" class="fz18 mtFontLight gray mr10">bike ID</div><div class="fz18 grayFont07">' +
    bikeInfo.bikeId +
    '</div></div><div class="startStart mb12"><div style="width:72px" class="fz18 mtFontLight gray mr10">状态</div><div class="fz18 grayFont07">' +
    (bikeStatusTextMap[bikeInfo.bikeStatus] || '') +
    '</div></div><div class="startStart mb12"><div style="width:72px" class="fz18 mtFontLight gray mr10">经度</div><div class="fz18 grayFont07">' +
    (bikeInfo.bikeReportLngLat || [])[0] +
    '</div></div><div class="startStart mb12"><div style="width:72px" class="fz18 mtFontLight gray mr10">纬度</div><div class="fz18 grayFont07">' +
    (bikeInfo.bikeReportLngLat || [])[1] +
    '</div></div><div class="startStart mb12"><div style="width:72px" class="fz18 mtFontLight gray mr10">上报时间</div><div class="fz18 grayFont07">' +
    formatDateShowTime(bikeInfo.locationTime) +
    '</div></div></div><div class="ml20"><div class="fz20 grayFont mb20">车锁信息</div><div class="startStart mb12"><div style="width:108px" class="fz18 mtFontLight gray mr10">最近开锁时间</div><div class="fz18 grayFont07">' +
    formatDateShowTime(bikeInfo.unlockTime) +
    '</div></div><div class="startStart mb12"><div style="width:108px" class="fz18 mtFontLight gray mr10">最近开锁位置</div><div class="fz18 grayFont07">' +
    bikeInfo.unlockLongitude +
    '/' +
    bikeInfo.unlockLatitude +
    '</div></div><div class="startStart mb12"><div style="width:108px" class="fz18 mtFontLight gray mr10">最近关锁时间</div><div class="fz18 grayFont07">' +
    formatDateShowTime(bikeInfo.lockTime) +
    '</div></div><div class="startStart mb12"><div style="width:108px" class="fz18 mtFontLight gray mr10">最近关锁位置</div><div class="fz18 grayFont07">' +
    bikeInfo.lockLongitude +
    '/' +
    bikeInfo.lockLatitude +
    '</div></div></div></div><div style="color:#4DBEFF" class="centerCenter mt10 pointer fz14" onclick=setBikeInfoContent(' +
    geometry +
    ')><span id="infoReloadIcon" role="img" aria-label="reload" class="anticon anticon-reload"><svg viewBox="64 64 896 896" focusable="false" data-icon="reload" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 00-10.1 4.8c-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 01655.9 829c-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 01279 755.2a342.16 342.16 0 01-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 01109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 003 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z"></path></svg></span><span id="refreshText" class="ml5">刷新信息</span></div></div>'
  // return ReactDOMServer.renderToString(dom)
  return domStr
}

let lastBikeId

const setBikeInfoContent = (window.setBikeInfoContent = function (evt) {
  const data = { bikeId: evt.geometry.bikeId }
  const position = new TMap.LatLng(evt.geometry.position.lat, evt.geometry.position.lng)
  const refreshDom = document.getElementById('refreshText')
  const infoReloadIcon = document.getElementById('infoReloadIcon')
  if (refreshDom && infoReloadIcon && lastBikeId === data.bikeId) {
    infoReloadIcon.classList.add('reloadRotate')
    refreshDom.innerHTML = '刷新中...'
  }
  request('getBikeInfoByBikeId', { data })
    .then(res => {
      const { data, code } = res
      if (code === 0 && data) {
        infoWindow.open()
        infoWindow.setPosition(position)
        infoWindow.setContent(getBikeInfoContent(data, position))
      }
    })
    .finally(() => {
      if (refreshDom && infoReloadIcon && lastBikeId === data.bikeId) {
        infoReloadIcon.classList.remove('reloadRotate')
        refreshDom.innerHTML = '刷新信息'
      }
      lastBikeId = data.bikeId
    })
})

function createInfoWindow(map) {
  const infoWindow = new TMap.InfoWindow({
    map: map,
    zIndex: 1,
    position: new TMap.LatLng(39.984104, 116.307503),
    offset: { x: 0, y: -22 },
    enableCustom: true,
    content: '<div class="info-card"></div>',
  })
  infoWindow.close()
  return infoWindow
}

function getStyleId(bizType, bikeStatus, brandCode) {
  bizType = bizType || 2
  bikeStatus = bikeStatus || 1
  brandCode = brandCode || 'MT'
  const brand = brandCode.toLowerCase()
  const biz = getKeyByVal(businessLineMap, bizType)
  const status = getKeyByVal(bikeStatusMap, bikeStatus)
  return `${brand}_${biz}_${status}`
  // return iconMap[`${brand}_${biz}_${status}`].icon
}

function getMarkerStyles() {
  return Object.keys(iconMap).reduce((prev, next) => {
    prev[next] = new TMap.MarkerStyle({
      width: 28,
      height: 28,
      anchor: {
        x: 14,
        y: 18,
      },
      src: iconMap[next].icon,
    })
    return prev
  }, {})
}

function createClusterBubble(map, geometries, infoWindow, dataCenterRs) {
  let markerCluster = null
  let marker = null
  let markerGeometries = []
  let clusterBubbleList = []

  // 重新创建点聚合
  markerCluster = new TMap.MarkerCluster({
    id: 'cluster' + Math.random(),
    map: map,
    enableDefaultStyle: false,
    minimumClusterSize: 3,
    geometries: geometries,
    zoomOnClick: true,
    gridSize: 120,
    averageCenter: false,
    maxZoom: 15,
  })

  // 监听聚合簇变化
  markerCluster.on('cluster_changed', function (e) {
    // 销毁旧聚合簇生成的覆盖物
    if (clusterBubbleList.length) {
      clusterBubbleList.forEach(function (item) {
        item.setMap(null)
        item.destroy()
      })
      clusterBubbleList = []
    }
    markerGeometries = []

    // 根据新的聚合簇数组生成新的覆盖物和点标记图层
    let clusters = markerCluster ? markerCluster.getClusters() : []
    clusters.forEach(function (item) {
      if (item.geometries.length > 1) {
        let clusterBubble = new ClusterBubble({
          map,
          position: item.center,
          content: item.geometries.length,
          brandCode: item.geometries[0].brandCode,
          brandName: brandCodeTextMap[item.geometries[0].brandCode],
        })
        clusterBubble.on('click', () => {
          map.fitBounds(item.bounds)
        })
        clusterBubbleList.push(clusterBubble)
      } else {
        const geometry = item.geometries[0]
        markerGeometries.push({
          ...geometry,
          position: item.center,
          bikeId: item.geometries[0].bikeId,
          styleId: getStyleId(geometry.bikeType, geometry.bikeStatus, geometry.brandCode), //指定样式id
        })
      }
    })

    if (marker) {
      // 已创建过点标记图层，直接更新数据
      marker.setGeometries(markerGeometries)
    } else {
      // 创建点标记图层
      marker = new TMap.MultiMarker({
        map,
        styles: getMarkerStyles(),
        geometries: markerGeometries,
      })
      if (marker.geometries[0]?.useIot) {
        marker.on('click', function (evt) {
          setBikeInfoContent(evt)
        })
      } else if (dataCenterRs.bikeOperationShowInfo) {
        marker.on('click', function (evt) {
          infoWindow.open()
          infoWindow.setPosition(evt.geometry.position)
          infoWindow.setContent(getInfoWindowContent(evt.geometry, dataCenterRs))
        })
      }
    }
  })

  // 清除历史图层
  return () => {
    if (clusterBubbleList.length) {
      clusterBubbleList.forEach(function (item) {
        item.setMap(null)
        item.destroy()
      })
      clusterBubbleList = []
    }
    if (markerCluster) {
      markerCluster.setGeometries([])
      markerCluster.setMap(null)
      markerCluster = null
    }
    if (marker) {
      marker.setGeometries([])
      marker.setMap(null)
      marker = null
    }
    if (infoWindow) {
      infoWindow.setMap(null)
      infoWindow = null
    }
  }
}

let allClusterLayers = []

const clearClusterLayer = () => {
  if (allClusterLayers.length) {
    allClusterLayers.forEach(fn => fn())
  }
}

function init(map, positionsArr, dataCenterRs) {
  infoWindow = createInfoWindow(map)
  // if (!infoWindow) {
  // }
  allClusterLayers = positionsArr.map((positions, idx) => {
    const geometries = positions.map(position => {
      return {
        ...position,
        position: new TMap.LatLng(position.lat, position.lng),
        bikeId: position.bikeId,
      }
    })
    return createClusterBubble(map, geometries, infoWindow, dataCenterRs)
  })
  return clearClusterLayer
  // const geometries = positionsArr.map(position => {
  //   return {
  //     ...position,
  //     position: new TMap.LatLng(position.lat, position.lng),
  //     bikeId: position.bikeId,
  //   }
  // })
  // return CreateClusterBubble(map, geometries, infoWindow, dataCenterRs)
}

export default init
