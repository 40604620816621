import React from 'react'
import { reportAegis } from '@/utils/util'

interface Props {
  children?: any
}

interface State {
  hasError: boolean
  error?: any
  errorInfo?: any
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error, errorInfo })
    reportAegis({ msg: error, ext1: errorInfo })
  }

  render() {
    if (this.state.hasError) {
      const { error } = this.state
      return process.env.NODE_ENV === 'development' ? (
        <div>
          <h1>Something went wrong.</h1>
          <p>{error && error.message}</p>
          <pre>{error && error.stack}</pre>
        </div>
      ) : (
        this.props.children
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
