import { memo, useEffect } from 'react'

import { useAppSelector } from '@/utils/hooks'
import { bikeZoom, cityAreaLevel } from '@/pages/dataCenter/center/map/consts'

const extendBounds = (paths, latlngBounds) => {
  if (paths[0]?.lat && paths[0]?.lng) {
    paths.forEach(point => {
      latlngBounds.extend(point)
    })
  } else {
    paths.forEach(path => {
      extendBounds(path, latlngBounds)
    })
  }
}

const RegionLayer = ({ areaInfo, map }) => {
  const [app] = useAppSelector(state => [state.app])

  useEffect(() => {
    const { region } = areaInfo
    if (map && region?.length) {
      return initRegionLayer()
    }
  }, [map, areaInfo])

  const initRegionLayer = () => {
    const { region } = areaInfo
    const laryer = new TMap.MultiPolygon({
      id: 'area-layer',
      map: map,
      styles: {
        polygon: new TMap.PolygonStyle({
          color: 'rgba(22,112,247,0.06)',
          showBorder: true,
          borderColor: 'rgba(22,112,247,1)',
          borderWidth: 2,
          borderDashArray: [3, 3],
        }),
      },
      geometries: [
        {
          id: 'polygon',
          styleId: 'polygon',
          paths: region,
          properties: {
            title: 'polygon',
          },
        },
      ],
    })

    const latlngBounds = new TMap.LatLngBounds()

    extendBounds(region, latlngBounds)

    map.fitBounds(latlngBounds, {
      maxZoom: cityAreaLevel.includes(app.currentArea?.level) ? bikeZoom - 0.01 : undefined,
    })

    return () => {
      laryer.setMap(null)
    }
  }
  return null
}

export default memo(RegionLayer)
