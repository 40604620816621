import { useMemo, useState } from 'react'
import { Image, Carousel } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { useAppSelector, useTimingRequest, useUserResources } from '@/utils/hooks'
import ContentBox from '../contentBox'
import request from '@/utils/request'

import { ColorMap, TaskOrder } from './types'

const colorMap: Record<number, string> = {
  1: '#EB5038', // 待处理
  2: '#E0812D', //处理中
  3: '#47C3CF', // 已处理
  4: '#8FA3BF', // 已作废
  5: '#47C3CF', // 审核通过
}

const displayNumber = 6
const PatrolResult = () => {
  const [manageCenterResource] = useUserResources(resources => [resources.manageCenter])
  const [app] = useAppSelector(state => [state.app])
  const [scheduleData, setScheduleData] = useState<TaskOrder[]>([])

  useTimingRequest(() => {
    if (app.currentArea.adCode) {
      queryScheduleDetail()
    }
  }, [app.currentFence.fenceId, app.currentFence.selectFenceId, app.currentArea.adCode])

  const queryScheduleDetail = () => {
    const data = {
      entityId: app.currentEntity.entityId,
      fencingId: app.currentFence.selectFenceId || app.currentFence.fenceId,
      adCode: app.currentArea.adCode,
    }
    request('patrolCenterCostTimeQueryList', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setScheduleData(res.data)
      }
    })
  }
  const carouselDiv = (content: TaskOrder[]) => {
    return (
      <div className="schedule-details">
        {content &&
          content.map((item: TaskOrder, index: number) => {
            return (
              <div key={index} className="schedule-details-content">
                {item.photos ? (
                  <Image className="schedule-details-img" src={item.photos} />
                ) : (
                  <div className="schedule-details-none-img">暂无图片</div>
                )}
                <div className="schedule-details-text">
                  <span style={{ color: colorMap[item.status] }}>{item.statusName}</span>
                  <span style={{ marginLeft: '8px' }}>{item.brandName}</span>
                  <span className="schedule-details-text-separate"></span>
                  <span>{item.address}</span>
                </div>
              </div>
            )
          })}
      </div>
    )
  }
  const carouselData = useMemo(() => {
    const result = []
    for (let i = 0; i < scheduleData.length; i += displayNumber) {
      result.push(scheduleData.slice(i, i + displayNumber))
    }
    return result
  }, [scheduleData])

  return (
    <ContentBox title="工单进度明细" style={{ marginTop: '4px' }}>
      <div>
        {manageCenterResource.taskCenterList && (
          <Link to={'/manageCenter/taskCenter'} className="view-detail">
            查看详情 <RightOutlined style={{ fontSize: 12 }} />
          </Link>
        )}
        {carouselData.length ? (
          <div className="schedule-parent">
            <Carousel dotPosition="left" autoplay dots={false}>
              {carouselData.map((item: TaskOrder[], index: number) => {
                return (
                  <div style={{ height: '371px' }} key={Date.now() - 1}>
                    {carouselDiv(item)}
                  </div>
                )
              })}
            </Carousel>
          </div>
        ) : (
          <div className="schedule-details-empty">暂无数据</div>
        )}
      </div>
    </ContentBox>
  )
}

export default PatrolResult
