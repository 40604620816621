import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Skeleton, Card } from 'antd'

import ManageAside from './ManageAside'
import { IBreadcrumb } from '@/components'
import { getFirstRoutePath } from '@/utils/util'

interface Props {
  route: Routes[number]
  routes: Routes
}

const ManageLayout: React.FC<Props> = ({ route, routes, ...rest }) => {
  const firstPath = getFirstRoutePath(routes)
  const redirectPath = !firstPath || firstPath === '*' ? '/notFound' : firstPath
  const fromPath = /(\/[A-z]+)/.exec(location.hash)?.[0]

  const renderRoutes = (routes: Routes): React.ReactNode => {
    if (!routes?.length) {
      return null
    }
    return routes.map(route =>
      route.component ? (
        <Route exact={true} key={route.path} path={route.path}>
          <route.component {...rest}>{route.routes && renderRoutes(route.routes)}</route.component>
        </Route>
      ) : (
        route.routes && renderRoutes(route.routes)
      )
    )
  }

  return (
    <div className="manage-layout">
      <ManageAside routes={routes}></ManageAside>
      <div className="manage-layout-main">
        <IBreadcrumb route={route}></IBreadcrumb>
        <Suspense fallback={<Skeleton active></Skeleton>}>
          <Card className="manage-layout-main-card">
            <Switch>
              <Redirect exact from={fromPath} to={redirectPath} />
              {renderRoutes(routes)}
            </Switch>
          </Card>
        </Suspense>
      </div>
    </div>
  )
}

export default ManageLayout
