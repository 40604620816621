import { forwardRef, useImperativeHandle, cloneElement } from 'react'
import { Button, Col, Form, Row } from 'antd'
import merge from 'lodash/merge'

import { IFormItem } from '@/components'

import type { FormItemConfig } from '@/components/formItem/type'
import type { FormInstance, FormProps } from 'antd/es/form'

import './index.less'

interface Props extends FormProps {
  form: FormInstance
  formItemsConfig: FormItemConfig[]
  divide?: 6 | 8
  loading?: boolean
  onClear?: () => void
  handler?: React.ReactElement | (() => React.ReactElement)
}

interface RefProps {
  getValue: () => any
}

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
}

const { renderFormItemComponents } = IFormItem

const defaultRangePickerSpan = 9

const IForm = forwardRef<RefProps, Props>((props, ref) => {
  const {
    onFinish,
    formItemsConfig,
    onClear,
    form,
    layout = 'horizontal',
    divide = 6,
    loading,
    handler,
    ...rest
  } = props
  useImperativeHandle(ref, () => ({
    getValue: () => {
      return form.getFieldsValue()
    },
  }))

  const handleClear = () => {
    onClear?.()
    form.resetFields()
  }

  return layout === 'horizontal' ? (
    <Form className="search-form" onFinish={onFinish} form={form} {...rest}>
      <Row gutter={24} style={{ width: '100%' }}>
        {renderFormItemComponents(formItemsConfig, (component, idx) => {
          const config = formItemsConfig[idx]
          const isRangePicker = config?.type === 'dateRangePicker'
          const rangePickerSpan = isRangePicker ? defaultRangePickerSpan : undefined
          return (
            <Col key={idx} span={config?.span ?? rangePickerSpan ?? divide}>
              {isRangePicker
                ? cloneElement(component, {
                    childProps: merge(
                      { style: { width: '100%' } },
                      component.props.childProps ?? {}
                    ),
                  })
                : component}
            </Col>
          )
        })}
        <Col>
          <Form.Item>
            {typeof handler === 'function'
              ? handler()
              : handler ?? (
                  <>
                    <Button className="mr12" htmlType="submit" type="primary" loading={loading}>
                      查询
                    </Button>
                    <Button onClick={handleClear}>重置</Button>
                  </>
                )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : (
    <Form
      {...formItemLayout}
      className="vertical-form"
      form={form}
      autoComplete="off"
      preserve={false}
      {...rest}
    >
      {renderFormItemComponents(formItemsConfig)}
    </Form>
  )
})

IForm.displayName = 'MyForm'

export default IForm
