import React, { useState, useReducer, useEffect, useRef } from 'react'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
import { Input, Select, Form, Tooltip, Button } from 'antd'
import pick from 'lodash/pick'

import { parkingAreaMap, parkingAreaTextMap, orderlyTypeMap } from '@/utils/consts'
import { useUserResources, useAppSelector } from '@/utils/hooks'
import { trimFormInput } from '@/utils/util'
import request from '@/utils/request'
import { SearchSelect } from '@/components'
import {
  parkingIcon,
  layerControlIcon,
  patrolAreaOrderlyIcon,
} from '@/pages/dataCenter/center/map/consts'

import type { SearchSelectRef } from '@/components/select/SearchSelect'

interface Props {
  map: TMap.Map
  onSearch: (param: SearchParam) => void
  onChange: (state: LayerParam) => void
}

export interface SearchParam {
  type: ValueOf<typeof searchType>
  value: string
  bikeId?: string
  bikePlate?: string
}
export interface LayerParam {
  orderlyResult?: OrderlyType
  areaTypeList: ParkingAreaType[]
}

export const searchType = {
  bikeId: 1,
  bikeNum: 2,
  parkingId: 3,
  parkingName: 4,
  operatorName: 5,
  operatorPhone: 6,
  operationCarId: 7,
}

const inputSearchTypes = [
  searchType.operatorName,
  searchType.operatorPhone,
  searchType.operationCarId,
]

const initialState: LayerParam = {
  orderlyResult: undefined,
  areaTypeList: [],
}

const reducer = (state: LayerParam, action: { type: string; payload: Partial<LayerParam> }) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const initialFold = {
  // searchFold: true,
  legendFold: true,
  layerFold: true,
}

const accumWidth = (data: any[], curIdx: number) => {
  return data.reduce((prev, next, idx) => {
    if (idx <= curIdx) {
      prev += next.width
    }
    return prev
  }, 0)
}

const LayerControl: React.FC<Props> = ({ onChange, onSearch }) => {
  const [form] = Form.useForm()
  const [app] = useAppSelector(state => [state.app])
  const [dataCenterRs] = useUserResources(resources => [resources.dataCenter])
  const [state, dispatch] = useReducer(reducer, initialState)

  const searchSelectRef = useRef<SearchSelectRef>(null)

  const [controlFold, setControlFold] = useState(initialFold)
  const [brandList, setBrandList] = useState<any>([])
  const [searchFold, setSearchFold] = useState(true)

  const { legendFold, layerFold } = controlFold
  const { orderlyResult, areaTypeList } = state

  useEffect(() => {
    queryBrandList()
  }, [app.currentEntity.entityId])

  useEffect(() => {
    onChange(state)
  }, [state])

  const queryBrandList = () => {
    const data = { entityId: app.currentEntity.entityId }
    request('companyBrandListByUserType', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setBrandList([{ brandCode: '0', brandName: '全部' }].concat(res.data))
      }
    })
  }

  const handleControlClick = (name: keyof typeof controlFold) => {
    setSearchFold(true)
    setControlFold({ ...initialFold, [name]: !controlFold[name] })
  }

  const handleClick = (payload: Partial<LayerParam>) => {
    dispatch({ type: 'update', payload })
  }

  const renderControlDesk = () => {
    const orderlyOpts = [
      { label: '秩序散乱', value: orderlyTypeMap.unorderly },
      { label: '全部', value: undefined },
    ]
    const data = [
      {
        title: '秩序重点区域',
        show: true,
        btns: orderlyOpts.map(opt => ({
          name: opt.label,
          value: opt.value,
          className: opt.value == orderlyResult ? 'manage-desk-btn-highlight' : '',
          onClick: () => handleClick({ orderlyResult: opt.value }),
        })),
      },
      {
        title: '区域点位',
        show: !!dataCenterRs.parkingAreaShow,
        btns: Object.values(pick(parkingAreaMap, 'parking')).map(val => ({
          name: parkingAreaTextMap[val],
          value: val,
          show: true,
          className: areaTypeList?.includes(val) ? 'manage-desk-btn-highlight' : '',
          onClick: () =>
            handleClick({
              areaTypeList: areaTypeList?.includes(val)
                ? areaTypeList.filter((item: any) => item != val)
                : [...areaTypeList, val],
            }),
        })),
      },
    ]
    return data.map((item, i) =>
      item.show ? (
        <div key={i} className="startStart" style={{ marginTop: i === 0 ? 0 : 12 }}>
          <div className="mr12" style={{ width: 56, marginTop: 6 }}>
            {item.title}
          </div>
          <div className="startCenter" style={{ width: 306, flexWrap: 'wrap' }}>
            {item.btns.map((btn: any, idx: number) =>
              !btn.validate || (btn.validate && btn.show) ? (
                <div
                  key={i + '-' + idx}
                  className={`manage-desk-btn centerCenter pointer ${btn.className}`}
                  onClick={btn.onClick}
                  style={{ marginRight: (idx + 1) % 3 === 0 ? 0 : 12, marginTop: idx > 2 ? 12 : 0 }}
                >
                  {btn.name?.length > 5 ? (
                    <Tooltip title={btn.name}>{btn.name.slice(0, 5) + '...'}</Tooltip>
                  ) : (
                    btn.name
                  )}
                </div>
              ) : null
            )}
          </div>
        </div>
      ) : null
    )
  }

  const handleLayerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    handleControlClick('layerFold')
  }

  const handleBikeSearch = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    // handleControlClick('searchFold')
    setSearchFold(false)
  }

  const handleLegendClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    handleControlClick('legendFold')
  }

  const renderLegendDesk = () => {
    const icons = [
      { icon: patrolAreaOrderlyIcon.orderly.icon, label: '重点区域(秩序整齐)' },
      { icon: patrolAreaOrderlyIcon.unorderly.icon, label: '重点区域(秩序散乱)' },
      { icon: parkingIcon.parking.icon, label: '停车点' },
    ]
    return (
      <div className="relative">
        {icons.map((item: any, idx) => (
          <div key={idx} style={{ marginBottom: idx === icons.length - 1 ? 0 : 8 }}>
            <span style={{ marginRight: 4 }}>
              <img style={{ width: 28, height: 28 }} src={item.icon}></img>
            </span>
            <span style={{ color: '#BCCBE0' }}>{item.label}</span>
          </div>
        ))}
      </div>
    )
  }

  const searchOptions = [
    { label: '车辆ID', value: searchType.bikeId, rs: true },
    {
      label: '车牌号',
      value: searchType.bikeNum,
      rs: dataCenterRs.showBikeLicenseCode,
    },
    {
      label: '停车点ID',
      value: searchType.parkingId,
      rs: dataCenterRs.parkingAreaShow,
    },
    {
      label: '停车点名称',
      value: searchType.parkingName,
      rs: dataCenterRs.parkingAreaShow,
    },
    {
      label: '运维人员',
      value: searchType.operatorName,
      rs: dataCenterRs.operationOperatorInfo,
    },
    {
      label: '手机号码',
      value: searchType.operatorPhone,
      rs: dataCenterRs.operationOperatorInfo,
    },
  ].filter(item => !!item.rs)

  const handleSearch = () => {
    const { type, input } = form.getFieldsValue()
    const param = { value: input, type }
    onSearch(param)
  }

  const handleTypeChange = () => {
    form.setFieldsValue({ input: undefined })
    searchSelectRef.current?.setOptions([])
  }

  return (
    <div className="relative endCenter">
      <div style={{ marginRight: 8, display: 'none' }}>
        {searchFold ? (
          <div className="layer-control-item centerCenter pointer" onClick={handleBikeSearch}>
            <img style={{ width: 24 }} src={layerControlIcon.bike_search}></img>
            <div className="fz16 ml5 mr5" style={{ width: 32 }}>
              搜索
            </div>
          </div>
        ) : (
          <Form
            form={form}
            id="bike-search-form"
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            <Form.Item noStyle>
              <Input.Group className="bike-search-input-group">
                <span onDoubleClick={() => setSearchFold(true)}>
                  <Form.Item name="type" noStyle initialValue={searchType.bikeId}>
                    <Select
                      placeholder="请选择"
                      size="large"
                      className="custom-select bike-search-select"
                      popupClassName="custom-select-dropdown"
                      options={searchOptions}
                      onChange={handleTypeChange}
                    ></Select>
                  </Form.Item>
                </span>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.type !== currentValues.type
                  }
                >
                  {({ getFieldValue }) =>
                    inputSearchTypes.includes(getFieldValue('type')) ? (
                      <Form.Item getValueFromEvent={trimFormInput} name="input" noStyle>
                        <Input
                          allowClear={{
                            clearIcon: (
                              <CloseOutlined
                                style={{ color: '#fff', backgroundColor: '#1B2333' }}
                              />
                            ),
                          }}
                          autoComplete="off"
                          bordered={false}
                          style={{ width: 220 }}
                          placeholder="请输入"
                          className="layer-control-search-input"
                        />
                      </Form.Item>
                    ) : (
                      <SearchSelect
                        ref={searchSelectRef}
                        formItemProps={{ name: 'input', noStyle: true }}
                        childProps={{
                          placeholder: '请输入关键字搜索',
                          allowClear: true,
                          showArrow: false,
                          bordered: false,
                          style: { width: 220, color: '#fff' },
                          className: 'custom-select layer-control-search-select',
                          popupClassName: 'custom-select-dropdown',
                          clearIcon: (
                            <CloseOutlined style={{ color: '#fff', backgroundColor: '#1B2333' }} />
                          ),
                        }}
                        extraProps={{
                          url: 'dataCenterBikeRemoteSearch',
                          data: {
                            entityId: app.currentEntity.entityId,
                            type: getFieldValue('type'),
                            fencingStatus: [searchType.parkingId, searchType.parkingName].includes(
                              getFieldValue('type')
                            )
                              ? 1
                              : undefined, // 查询停车点 1启用状态 不传查全量
                          },
                          searchPropName: 'input',
                          formatData: data => {
                            const { result = [], total } = data
                            const options = result?.map((item: any) => ({
                              label: item.label,
                              value: item.value,
                            }))
                            return { options, total }
                          },
                        }}
                      ></SearchSelect>
                    )
                  }
                </Form.Item>
                <Button
                  onClick={handleSearch}
                  style={{ background: '#1b2333', border: 0 }}
                  icon={<SearchOutlined style={{ color: '#fff' }} />}
                />
              </Input.Group>
            </Form.Item>
          </Form>
        )}
      </div>
      <div style={{ marginRight: 8 }}>
        <div className="layer-control-item centerCenter pointer">
          <img style={{ width: 24 }} src={layerControlIcon.layer_time}></img>
          <div className="fz16 ml5 mr5">最近24小时</div>
          <img style={{ width: 12 }} src={layerControlIcon.layer_arrow}></img>
        </div>
        {/* {legendFold ? null : (
          <div className="manage-desk manage-desk-legend" style={{ right: 100 }}>
            {renderLegendDesk()}
          </div>
        )} */}
      </div>
      <div style={{ marginRight: 8 }}>
        <div className="layer-control-item centerCenter pointer" onClick={handleLegendClick}>
          <img style={{ width: 24 }} src={layerControlIcon.layer_legend}></img>
          <div className="fz16 ml5 mr5" style={{ width: 32 }}>
            图例
          </div>
        </div>
        {legendFold ? null : (
          <div className="manage-desk manage-desk-legend" style={{ right: 100 }}>
            {renderLegendDesk()}
          </div>
        )}
      </div>
      <div>
        <div className="layer-control-item centerCenter pointer" onClick={handleLayerClick}>
          <img style={{ width: 24 }} src={layerControlIcon.layer_control}></img>
          <div className="fz16 ml5 mr5" style={{ width: 32 }}>
            图层
          </div>
        </div>
        {layerFold ? null : <div className="manage-desk">{renderControlDesk()}</div>}
      </div>
    </div>
  )
}

export default LayerControl
