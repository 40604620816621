/**
 * 区域tree筛选器
 */
import React, { useEffect, useState } from 'react'
import { Form, TreeSelect } from 'antd'

import request from '@/utils/request'
import { transformArea } from '@/utils/util'

import type { FormItemProps } from 'antd/es/form'
import type { TreeSelectProps } from 'antd/es/tree-select'

export interface AreaTreeSelectProps {
  formItemProps?: FormItemProps
  childProps?: TreeSelectProps
  onQueryDataSuccess?(data: TreeData): void
  onQueryChildDataSuccess?(key: React.Key, data: TreeData): void
}

const AreaTreeSelect: React.FC<AreaTreeSelectProps> = ({
  formItemProps = {},
  childProps = {},
  onQueryDataSuccess,
  onQueryChildDataSuccess,
}) => {
  const [key, setKey] = useState(0)
  const [treeData, setTreeData] = useState<DataNode[]>([])

  useEffect(() => {
    if (childProps.treeData) {
      setKey(key => key + 1)
      setTreeData(childProps.treeData as DataNode[])
    }
  }, [childProps.treeData])

  useEffect(() => {
    if (!treeData?.length && !childProps.treeData) {
      queryRegions()
    }
  }, [treeData, childProps.treeData])

  const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
    list.map(node => {
      if (node.key === key) {
        return {
          ...node,
          children,
        }
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        }
      }
      return node
    })

  const queryRegions = () => {
    request('commonQueryRegions', { data: {} }).then(res => {
      if (res?.code === 0 && res.data) {
        setTreeData(transformArea(res.data, node => ({ isLeaf: !node.hasChildren })))
        onQueryDataSuccess?.(res.data)
      }
    })
  }

  const onLoadData = ({ key, children }: any) => {
    if (children?.length) {
      return Promise.resolve()
    }
    const data = {
      adCode: key,
      depth: 1,
    }
    return request('commonQueryRegions', { data }).then(res => {
      if (res?.code === 0 && res.data?.length) {
        setTreeData(origin =>
          updateTreeData(
            origin,
            key!,
            transformArea(res.data[0]?.children || [], node => ({
              isLeaf: !node.hasChildren,
            }))
          )
        )
        onQueryChildDataSuccess?.(key, res.data)
      }
    })
  }

  return (
    <Form.Item {...formItemProps}>
      <TreeSelect
        showSearch
        allowClear
        key={key}
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        placeholder="请选择"
        treeNodeFilterProp="title"
        treeDefaultExpandAll={false}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        loadData={onLoadData}
        {...childProps}
        treeData={treeData}
      />
    </Form.Item>
  )
}

export default AreaTreeSelect
