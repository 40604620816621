import { useState, useEffect } from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import { useAppSelector, useUserResources } from '@/utils/hooks'
import { validateModuleResource } from '@/utils/util'

const noPermissionTip = '抱歉，页面暂时无法访问'
const loadResourceTip = '资源加载中...'
const page_not_found = require('_root/assets/img/page_not_found.png')

const NotFound: React.FC = () => {
  const history = useHistory()
  const [loading] = useAppSelector(state => [state.loading])
  const [dataCenterRs, manageCenterRs] = useUserResources(rs => [rs.dataCenter, rs.manageCenter])

  const [tip, setTip] = useState('')

  useEffect(() => {
    if (loading.resource) {
      return setTip(loadResourceTip)
    } else if (manageCenterRs.index && !loading.resource) {
      // 资源加载完成，访问的路径不正确
      return setTip(noPermissionTip)
    } else if (validateModuleResource(dataCenterRs) && !validateModuleResource(manageCenterRs)) {
      // 资源加载完成，管理模块无此资源点
      return setTip(noPermissionTip)
    } else if (!loading.loadingSuccess.resource) {
      // 资源加载失败
      return setTip(noPermissionTip)
    }
    // 初始条件下，及兜底情况
    const timer = setTimeout(() => {
      setTip(noPermissionTip)
    }, 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [loading])

  const renderTip = () => {
    if (tip === noPermissionTip) {
      return (
        <div className="columnCenter mt20 h100">
          <img src={page_not_found} style={{ width: 134, height: 112 }}></img>
          <div style={{ fontSize: 16, fontWeight: 600, color: '#191919' }}>{tip}</div>
          <Button
            type="primary"
            style={{ marginTop: 36 }}
            onClick={() => history.replace('/dataCenter')}
          >
            返回数据中心
          </Button>
        </div>
      )
    }
    return <div className="centerCenter mt20">{tip}</div>
  }

  return <>{renderTip()}</>
}

export default NotFound
