/**
 * 初始化按坐标搜索位置(定位到该位置)
 */
import { Tabs, Popover, Collapse } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import PlaceSearch from './PlaceSearch'
import FenceSearch from './FenceSearch'
import LocationSearch from './LocationSearch'

import './index.less'

const TabPane = Tabs.TabPane
const { Panel } = Collapse

const searchStyle = {
  marginBottom: 0,
}

const MapSearch = ({ map }) => {
  return (
    <div className="map-search-box">
      <Tabs size="small" defaultActiveKey="1" destroyInactiveTabPane>
        <TabPane key="1" tab="按地点搜索">
          <PlaceSearch map={map}></PlaceSearch>
        </TabPane>
        <TabPane
          key="2"
          tab={
            <span>
              按坐标搜索
              <Popover
                content="注意：坐标格式必须是经度+半角逗号+纬度，例如：116.397617,39.910993"
                trigger="hover"
              >
                <QuestionCircleOutlined className="ml5" />
              </Popover>
            </span>
          }
        >
          <div className="select-item" style={searchStyle}>
            <LocationSearch map={map}></LocationSearch>
          </div>
        </TabPane>
      </Tabs>
      {/* <Collapse bordered={false} className="fenceMap-fenceSearch">
        <Panel key="1" header="查询">
          <FenceSearch map={map}></FenceSearch>
        </Panel>
      </Collapse> */}
    </div>
  )
}

MapSearch.FenceSearch = FenceSearch
MapSearch.PlaceSearch = PlaceSearch
MapSearch.LocationSearch = LocationSearch

export default MapSearch
