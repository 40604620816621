import { useEffect, useRef, useState } from 'react'
import { message } from 'antd'

import { changeLngLat, toFixed } from '@/utils/util'

const MapGeometryEditor = ({ map, region = [], onDrawComplete, drawZoom = 14 }) => {
  const editorRef = useRef(null)
  const polygonRef = useRef(null)
  const activeTypeRef = useRef('polygon')

  const [geometry, setGeometry] = useState(null)

  useEffect(() => {
    if (map) {
      initEditor()
      listenEditor()
    }
  }, [map])

  // useEffect(() => {
  //   if (map && region?.length) {
  //     drawRegion()
  //   }
  // }, [map, region])

  useEffect(() => {
    onDrawComplete?.(geometry)
  }, [geometry])

  const getGeometries = () => {
    const geometries = [
      {
        // styleId: 'default',
        paths: changeLngLat(region),
        properties: {
          title: 'polygon',
        },
      },
    ]
    return geometries
  }

  const initEditor = () => {
    const polygon = new TMap.MultiPolygon({
      map: map,
      zIndex: 10,
    })
    if (region?.length) {
      const geometries = getGeometries()
      polygon.setGeometries(geometries)
      onDrawComplete?.(geometries[0])
    }
    const editor = new TMap.tools.GeometryEditor({
      map: map,
      overlayList: [
        {
          overlay: polygon,
          id: 'polygon',
        },
      ],
      actionMode: region?.length
        ? TMap.tools.constants.EDITOR_ACTION.INTERACT
        : TMap.tools.constants.EDITOR_ACTION.DRAW, // 编辑器的工作模式
      activeOverlayId: activeTypeRef.current, // 激活图层
      snappable: true, // 开启吸附
      selectable: true,
    })
    editorRef.current = editor
    polygonRef.current = polygon
  }

  const listenEditor = () => {
    const editor = editorRef.current
    const polygon = polygonRef.current
    editor.on('drawing', () => {
      const zoom = toFixed(map.getZoom(), 0)
      if (zoom < drawZoom) {
        message.warn(`需要放大地图层级到${drawZoom}再绘制，当前为${zoom}`)
        editor.disable()
      }
      editor.enable()
    })
    editor.on('draw_complete', geometry => {
      if (editor.getActiveOverlay().id === activeTypeRef.current) {
        const id = geometry.id
        const geo = polygon.geometries.find(function (item) {
          return item.id === id
        })
        editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT)
        setGeometry(geo)
      }
    })
    editor.on('adjust_complete', geometry => {
      if (editor.getActiveOverlay().id === activeTypeRef.current) {
        setGeometry({ ...geometry })
      }
    })
    editor.on('delete_complete', () => {
      if (editor.getActiveOverlay().id === activeTypeRef.current) {
        editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW)
        setGeometry(null)
      }
    })
  }

  return null
}

export default MapGeometryEditor
