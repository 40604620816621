import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getNamespace } from '../../utils/util'

interface LoadingState {
  app: boolean
  datacenter: boolean
  resource: boolean
}

interface LoadingSuccess {
  loadingSuccess: Record<keyof LoadingState, boolean>
}

const initialLoadingState: LoadingState = {
  app: false,
  datacenter: false,
  resource: false,
}

const initialLoadingSuccess: LoadingSuccess = {
  loadingSuccess: Object.keys(initialLoadingState).reduce(
    (pre, key) => ({ ...pre, [key]: true }),
    {} as LoadingState
  ),
}

const initialState: LoadingState & LoadingSuccess = {
  ...initialLoadingState,
  ...initialLoadingSuccess,
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<{ type: string }>) => {
      state[getNamespace(action.payload?.type) as keyof LoadingState] = true
    },
    hide: (state, action) => {
      state[getNamespace(action.payload?.type) as keyof LoadingState] = false
    },
    loadingSuccess: (state, action) => {
      state.loadingSuccess[
        getNamespace(action.payload?.type) as keyof LoadingSuccess['loadingSuccess']
      ] = action.payload.success
    },
  },
})

export const { show, hide, loadingSuccess } = loadingSlice.actions

export default loadingSlice.reducer
