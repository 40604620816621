/**
 * 管理员实体筛选器
 */
import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

import { roleTypeMap } from '@/utils/consts'
import { tranformToOptions, lsGetItem } from '@/utils/util'
import { useAppSelector, useAppDispatch, useDataCenterSearchParams } from '@/utils/hooks'
import { currentEntity } from '@/redux/reducers/app'

import type { SelectProps } from 'antd/es/select'
import type { FormItemProps, FormInstance } from 'antd/es/form'

export interface Props extends SelectProps {
  extraProps?: {
    form?: FormInstance
  }
  formItemProps?: FormItemProps
  childProps?: SelectProps
}

const AdminEntitySelect: React.FC<Props> = ({
  childProps = {},
  formItemProps = {},
  extraProps,
}) => {
  const dispatch = useAppDispatch()
  const [app] = useAppSelector(state => [state.app])
  const { userInfo } = app
  const [options, setOptions] = useState<Options>([])

  const searchParams = useDataCenterSearchParams()
  searchParams.entityId = searchParams.entityId || lsGetItem('__entityId__')!

  useEffect(() => {
    if (userInfo.entityList?.length) {
      // 管理员用户
      setOptions(tranformToOptions(userInfo.entityList))
      const { name } = formItemProps
      if (userInfo.roleType === roleTypeMap.managerRole) {
        // 判断 searchParams.entityId 是否在userInfo.entityList
        const isValidSearchEntityId = userInfo.entityList.find(
          entity => entity.entityId === +searchParams.entityId!
        )
        // url上有entityId并且是有效的entityId
        if (searchParams.entityId && isValidSearchEntityId) {
          extraProps?.form?.setFieldValue(name!, +searchParams.entityId)
          initQueryEntity(+searchParams.entityId)
        } else {
          extraProps?.form?.setFieldValue(name!, userInfo.entityId)
          handleChange(userInfo.entityId)
        }
      } else {
        // 平台用户或企业用户默认选择第一个实体
        extraProps?.form?.setFieldValue(name!, userInfo.entityList[0].entityId)
        handleChange(userInfo.entityList[0].entityId)
      }
    } else if (userInfo.entityId) {
      // 平台用户或企业用户确定当前实体
      const entity = {
        entityId: userInfo.entityId,
        entityName: userInfo.entityName,
        entityBusinessType: userInfo.entityBusinessType,
        regionType: userInfo.regionType,
        subjectColor: userInfo.subjectColor,
        mapBackgroundColor: userInfo.mapBackgroundColor,
        fromSearch: +searchParams.entityId! === userInfo.entityId,
      }
      updateCurrentEntity(entity)
      childProps.onChange?.(entity.entityId, entity as any)
    }
  }, [userInfo])

  const initQueryEntity = (entityId: Entity['entityId']) => {
    const entity = userInfo.entityList?.find(item => item.entityId == entityId) ?? {}
    updateCurrentEntity({ ...entity, fromSearch: true } as Entity)
    childProps.onChange?.(entityId, { ...entity, fromSearch: true } as any)
  }

  const handleChange = (entityId: Entity['entityId']) => {
    const entity = userInfo.entityList?.find(item => item.entityId == entityId) ?? {}
    updateCurrentEntity(entity as Entity)
    childProps.onChange?.(entityId, entity as any)
  }

  const updateCurrentEntity = (entity: Entity) => {
    dispatch(currentEntity(entity))
  }

  return userInfo.entityList?.length ? (
    <Form.Item {...formItemProps}>
      <Select
        allowClear
        showSearch
        optionFilterProp="label"
        placeholder="选择实体"
        options={options}
        {...childProps}
        onChange={handleChange}
      ></Select>
    </Form.Item>
  ) : null
}

export default AdminEntitySelect
