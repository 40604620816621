import { useState } from 'react'

const layerControl = {
  layer_legend: require('_root/assets/img/layer_legend.png'),
}

const Legend: React.FC = () => {
  const [legendFold, setLegendFold] = useState(true)

  const handleLegendClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setLegendFold(!legendFold)
  }

  const renderLegendDesk = () => {
    const data = [
      { label: '有监管实体', color: '#2965FF' },
      { label: '无监管实体', color: '#6179A8' },
    ]
    return data.map((item, idx) => (
      <div
        key={item.label}
        className="startStart"
        style={{ marginBottom: idx !== data.length - 1 ? 16 : 0 }}
      >
        <div
          style={{ background: item.color, marginRight: 8, width: 20, height: 20, borderRadius: 4 }}
        ></div>
        <div style={{ fontSize: 12, color: ' #BCCBE0' }}>{item.label}</div>
      </div>
    ))
  }

  return (
    <div className="relative endCenter">
      <div>
        <div className="legend-item centerCenter pointer" onClick={handleLegendClick}>
          <img style={{ width: 24 }} src={layerControl.layer_legend}></img>
          <div className="fz16 ml5 mr5" style={{ width: 32 }}>
            图例
          </div>
        </div>
        {legendFold ? null : <div className="legend-desk">{renderLegendDesk()}</div>}
      </div>
    </div>
  )
}

export default Legend
