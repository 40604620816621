import RideCount from './RideCount'
import OrderStatistics from './OrderStatistics'
import RealOrderWork from './RealOrderWork'

import './index.less'

const Right = () => {
  return (
    <div id="parentContent">
      <RideCount></RideCount>
      <OrderStatistics></OrderStatistics>
      <RealOrderWork></RealOrderWork>
    </div>
  )
}

export default Right
