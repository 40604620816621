import { useEffect, useState } from 'react'

import { businessLineMap, businessLineTextMap } from '@/utils/consts'
import { useAppSelector } from '@/utils/hooks'

import './index.less'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  showBiz?: boolean
  showTitle?: boolean
  showTitleDecoration?: boolean
  onBizChange?: (biz: BusinessType) => void
}

const ContentBox: React.FC<Props> = ({
  children,
  title = '车辆备案情况',
  showBiz = true,
  showTitle = true,
  showTitleDecoration = false,
  onBizChange,
  ...rest
}) => {
  const [selBiz, setSelBiz] = useState(businessLineMap.bike)
  const [app] = useAppSelector(state => [state.app])
  const { currentEntity, currentBiz } = app

  useEffect(() => {
    // 监听实体切换
    if (currentEntity.entityBusinessType === businessLineMap.double) {
      handleBizChange(businessLineMap.bike)
    } else if (currentEntity.entityBusinessType) {
      handleBizChange(currentEntity.entityBusinessType)
    }
  }, [currentEntity.entityBusinessType])

  useEffect(() => {
    // 监听业务线切换
    if (currentBiz.businessType === businessLineMap.double) {
      handleBizChange(businessLineMap.bike)
    } else if (currentBiz.businessType) {
      handleBizChange(currentBiz.businessType)
    }
  }, [currentBiz.businessType])

  const handleBizChange = (biz: BusinessType) => {
    setSelBiz(biz)
    onBizChange?.(biz)
  }

  const renderBiz = () => {
    if (showBiz && currentEntity.entityBusinessType === businessLineMap.double) {
      return (
        <div className="biz startCenter ml20">
          <div
            className={`mr12 pointer ${selBiz === businessLineMap.bike ? 'sel-biz' : ''}`}
            onClick={() => handleBizChange(businessLineMap.bike)}
          >
            {businessLineTextMap[businessLineMap.bike]}
          </div>
          <div
            className={`pointer  ${selBiz === businessLineMap.spock ? 'sel-biz' : ''}`}
            onClick={() => handleBizChange(businessLineMap.spock)}
          >
            {businessLineTextMap[businessLineMap.spock]}
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <div className="patrol-content-box" {...rest}>
      {showTitleDecoration && (
        <>
          <div className="content-box-left-bg"></div>
          <div className="content-box-right-bg"></div>
          <div className="content-box-top-bg"></div>
        </>
      )}
      {showTitle && (
        <>
          <div className="content-box-icon"></div>
          <div className="content-box-bg"></div>
        </>
      )}
      {showTitle && (
        <div className="startCenter" style={{ display: 'inline-block' }}>
          <div className="title">{title}</div>
          <div className="title-img"></div>
          {/* {renderBiz()} */}
        </div>
      )}
      {children}
    </div>
  )
}

export default ContentBox
