/**
 * 登录用户业务线筛选器
 */
import React, { useMemo } from 'react'
import { Form, Select } from 'antd'

import { getBizOptions } from '@/utils/util'
import { useAppSelector } from '@/utils/hooks'
import { businessLineMap } from '@/utils/consts'

import type { FormItemProps, FormInstance } from 'antd/es/form'
import type { SelectProps } from 'antd/es/select'

const { Item } = Form

export interface BizSelectProps {
  extraProps?: { needDoubleBiz?: boolean; form?: FormInstance }
  formItemProps?: FormItemProps
  childProps?: SelectProps
}

export const BizSelect: React.FC<BizSelectProps> = ({
  extraProps: { needDoubleBiz = true } = {},
  formItemProps = {},
  childProps = {},
}) => {
  const { currentEntity } = useAppSelector(state => state.app)

  const initialValue = useMemo(() => {
    const businessType = currentEntity?.entityBusinessType
    const hasInitialValue = 'initialValue' in formItemProps

    if (+businessType === businessLineMap.double) {
      // 双业务，使用默认业务
      return hasInitialValue ? formItemProps.initialValue : businessLineMap.bike
    } else {
      // 不是双业务，使用该单业务
      return hasInitialValue ? formItemProps.initialValue : businessType
    }
  }, [currentEntity?.entityBusinessType])

  const options = useMemo(() => {
    return getBizOptions(currentEntity?.entityBusinessType, needDoubleBiz)
  }, [currentEntity?.entityBusinessType])

  const handleChange = (value: BusinessType, options?: any) => {
    childProps.onChange?.(value, options)
  }

  return (
    <Item {...formItemProps} initialValue={initialValue}>
      <Select
        allowClear
        placeholder="请选择"
        {...childProps}
        options={options}
        onChange={handleChange}
      ></Select>
    </Item>
  )
}

export default BizSelect
