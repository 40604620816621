import { useEffect, useMemo, useState, useContext } from 'react'
import CountUp from 'react-countup'

import { useAppSelector } from '@/utils/hooks'
import ContentBox from '../../contentBox'
import { Context } from '../index'

import './index.less'

const iconArrow = { show_detail_arrow: require('_root/assets/img/show_detail_arrow.png') }

const OrderReality = () => {
  const [app] = useAppSelector(state => [state.app])
  const [showDetail, setShowDetail] = useState(false)
  const { realityData } = useContext(Context)
  const [resetKey, setResetKey] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setResetKey(prevKey => prevKey + 1)
    }, 4000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const dealData: any = useMemo(() => {
    const data = realityData
    return data.length > 5 && !showDetail ? data.slice(0, 5) : realityData
  }, [realityData, showDetail])

  return (
    <div
      className="order-reality-parent"
      style={{ border: '1px solid rgba(75, 210, 222, 0.4)', borderRadius: '12px' }}
    >
      <ContentBox title="秩序实况" showTitleDecoration={true} style={{ width: '100%' }}>
        <div
          style={{ paddingBottom: realityData.length > 5 ? '10px' : '0' }}
          className="order-reality"
        >
          {dealData.length > 0 && (
            <div
              className="order-reality-content"
              style={{ width: `${100 / (realityData.length > 5 ? 5 : realityData.length)}%` }}
            >
              <div className="order-reality-title">{dealData[0]?.label}</div>
              {/* <span className="order-reality-quantity">{dealData[0]?.rate}%</span> */}
              <span className="order-reality-quantity">
                <CountUp key={resetKey + 1} start={0} end={dealData[0]?.rate} duration={1} />%
              </span>
              <span className="order-reality-percentages">
                {dealData[0]?.numerator}/{dealData[0]?.denominator}
              </span>
            </div>
          )}
          {dealData &&
            dealData.map((item: any, index: number) => {
              return (
                index > 0 && (
                  <div
                    className="order-reality-content"
                    style={{ width: `${100 / (realityData.length > 5 ? 5 : realityData.length)}%` }}
                    key={index}
                  >
                    <div className="order-reality-title">{item.label}</div>
                    <span className="order-reality-quantity">
                      <CountUp key={resetKey} start={0} end={item.numerator} duration={1} />/
                      <CountUp key={resetKey + 1} start={0} end={item.denominator} duration={1} />
                      {/* {item.numerator}/{item.denominator} */}
                    </span>
                  </div>
                )
              )
            })}
        </div>
        {realityData.length > 5 && (
          <div onClick={() => setShowDetail(!showDetail)} className="detail-show-btn">
            <span style={{ marginRight: '8px' }}>{showDetail ? '收起企业' : '更多企业'}</span>
            {showDetail ? (
              <img className="detail-show-btn-rotate" src={iconArrow.show_detail_arrow} />
            ) : (
              <img className="detail-show-btn-img" src={iconArrow.show_detail_arrow} />
            )}
          </div>
        )}
      </ContentBox>
    </div>
  )
}

export default OrderReality
