/**
 * 城市筛选器
 */
import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

import request from '@/utils/request'

import type { FormItemProps } from 'antd/es/form'
import type { SelectProps } from 'antd/es/select'

const { Option } = Select

export interface CitySelectProps {
  extraData?: object
  formItemProps?: FormItemProps
  childProps?: SelectProps
}

const CitySelect: React.FC<CitySelectProps> = ({ extraData, childProps, formItemProps }) => {
  const [list, setList] = useState<{ adCode: string; adName: string }[]>([])

  useEffect(() => {
    if (!list?.length) {
      queryList()
    }
  }, [list])

  const queryList = () => {
    request('queryAreaCityList', { data: extraData }).then(res => {
      const { data = [], code } = res
      code == 0 && data?.length && setList(data)
    })
  }

  return (
    <Form.Item {...formItemProps}>
      <Select allowClear showSearch {...childProps}>
        {list.map((item: any, index) => (
          <Option key={index} value={item.adCode}>
            {item.adName}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default CitySelect
