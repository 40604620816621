import { getFullScreenEle } from '@/utils/util'
import { useAppSelector, useAppDispatch } from '@/utils/hooks'
import { updateFullScreen } from '@/redux/reducers/app'

const mapControl = {
  map_full: require('_root/assets/img/map_full.png'),
  map_unfull: require('_root/assets/img/map_unfull.png'),
}

const AreaMapControl: React.FC = () => {
  const [app] = useAppSelector(state => [state.app])
  const dispatch = useAppDispatch()

  const { fullScreen } = app

  const toggleFullscreen = () => {
    const elem = getFullScreenEle()
    elem.onfullscreenchange = handleFullscreenChange
    if (!document.fullscreenElement) {
      elem
        .requestFullscreen()
        .then(() => {})
        .catch((err: any) => {
          alert(`Error attempting to enable full-screen mode: ${err?.message} (${err?.name})`)
        })
    } else {
      document.exitFullscreen()
    }
  }

  const handleFullscreenChange = (event: any) => {
    const elem = event.target
    const isFullscreen = document.fullscreenElement === elem
    onFullScreenClick(isFullscreen)
  }

  const handleFullScreenClick = () => {
    if (document.fullscreenEnabled) {
      toggleFullscreen()
    } else {
      onFullScreenClick(!fullScreen)
    }
  }

  const onFullScreenClick = (isFull: boolean) => dispatch(updateFullScreen(isFull))

  return (
    <div className="area-map-control endCenter">
      <div
        className="area-map-control-item mb8 pointer"
        style={{ padding: 8, marginTop: 8 }}
        onClick={handleFullScreenClick}
        title={fullScreen ? '收起全屏模式' : '全屏模式'}
      >
        {fullScreen ? (
          <img style={{ width: 24 }} src={mapControl.map_unfull}></img>
        ) : (
          <img style={{ width: 24 }} src={mapControl.map_full}></img>
        )}
      </div>
    </div>
  )
}

export default AreaMapControl
