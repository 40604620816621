import React, { useEffect, useState, useRef } from 'react'
import { Modal, Table, Radio, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import throttle from 'lodash/throttle'
import * as echarts from 'echarts'

import request from '@/utils/request'
import { useAppSelector, useTimingRequest } from '@/utils/hooks'

import type { RadioChangeEvent } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import './index.less'

type EChartsOption = echarts.EChartsOption
interface Props {
  visible: boolean
  polygon: string
  stationInfo: any
  onCancel: () => void
}
interface DataType {
  parkingSpotName: string
  maxCapacity: number
  currentParkingCount: number
  totalDepositTime: number
}

const columns: ColumnsType<DataType> = [
  {
    title: '停车点名称',
    dataIndex: 'parkingSpotName',
    ellipsis: {
      showTitle: false,
    },
    render: text => (
      <Tooltip
        placement="topLeft"
        title={text}
        color="rgba(255, 255, 255, 0.9)"
        overlayInnerStyle={{ color: '#000', borderRadius: '6px' }}
      >
        <span>{text || '-'}</span>
      </Tooltip>
    ),
  },
  {
    title: '最大停车容量',
    dataIndex: 'maxCapacity',
    render: text => <span>{text || '-'}</span>,
  },
  {
    title: '当前停放车数量',
    dataIndex: 'currentParkingCount',
    render: text => <span>{text || '-'}</span>,
  },
]
const DiagnosisModal: React.FC<Props> = ({ visible, polygon, stationInfo, onCancel }) => {
  const [app] = useAppSelector(state => [state.app])
  const [info, setInfo] = useState<any>({})
  const [tableData, setTableData] = useState<DataType[]>([])
  const [buttonValue, setButtonValue] = useState<number>(1)
  const [trackData, setTrackData] = useState<any>({})
  const [arcMap, setArcMap] = useState<any>(null)
  const [marker, setMarker] = useState<any>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerTwoRef = useRef<HTMLDivElement>(null)
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<any>(null)
  const chartTwoRef = useRef<any>(null)

  useEffect(() => {
    if (visible && polygon && stationInfo) {
      subwayCenterDiagnosticAnalysis()
      setTimeout(() => {
        subwayCenterTrack()
      })
    }
  }, [visible, stationInfo, polygon])

  useTimingRequest(
    () => {
      setTimeout(() => {
        subwayCenterDiagnosticAnalysisGraph()
      })
    },
    [visible],
    10000
  )

  const subwayCenterDiagnosticAnalysis = () => {
    // const data = require('_root/mock/subwayCenter/subwayCenterDiagnosticAnalysis.json')
    // setInfo(data.data || {})
    // setTableData(data.data.list || [])
    const data = {
      entityId: app.currentEntity.entityId,
      polygon,
    }
    request('diagnosticAnalysis', { data }).then(res => {
      if (res.code === 0) {
        setInfo(res.data || {})
        setTableData(res.data?.list || [])
      }
    })
  }

  const subwayCenterDiagnosticAnalysisGraph = () => {
    const data = require('_root/mock/subwayCenter/subwayCenterDiagnosticAnalysisGraph.json')
    const arr = [
      {
        key: containerRef,
        value: chartRef,
      },
      {
        key: containerTwoRef,
        value: chartTwoRef,
      },
    ]
    arr.forEach((item: any, index: number) => {
      if (item.key.current) {
        item.key.current!.style.height = '280px'
        item.value.current = echarts.dispose(item.key.current as any)
        !item.value.current && initChart(item)
        setOption(
          index === 0 ? data.data?.rideCount || [] : data.data?.passengerFlow || [],
          item.value
        )
      }
    })
  }

  const subwayCenterTrack = () => {
    // const data = require('_root/mock/subwayCenter/subwayCenterTrack.json')
    // setTrackData(data.data || {})
    // initMap(data.data || [])
    const subwayMap: any = {
      石羊立交: 'SHIYANG_INTERCHANGE_METRO_STATION',
      孵化园: 'INCUBATOR_METRO_STATION',
      金融城: 'FINANCIAL_CITY_METRO_STATION',
    }
    const subway = subwayMap[stationInfo?.station_name] || 'SHIYANG_INTERCHANGE_METRO_STATION'
    const data = { subway }
    request('cyclingTrajectoryDiagram', { data }).then(res => {
      if (res.code === 0) {
        setTrackData(res.data || {})
        initMap(res.data)
      }
    })
  }

  const setOption = (data: any, item: any) => {
    const seriesData: any[] = []
    const legendData: any[] = []
    let xData: any[] = []
    data.series?.forEach((item: any) => {
      legendData.push({
        name: item?.name,
        icon: 'roundRect',
        itemStyle: {
          color: item?.color,
        },
      })
      xData = Object.keys(item.data)
      seriesData.push({
        color: item.color,
        data: Object.values(item.data),
        name: item.name,
        type: 'line',
        showSymbol: false,
        animationDuration: 3000,
      })
    })
    const option: EChartsOption = {
      title: {
        top: 0,
        left: 'left',
        text: data.title,
        textStyle: {
          fontSize: '16px',
          color: 'rgba(255, 255, 255, 0.9)',
        },
        subtext: '单位(人)',
        subtextStyle: {
          color: '#DEDEDE',
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(66, 75, 92, 0.2)',
          },
        },
        backgroundColor: '#424B5C',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
        formatter: function (params: any) {
          let str = ''
          params &&
            params.forEach((item: any) => {
              str +=
                '<div>' +
                '<span style="display:inline-block;margin-right:4px;width:3px;height:8px;border-radius:1px;background-color:' +
                item.color +
                ';"></span>' +
                ' ' +
                '<span style="color: #BCCBE0;">' +
                item.seriesName +
                '</span>' +
                '<span style="display:inline-block;margin-right:20px;"></span>' +
                '<span style="float:right;font-family: AvenirLight;">' +
                item.value +
                '</span>' +
                '</div>'
            })
          return (
            '<div>' +
            '<span style="display:block;margin-bottom:10px;font-size:14px;">' +
            params[0].name +
            '</span>' +
            str +
            '</div>'
          )
        },
      },
      legend: {
        itemWidth: 8,
        itemHeight: 2,
        bottom: 0,
        textStyle: { color: '#BCCBE0' },
        data: legendData,
      },
      xAxis: {
        axisLabel: {
          color: '#DEDEDE',
        },
        data: xData,
      },
      yAxis: [
        {
          axisLabel: {
            color: '#DEDEDE',
            formatter: function (value: any) {
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + 'W'
              } else if (value >= 10000000) {
                value = value / 10000000 + 'KW'
              }
              return value
            },
          },
          splitNumber: 4,
          splitLine: {
            lineStyle: {
              color: '#748399',
              width: 0.4,
            },
          },
        },
      ],
      grid: {
        top: 60,
        bottom: 30,
        left: 1,
        right: 18,
        containLabel: true,
      },
      series: seriesData,
    }
    item.current?.setOption(option)
    setTimeout(() => {
      item.current?.resize()
    }, 3000)
  }

  const initChart = (item: any) => {
    item.value.current = echarts.init(item.key.current as any)
    window.addEventListener(
      'resize',
      throttle(function () {
        item.value.current?.resize()
      }, 100)
    )
  }

  const getMarker = (mapData: any[], index = 1) => {
    const geometries = [
      {
        styleId: 'toMarker',
        content: 1,
        position: new TMap.LatLng(
          index === 1 ? mapData[0]?.to.lat : mapData[0]?.from.lat,
          index === 1 ? mapData[0]?.to.lng : mapData[0]?.from.lng
        ), // 标记位置(纬度，经度，高度)
      },
    ]
    mapData.forEach((item: any) => {
      geometries.push({
        styleId: 'fromMarker',
        content: 2,
        position: new TMap.LatLng(
          index === 1 ? item?.from.lat : item?.to.lat,
          index === 1 ? item?.from.lng : item?.to.lng
        ),
      })
    })
    return geometries
  }

  const initMap = (data: any) => {
    const mapData = data.inflow || []
    //定义map变量，调用TMap.Map构造函数创建地图
    const tmap = new TMap.Map(mapContainerRef.current!, {
      center: new TMap.LatLng(mapData[0]?.to.lat, mapData[0]?.to.lng), //设置地图中心点坐标
      zoom: 16, //设置地图缩放级别
      showControl: false, //是否显示地图上的控件
      mapStyleId: 'style 5 大屏',
      pitch: 45,
    })
    // @ts-ignoreq
    const marker = new TMap.MultiMarker({
      map: tmap,
      styles: {
        // @ts-ignore
        toMarker: new TMap.MarkerStyle({
          width: 36,
          height: 36,
          anchor: { x: 18, y: 36 },
          src: require('_root/assets/img/station_deposit.png'),
        }),
        // @ts-ignore
        fromMarker: new TMap.MarkerStyle({
          width: 16,
          height: 18,
          anchor: { x: 8, y: 16 },
          src: require('_root/assets/img/spot.png'),
        }),
      },
      // @ts-ignore
      geometries: getMarker(mapData),
    })
    // @ts-ignoreq
    const color = new TMap.GradientColor({
      stops: {
        0: 'rgba(11,205,222,0)',
        1: '#0BCDDE',
      },
      angle: 70,
    })
    // 创建弧线图
    // @ts-ignoreq
    const arc = new TMap.visualization.Arc({
      pickStyle: function () {
        return {
          animateColor: 'rgba(11,205,222,0)',
          color: color,
          width: 5,
        }
      },
    })
      .addTo(tmap)
      .setData(mapData)
    setMarker(marker)
    setArcMap(arc)
    // @ts-ignoreq
    document.querySelector('.map canvas+div:last-child').style.display = 'none'
  }

  const radioChange = (e: RadioChangeEvent) => {
    setButtonValue(e.target.value)
    const mapData = e.target.value === 1 ? trackData.inflow || [] : trackData.outflow || []
    // @ts-ignoreq
    arcMap && arcMap.setData(mapData)
    marker && marker.setGeometries(getMarker(mapData, e.target.value))
  }

  const onModalCancel = () => {
    setButtonValue(1)
    onCancel()
  }

  return (
    <Modal
      footer={null}
      open={visible}
      destroyOnClose
      width="90%"
      wrapClassName="model"
      bodyStyle={{ background: '#424B5C', borderRadius: '12px' }}
      maskClosable={false}
      onCancel={onModalCancel}
    >
      <div>
        <h2 className="title">{stationInfo?.station_name}地铁站接驳诊断分析</h2>
        <div className="tip">
          <InfoCircleOutlined style={{ margin: '0 16px 0 18px' }} />
          {info.tip || '暂无建议'}
        </div>
        <div className="content">
          <div className="box">
            <p className="text">{info.content || '暂无诊断建议'}</p>
            <Table
              columns={columns}
              dataSource={tableData}
              pagination={false}
              bordered={true}
              scroll={{
                y: 160,
              }}
            />
          </div>
          <div className="box" style={{ margin: '0 24px' }}>
            <div ref={containerRef} style={{ flex: 1 }}></div>
          </div>
          <div className="box">
            <div ref={containerTwoRef} style={{ flex: 1 }}></div>
          </div>
        </div>
        <div
          className="map"
          style={{
            height: '440px',
            marginTop: '24px',
            position: 'relative',
            borderRadius: '12px',
            overflow: 'hidden',
          }}
          ref={mapContainerRef}
        >
          <div style={{ position: 'absolute', top: '24px', left: '24px', zIndex: 99999999 }}>
            <Radio.Group buttonStyle="solid" defaultValue={buttonValue} onChange={radioChange}>
              <Radio.Button value={1}>进站接驳</Radio.Button>
              <Radio.Button value={2}>出站接驳</Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DiagnosisModal
