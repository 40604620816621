import { districtLevelMap } from '@/utils/consts'
import { getFullScreenEle } from '@/utils/util'
import { useAppSelector } from '@/utils/hooks'
import { cityZoom, streetZoom, bikeZoom } from './consts'

const mapControl = {
  map_full: require('_root/assets/img/map_full.png'),
  map_unfull: require('_root/assets/img/map_unfull.png'),
  map_pos: require('_root/assets/img/map_pos.png'),
  map_increase: require('_root/assets/img/map_increase.png'),
  map_minus: require('_root/assets/img/map_minus.png'),
  // map_reload: require('_root/assets/img/map_reload.png'),
}

interface Props {
  map: any
  fullScreen: boolean
  onFullScreenClick: (fullScreen: boolean) => void
}

const MapControl: React.FC<Props> = ({ map, fullScreen, onFullScreenClick }) => {
  const [app] = useAppSelector(state => [state.app])

  const handlePosClick = () => {
    if (app.currentArea.level === districtLevelMap.city && map.getZoom() !== cityZoom) {
      // cityInfo
      map.setZoom(cityZoom)
    } else if (app.currentArea.level >= districtLevelMap.area) {
      // bikeInfo
      if (app.currentArea.level === districtLevelMap.area && map.getZoom() !== streetZoom) {
        map.setZoom(streetZoom)
      } else if (app.currentArea.level === districtLevelMap.street && map.getZoom() !== bikeZoom) {
        map.setZoom(bikeZoom)
      }
    }
    // @ts-ignore
    setTimeout(() => map.setCenter(new TMap.LatLng(app.currentArea?.lat, app.currentArea?.lng)), 0)
  }

  const toggleFullscreen = () => {
    const elem = getFullScreenEle()
    elem.onfullscreenchange = handleFullscreenChange
    if (!document.fullscreenElement) {
      elem
        .requestFullscreen()
        .then(() => {})
        .catch((err: any) => {
          alert(`Error attempting to enable full-screen mode: ${err?.message} (${err?.name})`)
        })
    } else {
      document.exitFullscreen()
    }
  }

  const handleFullscreenChange = (event: any) => {
    const elem = event.target
    const isFullscreen = document.fullscreenElement === elem
    onFullScreenClick(isFullscreen)
  }

  const handleFullScreenClick = () => {
    if (document.fullscreenEnabled) {
      toggleFullscreen()
    } else {
      onFullScreenClick(!fullScreen)
    }
  }

  // const handleReloadClick = () => {
  //   reloadCb && reloadCb()
  //   if (map.getZoom() >= eventZoom) {
  //     dispatch({
  //       type: DATACENTER.GET_BIKE_INFO,
  //       payload: {
  //         ...getPayload(),
  //         isBatchRefresh: true,
  //         bikeId: undefined,
  //       },
  //     })
  //   } else {
  //     setTimeout(() => {
  //       map.setZoom(eventZoom)
  //     }, 50)
  //   }
  // }

  return (
    <div className="map-control">
      <div className="map-control-item columnCenter">
        <div
          className="map-control-item-img-box centerCenter"
          onClick={() => map.zoomTo(map.getZoom() + 1)}
        >
          <img style={{ width: 24 }} src={mapControl.map_increase}></img>
        </div>
        <div style={{ marginTop: 27 }}></div>
        <div
          className="map-control-item-img-box centerCenter"
          onClick={() => map.zoomTo(map.getZoom() - 1)}
        >
          <img style={{ width: 24 }} src={mapControl.map_minus}></img>
        </div>
      </div>
      <div
        className="map-control-item mb8 pointer"
        style={{ padding: 8, marginTop: 8 }}
        title="返回初始地图范围"
        onClick={handlePosClick}
      >
        <img style={{ width: 24 }} src={mapControl.map_pos}></img>
      </div>
      <div
        className="map-control-item mb8 pointer"
        style={{ padding: 8, marginTop: 8 }}
        onClick={handleFullScreenClick}
        title={fullScreen ? '收起全地图模式' : '全地图模式'}
      >
        {fullScreen ? (
          <img style={{ width: 24 }} src={mapControl.map_unfull}></img>
        ) : (
          <img style={{ width: 24 }} src={mapControl.map_full}></img>
        )}
      </div>
    </div>
  )
}

export default MapControl
