import { useEffect, memo } from 'react'
import ReactDOMServer from 'react-dom/server'
import { Progress } from 'antd'

import { currentArea } from '@/redux/reducers/app'
import { useAppDispatch } from '@/utils/hooks'

function Donut(options) {
  TMap.DOMOverlay.call(this, options)
}

Donut.prototype = new TMap.DOMOverlay()

Donut.prototype.onInit = function (options) {
  this.position = options.position
  this.data = options.data
  this.minRadius = options.minRadius || 0
  this.maxRadius = options.maxRadius || 50
}

Donut.prototype.onDestroy = function () {
  if (this.onClick) {
    this.dom.removeEventListener(this.onClick)
  }
}

Donut.prototype.createDOM = function () {
  const { name, total, unorderlyCount, rate } = this.data
  const dom = document.createElement('div')
  dom.className = 'map-city-info columnCenter'
  const format = () => {
    return (
      <>
        <div className="map-city-info-name gray07">{name}</div>
        <div className="map-city-info-count gray07">{`${unorderlyCount}/${total}`}</div>
      </>
    )
  }
  const ele = (
    <div style={{ border: '2px solid rgba(64, 84, 112, 0.85)', borderRadius: '50%' }}>
      <Progress
        type="circle"
        strokeColor={getStrokeColor(rate)}
        trailColor="#1f2d40"
        strokeLinecap="butt"
        strokeWidth={10}
        width={102}
        percent={rate}
        format={percent => format(percent)}
      />
    </div>
  )
  const domStr = ReactDOMServer.renderToString(ele)
  dom.innerHTML = domStr
  this.onClick = () => {
    this.emit('click')
  }
  dom.addEventListener('click', this.onClick)
  return dom
}

Donut.prototype.updateDOM = function () {
  if (!this.map) {
    return
  }
  const pixel = this.map.projectToContainer(this.position)
  const left = pixel.getX() - this.dom.clientWidth / 2 + 'px'
  const top = pixel.getY() - this.dom.clientHeight / 2 + 'px'
  this.dom.style.transform = `translate(${left}, ${top})`

  this.emit('dom_updated')
}

const getStrokeColor = bikeCount => {
  if (bikeCount > 50) {
    return '#CC3131'
  } else if (bikeCount > 30) {
    return '#E0812D'
  } else {
    return '#49CAD6'
  }
}

const AreaDonut = ({ map, cityInfo = [] }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (map && cityInfo.length) {
      return initDonutList()
    }
  }, [map, cityInfo])

  const onCityCircleClick = ({ adCode, adName, level, position = {} }) => {
    dispatch(
      currentArea({
        lng: position.lng,
        lat: position.lat,
        level: level,
        adCode: adCode + '',
        adName,
      })
    )
  }

  const initDonutList = () => {
    const donutList = cityInfo.map(info => {
      return new Donut({
        map,
        position: new TMap.LatLng(info.lat, info.lng),
        data: {
          adCode: info.adCode,
          name: info.name,
          bikeCount: info.bikeCount,
          level: info.level,
          total: info.total,
          unorderlyCount: info.unorderlyCount,
          rate: info.rate,
        },
      })
    })

    donutList.forEach(donut => {
      donut.on('click', () => {
        onCityCircleClick({
          map,
          adCode: donut.data.adCode,
          adName: donut.data.name,
          level: donut.data.level,
          position: donut.position,
        })
      })
    })
    return () => {
      donutList.forEach(donut => {
        donut.setMap(null)
      })
    }
  }

  return null
}

export default memo(AreaDonut)
