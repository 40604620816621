/**
 * 实体筛选器
 */
import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

import request from '@/utils/request'
import { tranformToOptions } from '@/utils/util'
import { useAppSelector } from '@/utils/hooks'

import type { SelectProps } from 'antd/es/select'
import type { FormItemProps } from 'antd/es/form'

export interface EntitySelectProps extends SelectProps {
  formItemProps?: FormItemProps
  childProps?: SelectProps
}

const EntitySelect: React.FC<EntitySelectProps> = ({ childProps = {}, formItemProps = {} }) => {
  const [app] = useAppSelector(state => [state.app])
  const [options, setOptions] = useState<Options>([])

  const queryEntityList = () => {
    const data = { entityId: app.currentEntity.entityId }
    request('commonQueryUserEntities', { data }).then(res => {
      const { data = [], code } = res
      code == 0 && data?.length && setOptions(tranformToOptions(data))
    })
  }

  useEffect(() => {
    if (!childProps?.options) {
      queryEntityList()
    }
  }, [childProps?.options])

  return (
    <Form.Item {...formItemProps}>
      <Select
        allowClear
        showSearch
        optionFilterProp="label"
        placeholder="选择实体"
        options={options}
        {...childProps}
      ></Select>
    </Form.Item>
  )
}

export default EntitySelect
