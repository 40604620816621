import PatrolResult from './PatrolResult'
import PatrolOrderStatistics from './PatrolOrderStatistics'
import './index.less'

const Right = () => {
  return (
    <div className="patrol-center-right-box">
      <PatrolOrderStatistics></PatrolOrderStatistics>
      <PatrolResult></PatrolResult>
    </div>
  )
}

export default Right
