const themeMap = {
  css: {
    '--theme-header-bg': '#004B8C', // 顶部栏背景色
    '--theme-body-bg': '#10131a', // 页面背景色
    // 通用
    '--theme-legend-mask-bg': '#617B91', // tree/select/input组件下拉框背景色、图层、图例等mask背景色
    '--theme-legend-bg': 'rgba(0, 41, 77, 0.88)', // 图层、图例等背景色
    '--theme-tooltip-indicator-color': 'rgba(255, 255, 255, 0.7)', // 图层、图例等文字
    '--theme-tooltip-indicator-sub-color': 'rgba(255, 255, 255, 0.5)', // 统计模块subtext等文字
    '--theme-tooltip-indicator-label-color': 'rgba(255, 255, 255, 0.7)', // 图层、图例等文字
    '--theme-tooltip-divider-bg': 'rgba(255, 255, 255, 0.08)', // 图层、图例等分割线
    '--theme-indicator-num-bg': 'rgba(0, 27, 51, 0.4)', // 统计指标-数字框背景色
    '--theme-indicator-num-color': '#41C8FF', // 统计指标-数字颜色
    '--theme-indicator-num-box-shadow':
      '0px 2px 6px 0px rgba(23, 38, 51, 0.3),inset 0px 0px 2px 0px rgba(255, 255, 255, 0.2)', // 统计指标-数字框box shadow
    '--theme-content-box-sel-biz-color': '#41C8FF', // 统计指标-业务线选中颜色
    '--theme-content-box-biz-color': 'rgba(255, 255, 255, 0.7)', // 统计指标-业务线默认颜色
    '--theme-header-title-color': '#41C8FF', // 单个数据中心高亮色
    '-theme-legend-select-item-default-color': '#617B91', // 图层选择项默认颜色
    '-theme-legend-select-item-selected-color': 'rgba(65, 200, 255, 0.2)', // 图层选择项选中颜色
    '--theme-legend-select-item-selected-border-color': '#41C8FF', // 图层选择项选中边框颜色
    '--theme-map-dialog-bg': '#425666', // 地图 - 弹窗背景色
    '--theme-common-dialog-bg': '#425666', // 弹窗背景色
    '--theme-common-dialog-module-bg': '#313f4c', // 弹窗-模块背景色
    '--theme-common-dialog-table-th-bg': '#505c67', // 弹窗-表格表头背景色
    '--theme-common-dialog-table-td-bg': '#3d4a57', // 弹窗-表格单元格背景色
    '--theme-collapse-btn-color': 'rgba(255, 255, 255, 0.7)', // 折叠按钮color
    '--theme-orderly-alarm-bg-color': '#eb5038', // 异常提醒背景色
    // 统计模块
    '--theme-content-box-bg': 'linear-gradient(180deg, rgba(0, 41, 77, 0.95) 0%, #004B8C 100%)', // 背景色
    '--theme-content-box-border-bg': 'linear-gradient(0deg, rgba(0, 122, 229, 0) 0%, #007AE5 100%)', // 边框色
    '--theme-content-box-title-bg': '#41C8FF', // 统计模块-标题竖线颜色
    '--theme-content-box-bg-url': `url(${require('_root/assets/img/content_box_bg_blue.png')})`, // 统计模块-标题竖线背景图

    // 地图
    '--theme-map-search-input-group-bg': 'rgba(2, 42, 77, 0.88)', // 地图-输入框背景色
    '--theme-map-search-select-bg': 'rgba(97, 123, 145, 1)', // 地图-下拉选择背景色
    '--theme-map-modal-confrim-btn-bg': '#41C8FF', // 地图-弹窗确认按钮背景色
    '--theme-map-table-th-bg': 'rgba(0, 0, 0, 0.15)', // 地图-table 表头按钮背景色
    '--theme-map-heat-slider-bg': 'linear-gradient(180deg, rgba(0, 41, 77, 0.95) 0%, #004B8C 100%)', // 地图-热力图slider 背景色
    '--theme-map-heat-slider-border-color':
      'linear-gradient(0deg, rgba(0, 122, 229, 0) 0%, #007AE5 100%)', // 地图-热力图slider border color
    '--theme-map-heat-slider-default-line-color': 'rgba(255, 255, 255, 0.2)', // 地图-热力图slider 线条默认颜色
    '--theme-map-heat-slider-highlight-line-color': '#41C8FF', // 地图-热力图slider 线条高亮颜色
    '--theme-map-heat-slider-hover-line-color': '#41C8FF', // 地图-热力图slider 线条hover颜色
    '--theme-map-heat-slider-active-dot-color': '#FFFFFF', // 地图-热力图slider 选中点颜色
    '--theme-map-heat-slider-mark-color': 'rgba(255, 255, 255, 0.9)', // 地图-热力图slider 字体颜色
    '--theme-map-city-info-border-color': 'rgba(0, 136, 191, 0.3)', // 地图-城市信息气泡border颜色
    '--theme-map-city-info-bg': 'rgba(65, 200, 255, 0.8)', // 地图-城市信息气泡颜色
    '--theme-map-city-info-color': '#151e2d', // 地图-城市信息气泡主color
    '--theme-map-city-operation-info-color': 'rgba(21, 30, 45, 0.7)', // 地图-城市信息气泡 操作人员等信息color
  },
  '--theme-china-map-area-color-primary-0': '#2B83B5', // 中国地图-有实体区域背景色 0%
  '--theme-china-map-area-color-primary-100': '#2B83B5', // 中国地图-有实体区域背景色 100%
  '--theme-china-map-area-color-ghost-0': '#4D6985', // 中国地图-无实体区域背景色 0%
  '--theme-china-map-area-color-ghost-100': '#4D6985', // 中国地图-无实体区域背景色 100%

  '--theme-china-map-area-color-primary-hover-0': '#30B2F2', // 中国地图-有实体区域背景色 hover 0%
  '--theme-china-map-area-color-primary-hover-100': '#30B2F2', // 中国地图-有实体区域背景色 hover 100%
  '--theme-china-map-area-color-ghost-hover-0': '#597999', // 中国地图-无实体区域背景色 hover 0%
  '--theme-china-map-area-color-ghost-hover-100': '#597999', // 中国地图-无实体区域背景色 hover 100%

  '--theme-china-map-area-border-color-primary': '#24AEF2', // 中国地图-有实体区域边框色
  '--theme-china-map-area-border-color-ghost': '#73A6BF', // 中国地图-无实体区域边框色

  '--theme-echarts-subtext-color': 'rgba(255, 255, 255, 0.9)', // echart折线图 subTitle color
  '--theme-echarts-y-axis-split-line-color': '#FFFFFF', // echart折线图 y轴分割线color
  '--theme-echarts-x-axis-split-line-color': 'rgba(255, 255, 255, 0.4)', // echart折线图 x轴分割线color
  '--theme-echarts-y-axis-axis-line-color': 'rgba(255, 255, 255, 0.4)', // echart折线图 y轴轴线color
  '--theme-echarts-x-axis-axis-line-color': 'rgba(255, 255, 255, 0.4)', // echart折线图 x轴轴线color
  '--theme-echarts-x-axis-axis-label-color': '#FFFFFF', // echart折线图 x轴label color
  '--theme-parking-normal-color': '#41C8FF', // 正常停车点统计指标color
  '--theme-content-box-pie-border-color': '#003665', // echart饼图 border color
  '--theme-echarts-bar-color-1': '#EB5038', // 运维力量统计 柱状图柱子颜色
  '--theme-echarts-bar-color-2': '#41C8FF', // 车辆备案情况、实时上报车辆情况 柱状图柱子颜色
  '--theme-echarts-bar-color-3': 'rgba(255, 255, 255, 0)', // 车辆备案情况、实时上报车辆情况 柱状图柱子颜色 - 0%
  '--theme-echarts-bar-color-4': 'rgba(255, 255, 255, 0.15)', // 车辆备案情况、实时上报车辆情况 柱状图柱子颜色 - 100%
  '--theme-tooltip-hover-color': 'rgba(255, 255, 255, 0.12)', // echart图 toolTip hover颜色
  '--theme-tooltip-hover-color-1': 'rgba(255, 255, 255, 0)', // 地图-热力图slider 线条hover颜色 - 0%
  '--theme-tooltip-hover-color-2': 'rgba(255, 255, 255, 0.12)', // 地图-热力图slider 线条hover颜色 - 100%

  '--theme-map-area-polygon-bg': 'rgba(0, 181, 255, 0.1)', // 地图区域-polygon背景色
  '--theme-map-area-polygon-border-color': 'rgba(77, 203, 255, 1)', // 地图区域-polygon border color
  '--theme-map-cluster-bubble-close': 'rgba(255, 255, 255, 0.9)', // 地图info close color
  '--theme-map-area-donut-operator': `${require('_root/assets/img/areaDonutIcon/donut_operator_1.png')}`, // 地图气泡操作人icon
  '--theme-map-area-donut-car': `${require('_root/assets/img/areaDonutIcon/donut_operation_car_1.png')}`, // 地图气泡操作人icon

  '--theme-trend-down-img': `${require('_root/assets/img/trend_down_blue.png')}`, // 工单实况下降箭头

  '--theme-order-patrol-detail-statistics-effective-count': '#A6E5FF', // 秩序巡视详情统计 有效巡视次数颜色
  '--theme-order-patrol-detail-statistics-nest-ratio': '#3F8DED', // 秩序巡视详情统计 秩序整齐率颜色
}

export default themeMap
