import { brandCodeMap, parkingAreaMap } from '@/utils/consts'
import createClusterBubble from './ClusterBubble'
import drawParkingAreaLayer from './ParkingAreaLayer'

export const drawRegionArea = ({ areaInfo, map }) => {
  const { region } = areaInfo
  if (!region?.length) {
    return
  }
  const laryer = new TMap.MultiPolygon({
    id: 'area-layer',
    map: map,
    styles: {
      polygon: new TMap.PolygonStyle({
        color: 'rgba(22,112,247,0.06)',
        showBorder: true,
        borderColor: 'rgba(22,112,247,1)',
        borderWidth: 2,
        borderDashArray: [3, 3],
      }),
    },
    geometries: [
      {
        id: 'polygon',
        styleId: 'polygon',
        paths: region,
        properties: {
          title: 'polygon',
        },
      },
    ],
  })
  return () => {
    laryer.setMap(null)
  }
}

export const drawBikeArea = ({ map, bikeInfo, dataCenterResource }) => {
  const { bikeLocations = [], spockLocations = [] } = bikeInfo
  let positionsArr = []

  const mt = []
  const qj = []
  const hb = []
  const dh = []
  const qf = []
  const rest = []
  const allBikes = [...bikeLocations, ...spockLocations]

  allBikes.forEach(item => {
    if (item.brandCode === brandCodeMap.mt) {
      mt.push(item)
    } else if (item.brandCode === brandCodeMap.qj) {
      qj.push(item)
    } else if (item.brandCode === brandCodeMap.hb) {
      hb.push(item)
    } else if (item.brandCode === brandCodeMap.dh) {
      dh.push(item)
    } else if (item.brandCode === brandCodeMap.qf) {
      qf.push(item)
    } else {
      rest.push(item)
    }
  })

  positionsArr = [mt, hb, qj, dh, qf, rest]

  return createClusterBubble(map, positionsArr, dataCenterResource)
}

export const drawParkingArea = ({ map, parkingAreaInfo, dataCenterResource }) => {
  const parking = []
  const noParking = []
  const operateArea = []
  const rest = []

  parkingAreaInfo.forEach(item => {
    if (item.areaType === parkingAreaMap.parking) {
      parking.push(item)
    } else if (item.areaType === parkingAreaMap.noParking) {
      noParking.push(item)
    } else if (item.areaType === parkingAreaMap.operateArea) {
      operateArea.push(item)
    } else {
      rest.push(item)
    }
  })

  const info = [parking, noParking, operateArea, rest]

  return drawParkingAreaLayer(map, info, dataCenterResource)
}
