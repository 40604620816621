import { extend, RequestOptionsInit, ResponseError } from 'umi-request'
import debounce from 'lodash/debounce'
import { message as Message } from 'antd'

import api from '@/utils/api'
import { resCodeMap } from '@/utils/consts'
import { lsSetItem } from '@/utils/util'
import _store from '@/redux/store'

interface Result {
  data: ResponseData
  response: Response
}

const extensions: RequestOptionsInit = {
  method: 'post',
  timeout: 30000,
  getResponse: true,
  requestType: 'json',
  credentials: 'include',
}

const umiRequest = extend(extensions)

umiRequest.use(async (ctx, next) => {
  if (_store.getState().app?.userInfo?.isLogout) {
    return
  }
  await next()
})

const successCodes: any[] = [resCodeMap.success]

const debounceMessage = debounce(message => {
  Message.error(message)
}, 400)

const checkLogin = (result: Result) => {
  const { data } = result
  if (data?.code === resCodeMap.unauthorized) {
    lsSetItem('__fallBack__', location.href)
    location.hash = '/login'
  }
  return result
}

const checkSuccess = (result: Result) => {
  const { data } = result
  if (!successCodes.includes(data?.code)) {
    debounceMessage(data.message)
  }
  return result
}

const dataHandler = (result: Result) => {
  const { data } = result
  if (successCodes.includes(data?.code)) {
    return { ...data, code: resCodeMap.success }
  }
  return { ...data }
}

const errorHandler = (error: ResponseError) => {
  if (error.name === 'ResponseError') {
    Message.error(error.message || '网络错误')
  }
  return error
}

const request = (url: string, options: RequestOptionsInit = {}): Promise<ResponseData> => {
  return umiRequest(api[url as keyof typeof api], { ...extensions, ...options })
    .then(checkLogin)
    .then(checkSuccess)
    .then(dataHandler)
    .catch(error => {
      return errorHandler(error)
    })
}

export default request
