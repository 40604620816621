import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Modal, Table, message } from 'antd'
import { createPortal } from 'react-dom'

import request from '@/utils/request'
import { toFixed, getKeyByVal } from '@/utils/util'
import { parkingAreaMap, depositionDegreeMap } from '@/utils/consts'
import {
  parkingIcon,
  streetZoom,
  parkingSpotEditIcon,
  noParkingAreaIcon,
  parkingAreaIcon,
  parkingDepositIcon,
} from '@/pages/dataCenter/center/map/consts'
import { useAppSelector, useUserResources } from '@/utils/hooks'
import ParkingAreaEditModal from './ParkingAreaEditModal'

const allParkingIcons = {
  ...parkingIcon,
  ...noParkingAreaIcon,
  ...parkingAreaIcon,
  ...parkingDepositIcon,
}

const getPolygonStyle = () => {
  return Object.keys(parkingIcon).reduce((prev, next) => {
    prev[next] = new TMap.PolygonStyle({
      color: 'rgba(255, 255, 255, 0.1)',
      showBorder: true,
      borderColor: 'rgba(255, 255, 255, 0.3)',
      borderWidth: 2,
      borderDashArray: next.dashed ? [5, 5] : [0, 0],
    })
    return prev
  }, {})
}

const getMarkerStyles = isSearched => {
  return Object.keys(allParkingIcons).reduce((prev, next) => {
    prev[next] = new TMap.MarkerStyle({
      width: isSearched ? 60 : 40,
      height: isSearched ? 60 : 40,
      anchor: isSearched ? { x: 30, y: 40 } : { x: 20, y: 30 },
      src: allParkingIcons[next].icon,
    })
    return prev
  }, {})
}

const getMarkerStyleId = item => {
  let parkBrandCode = item.parkBrandCode?.toLowerCase()
  const targetKey = Object.keys(allParkingIcons).find(key => key.includes(parkBrandCode))
  if (!targetKey) {
    parkBrandCode = 'default'
  }
  const areaType = +item.areaType
  if (areaType === parkingAreaMap.parking) {
    // 停车点区分是否淤积
    if (item.depositionDegreeType > depositionDegreeMap.normal) {
      return `parking_deposit_${parkBrandCode}`
    } else {
      return `parking_${parkBrandCode}`
    }
  } else if (areaType === parkingAreaMap.noParking) {
    // 禁停区
    return `no_parking_${parkBrandCode}`
  } else {
    // 运营区 取parkingIcon图标 当前运营区图标不分企业
    return getKeyByVal(parkingAreaMap, areaType)
  }
}

const getClassifiedParkingArea = (parkingAreaInfo = []) => {
  const parking = []
  const noParking = []
  const operateArea = []
  const rest = []

  parkingAreaInfo?.forEach(item => {
    if (item.areaType === parkingAreaMap.parking) {
      parking.push(item)
    } else if (item.areaType === parkingAreaMap.noParking) {
      noParking.push(item)
    } else if (item.areaType === parkingAreaMap.operateArea) {
      operateArea.push(item)
    } else {
      rest.push(item)
    }
  })

  return [parking, noParking, operateArea, rest]
}

let infoWindow

const initInfoWindow = map => {
  if (!infoWindow) {
    infoWindow = new TMap.InfoWindow({
      map: map,
      zIndex: 1,
      position: new TMap.LatLng(39.984104, 116.307503),
      offset: { x: 0, y: -32 },
      enableCustom: true,
      content: '<div class="patrol-info-card"></div>',
    })
    infoWindow.close()
  }
}

const ParkingAreaLayer = ({ map, parkingAreaInfo, queryParkingAreaInfo, searchParkingArea }) => {
  const [popupContainer, setPopupContainer] = useState(null)
  const [fenceInfo, setFenceInfo] = useState({})
  const [parkingAreaEditParam, setParkingAreaEditParam] = useState({})
  const queryBikeDetailRef = useRef(false)

  const [app] = useAppSelector(state => [state.app])
  const [manageCenterRs, dataCenterRs] = useUserResources(resource => [
    resource.manageCenter,
    resource.dataCenter,
  ])

  const allParkingCoords = useMemo(
    () => getClassifiedParkingArea(parkingAreaInfo),
    [parkingAreaInfo]
  )

  useEffect(() => {
    if (map) {
      initInfoWindow(map)
    }
  }, [map])

  useEffect(() => {
    return renderParkingArea()
  }, [allParkingCoords])

  const handleMarkerClick = evt => {
    const data = { ...evt.geometry }
    // 停车点显示详情，禁停区不显示
    if (data.fencingId) {
      const position = new TMap.LatLng(evt.geometry.position.lat, evt.geometry.position.lng)
      const popupDiv = '<div id="patrol-area-popup"></div>'
      infoWindow?.setMap(map)
      infoWindow?.setContent(popupDiv)
      infoWindow?.open()
      setPopupContainer(document.getElementById('patrol-area-popup'))
      setFenceInfo(data)
      infoWindow?.setPosition(position)
    }
  }

  const renderParkingArea = () => {
    const polygons = []
    const markers = []
    allParkingCoords.forEach((parkingCoords, i) => {
      const exceedStreetZoom = map.getZoom() >= streetZoom
      const polygon = new TMap.MultiPolygon({
        id: 'parking-layer' + Math.random(),
        map: map,
        styles: getPolygonStyle(),
      })
      const geometries = parkingCoords.map(coordinate => {
        return {
          id: 'polygon',
          styleId: getKeyByVal(parkingAreaMap, coordinate.areaType),
          paths: coordinate.region,
          properties: {
            title: 'polygon',
          },
        }
      })
      if (exceedStreetZoom) {
        polygon.setGeometries(geometries)
      }

      // 添加marker
      const isSearched = parkingCoords.length === 1 && parkingCoords[0].isSearched
      const marker = new TMap.MultiMarker({
        id: 'parking-marker-layer' + Math.random(),
        map: map,
        zIndex: isSearched ? 5 : 1,
        styles: getMarkerStyles(isSearched),
        geometries: parkingCoords.map(item => ({
          ...item,
          id: 'parking-marker' + i,
          styleId: getMarkerStyleId(item),
          position: new TMap.LatLng(item.centerPoint[1], item.centerPoint[0]),
          properties: {
            title: 'marker',
          },
        })),
      })
      marker.on('click', handleMarkerClick)
      markers.push(marker)
      polygons.push(polygon)
    })
    return () => {
      let layers = [polygons, markers]
      layers.map(polygonsMarkers => {
        polygonsMarkers.map(layer => {
          layer.setMap(null)
        })
      })
      if (infoWindow) {
        infoWindow.close()
        infoWindow.setMap(null)
      }
    }
  }

  const handleFenceEdit = param => {
    setParkingAreaEditParam(param)
  }

  const parkingLayerFenceNameEdit = e => {
    e?.stopPropagation()
    handleFenceEdit({ type: 'fence_name', parkingArea: fenceInfo })
  }

  const parkingLayerFenceParkCompanyEdit = e => {
    e?.stopPropagation()
    handleFenceEdit({ type: 'fence_park_company', parkingArea: fenceInfo })
  }

  const parkingLayerFenceCapacityEdit = e => {
    e?.stopPropagation()
    handleFenceEdit({ type: 'fence_capacity', parkingArea: fenceInfo })
  }

  const parkingLayerCloseInfoWindow = () => {
    infoWindow?.close()
    setPopupContainer(null)
  }

  const parkingLayerStopPropagation = e => {
    e?.stopPropagation()
  }

  const showFenceDetailTable = data => {
    const columns = [
      {
        title: '企业品牌',
        dataIndex: 'brandName',
        key: 'brandName',
      },
      {
        title: '车辆编号',
        dataIndex: 'bikeId',
        key: 'bikeId',
      },
      {
        rs: dataCenterRs.showBikeLicenseCode,
        title: '车牌号',
        dataIndex: 'vehicleLicense',
        key: 'vehicleLicense',
      },
      {
        rs: dataCenterRs.showBikeFrameId,
        title: '车架号',
        dataIndex: 'vehicleVin',
        key: 'vehicleVin',
      },
    ].filter(item => item.rs !== null)
    Modal.info({
      icon: null,
      className: 'deposition-count-modal',
      width: 500,
      content: <Table dataSource={data} columns={columns} pagination={false} scroll={{ y: 500 }} />,
    })
  }

  const parkingLayerDepositCountClick = e => {
    e?.stopPropagation()
    if (queryBikeDetailRef.current) {
      return
    }
    queryBikeDetailRef.current = true
    const data = {
      entityId: app.currentEntity?.entityId,
      fencingId: fenceInfo?.fencingId,
    }
    document.getElementById('parkingDepositionCount').innerText = '（加载中...）'
    request('dataCenterQueryPointBikeDetail', { data })
      .then(res => {
        if (res?.code === 0 && res?.data) {
          showFenceDetailTable(res.data)
        } else if (res.code === 0 && !res.data) {
          message.warn('暂无数据')
        }
      })
      .finally(() => {
        queryBikeDetailRef.current = false
        document.getElementById('parkingDepositionCount').innerText = '（点击查看详情）'
      })
  }

  const renderInfoWindowContent = () => {
    const editShow = manageCenterRs.fenceManageUpdate ? '' : 'none'
    return (
      <div
        className="bike-content-info patrol-info-card fz16"
        onMouseMove={parkingLayerStopPropagation}
        onClick={parkingLayerStopPropagation}
        onDoubleClick={parkingLayerStopPropagation}
      >
        <div className="close" onClick={parkingLayerCloseInfoWindow}>
          <svg
            style={{ fill: '#aaa', overflow: 'hidden' }}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M579.888 512l190.064-190.064a48 48 0 0 0-67.888-67.872L512 444.112 321.936 254.064a48 48 0 1 0-67.872 67.872L444.112 512 254.064 702.064a48 48 0 1 0 67.872 67.872L512 579.888l190.064 190.064a48 48 0 0 0 67.872-67.888L579.888 512z"
              fill=""
            ></path>
          </svg>
        </div>
        <div className="triangle"></div>
        <div className="startStart mb12">
          <div style={{ width: 100 }} className="mtFontLight gray mr10">
            停车点ID
          </div>
          <div className="grayFont07">{fenceInfo.fencingId}</div>
        </div>
        <div className="startCenter mb12">
          <div style={{ width: 100 }} className="mtFontLight gray mr10">
            停车点名称
          </div>
          <div style={{ maxWidth: 500 }} className="grayFont07">
            {fenceInfo.fencingName}
          </div>
          <img
            onClick={parkingLayerFenceNameEdit}
            className="pointer ml10"
            style={{ width: 16, display: editShow }}
            src={parkingSpotEditIcon.nameEdit.icon}
          />
        </div>
        <div className="startCenter mb12">
          <div style={{ width: 100 }} className="mtFontLight gray mr10">
            可停品牌
          </div>
          <div className="grayFont07">{fenceInfo.parkBrandName}</div>
          <img
            onClick={parkingLayerFenceParkCompanyEdit}
            className="pointer ml10"
            style={{ width: 16, display: editShow }}
            src={parkingSpotEditIcon.nameEdit.icon}
          />
        </div>
        <div className="startStart mb12">
          <div style={{ width: 100 }} className="mtFontLight gray mr10">
            停车点位置
          </div>
          <div className="grayFont07">
            {toFixed(fenceInfo.position?.lng, 6) + '/' + toFixed(fenceInfo.position?.lat, 6)}
          </div>
        </div>
        <div className="startStart mb12 pointer" onClick={parkingLayerDepositCountClick}>
          <div style={{ width: 100 }} className="mtFontLight gray mr10">
            停放车辆数
          </div>
          <div className="grayFont07">
            {fenceInfo.depositionCount}
            <span className="gray07" id="parkingDepositionCount">
              （点击查看详情）
            </span>
          </div>
        </div>
        <div className="startCenter mb12">
          <div style={{ width: 100 }} className="mtFontLight gray mr10">
            最大容量
          </div>
          <div className="grayFont07">{fenceInfo.fencingCapacity}</div>
          <img
            onClick={parkingLayerFenceCapacityEdit}
            className="pointer ml10"
            style={{ width: 16, display: editShow }}
            src={parkingSpotEditIcon.nameEdit.icon}
          />
        </div>
        <div className="startStart mb12">
          <div style={{ width: 100 }} className="mtFontLight gray mr10">
            淤积程度
          </div>
          <div className="grayFont07">{fenceInfo.depositionName}</div>
        </div>
      </div>
    )
  }

  const handleParkingAreaEditSuccess = param => {
    const { parkingArea } = param
    if (parkingArea.isSearched) {
      searchParkingArea(parkingArea)
    } else {
      queryParkingAreaInfo()
    }
    parkingLayerCloseInfoWindow()
  }

  return (
    <>
      {popupContainer !== null && createPortal(renderInfoWindowContent(), popupContainer)}
      <ParkingAreaEditModal
        param={parkingAreaEditParam}
        onEditSuccess={handleParkingAreaEditSuccess}
      ></ParkingAreaEditModal>
    </>
  )
}

export default memo(ParkingAreaLayer)
