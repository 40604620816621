import React, { useState, useEffect } from 'react'
import { Modal, Table } from 'antd'

import request from '../../utils/request'
import { formatDateShowTime } from '../../utils/util'

import type { ColumnsType } from 'antd/es/table'

type Props = {
  url?: string
  text?: string
  columns?: any[]
  uniqueKey?: string
  extraData?: object
  extraColumns?: ColumnsType
  onCancel?: (...rest: any[]) => void
}

const primaryColumns = [
  {
    title: '操作人',
    dataIndex: 'operatorName',
    key: 'operatorName',
  },
  {
    title: '操作时间',
    dataIndex: 'operationTime',
    key: 'operationTime',
    render(text: any) {
      return formatDateShowTime(text)
    },
  },
  {
    title: '操作内容',
    dataIndex: 'operationTypeName',
    key: 'operationTypeName',
  },
]

const commonUrl = 'commonQueryOperateLog'

const LogModal: React.FC<Props> = ({
  onCancel,
  text = '操作日志',
  uniqueKey = 'id',
  columns = primaryColumns,
  url = commonUrl,
  extraColumns = [],
  extraData = {},
}) => {
  const [open, setOpen] = useState(false)
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [filterData, setFilterData] = useState({ page: 1, pageSize: 20 })

  const queryLogList = (param = {}) => {
    const newParam = Object.assign({}, filterData, param)
    request(url, { data: { ...extraData, ...newParam } }).then(res => {
      if (res.code === 0) {
        setList(res.data?.list || [])
        setTotal(res.data?.total || 0)
        setFilterData(newParam)
      }
    })
  }

  useEffect(() => {
    if (open) {
      queryLogList()
    }
  }, [open])

  const handleCancelClick = () => {
    setOpen(false)
    onCancel?.()
    setFilterData({ page: 1, pageSize: 20 })
  }

  const onPageNo = (page: any, pageSize: any) => {
    queryLogList({ page, pageSize })
  }

  const handleLogClick = () => {
    setOpen(true)
  }

  return (
    <>
      <a className="link" onClick={() => handleLogClick()}>
        {text}
      </a>
      {open ? (
        <Modal
          title="操作日志"
          width={640}
          maskClosable={false}
          open={open}
          bodyStyle={{ maxHeight: 550, overflowY: 'auto' }}
          onCancel={handleCancelClick}
          footer={null}
        >
          <Table
            size="small"
            rowKey={uniqueKey}
            scroll={{ y: 350 }}
            columns={columns.concat(extraColumns)}
            dataSource={list}
            pagination={{
              total,
              size: 'small',
              showSizeChanger: true,
              showQuickJumper: true,
              current: filterData.page,
              pageSize: filterData.pageSize,
              onChange: onPageNo,
              position: ['bottomRight'],
            }}
          />
        </Modal>
      ) : null}
    </>
  )
}

export default LogModal
