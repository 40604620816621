import { stopStatusIcon, exitEnterZoom, stationStatusIcon, orderTypeMap } from './consts'
import { setStopInfoWindow } from './StopAlarmInfoWin'

// 线路
export const drawSubwayLine = ({ map, lineData }) => {
  const pathsArr = []
  const polylineLayer = new TMap.MultiPolyline({
    id: 'line-layer', //图层唯一标识
    map: map, //绘制到目标地图
    //折线样式定义
    styles: {
      style_blue: new TMap.PolylineStyle({
        color: '#0997A4', //线填充色
        width: 4, //折线宽度
        // borderWidth: 1, //边线宽度
        borderColor: '#FFF', //边线颜色
        lineCap: 'butt', //线端头方式
      }),
    },
    geometries: lineData.map(item => {
      const paths = item.geo?.split(';').map(lnglonStr => {
        const arr = lnglonStr.split(',')
        return new TMap.LatLng(arr[1], arr[0])
      })
      pathsArr.push(paths)
      return {
        //第1条线
        id: item.line_id, //折线唯一标识，删除时使用
        styleId: 'style_blue', //绑定样式名
        paths: paths,
      }
    }),
  })
  const latlngBounds = new TMap.LatLngBounds()
  pathsArr.forEach(paths => {
    paths.forEach(point => {
      latlngBounds.extend(point)
    })
  })
  setTimeout(() => {
    map.fitBounds(latlngBounds)
  }, 1000)
  return () => {
    polylineLayer.setMap(null)
  }
}

const getStopStyle = (item, alarmList = []) => {
  const { data_status } = item
  const alarm = alarmList?.find(alarm => alarm.stationId === item.station_id)
  // const isMiddle = middle?.find(station => station.station_id === item.station_id)
  // const isLow = low?.find(station => station.station_id === item.station_id)
  if (alarm) {
    item.orderType = alarm.orderType
  }
  if (data_status === '0') {
    return 'online'
  } else if (data_status === '1') {
    return 'offline'
  } else {
    return 'offline'
  }
}

// 站点
export const drawSubwayStop = ({ map, stopData, alarmList, stationData, onViewAnalysis }) => {
  //创建并初始化MultiMarker
  const defStyle = {
    width: 20, // 点标记样式宽度（像素）
    height: 20, // 点标记样式高度（像素）
    //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
    anchor: { x: 10, y: 10 },
  }
  const markerLayer = new TMap.MultiMarker({
    map: map, //指定地图容器
    //样式定义
    styles: {
      online: new TMap.MarkerStyle({
        ...defStyle,
        src: stopStatusIcon.online.icon, //图片路径
      }),
      offline: new TMap.MarkerStyle({
        ...defStyle,
        src: stopStatusIcon.offline.icon, //图片路径
      }),
    },
    geometries: stopData.map(item => {
      const station = stationData.find(station => station.station_id === item.station_id)
      item.station_name = station?.station_name
      const styleId = getStopStyle(item, alarmList)
      item.infoWinStationOffset = !!item.orderType
      return {
        id: item.stop_id,
        styleId: styleId,
        position: new TMap.LatLng(item.lat, item.lon),
        properties: {
          ...item,
        },
      }
    }),
  })
  const clickHandler = function (evt) {
    const center = evt.geometry.position
    // if (map.getZoom() < exitEnterZoom) {
    // }

    const properties = evt.geometry.properties
    if (properties.orderType) {
      map.easeTo({
        center: new TMap.LatLng(+properties.lat + 0.0035, properties.lon),
        zoom: exitEnterZoom,
      })
      setTimeout(() => {
        setStopInfoWindow(map, evt.geometry, onViewAnalysis)
      }, 200)
    } else {
      map.easeTo({
        center: center,
        zoom: exitEnterZoom,
      })
    }
  }
  markerLayer.on('click', clickHandler)
  return () => {
    markerLayer.off('click', clickHandler)
    markerLayer.setMap(null)
  }
}

const getStationStyle = (station, alarmList) => {
  const alarm = alarmList.find(alarm => alarm.stationId === station.station_id)
  if (alarm) {
    station.orderType = alarm.orderType
    return 'alarm'
  }
  return 'label'
}
// 站台
export const drawSubwayStation = ({ map, stationData, alarmList }) => {
  const defStyle = {
    // label不可自定义字体
    color: '#ffffffe6', //颜色属性
    size: 16, //文字大小属性
    offset: { x: 0, y: -18 }, //文字偏移属性单位为像素
    angle: 0, //文字旋转属性
    alignment: 'center', //文字水平对齐属性
    verticalAlignment: 'middle', //文字垂直对齐属性
  }
  const labelLayer = new TMap.MultiLabel({
    id: 'station-layer',
    map: map, //设置折线图层显示到哪个地图实例中
    //文字标记样式
    styles: {
      label: new TMap.LabelStyle({
        ...defStyle,
      }),
      alarm: new TMap.LabelStyle({
        ...defStyle,
        offset: { x: 0, y: -28 },
      }),
    },
    geometries: stationData.map(item => {
      return {
        id: item.station_id, //点图形数据的标志信息
        styleId: getStationStyle(item, alarmList),
        position: new TMap.LatLng(item.lat, item.lon),
        content: item.station_name, //标注文本
      }
    }),
  })
  return () => {
    labelLayer.setMap(null)
  }
}

// 出入口
export const drawSubwayExitEnter = ({ map, exitEnterData }) => {
  const labelLayer = new TMap.MultiLabel({
    id: 'exitEnter-layer',
    map: map, //设置折线图层显示到哪个地图实例中
    //文字标记样式
    styles: {
      label: new TMap.LabelStyle({
        color: '#49cbd6', //颜色属性
        size: 12, //文字大小属性
        offset: { x: 0, y: 0 }, //文字偏移属性单位为像素
      }),
    },
    //文字标记数据
    geometries: exitEnterData.map(item => {
      return {
        id: item.station_id, //点图形数据的标志信息
        styleId: 'label', //样式id
        position: new TMap.LatLng(item.lat, item.lon), //标注点位置
        content: item.exit_name, //标注文本
      }
    }),
  })
  return () => {
    labelLayer.setMap(null)
  }
}

const getStationStatusStyle = station => {
  if (station.orderType === orderTypeMap.bikeShortage) {
    return 'shortage'
  } else if (station.orderType === orderTypeMap.bikeDeposit) {
    return 'deposit'
  } else {
    return ''
  }
}

// 站点告警状态图标
export const drawStationStatus = ({ map, alarmList, stationData, onViewAnalysis, stopData }) => {
  const defStyle = {
    width: 20, // 点标记样式宽度（像素）
    height: 20, // 点标记样式高度（像素）
    //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
    anchor: { x: 10, y: 20 },
  }
  const markerLayer = new TMap.MultiMarker({
    map: map, //指定地图容器
    //样式定义
    styles: {
      deposit: new TMap.MarkerStyle({
        ...defStyle,
        src: stationStatusIcon.deposit.icon,
      }),
      shortage: new TMap.MarkerStyle({
        ...defStyle,
        src: stationStatusIcon.shortage.icon,
      }),
    },
    geometries: alarmList.map(item => {
      const station = stationData.find(station => station.station_id === item.stationId)
      const styleId = getStationStatusStyle(item)
      return {
        id: station.station_id,
        styleId: styleId,
        position: new TMap.LatLng(station.lat, station.lon),
        properties: {
          ...station,
        },
      }
    }),
  })
  const clickHandler = function (evt) {
    const center = evt.geometry.position
    // if (map.getZoom() < exitEnterZoom) {
    // }
    const properties = evt.geometry.properties
    map.easeTo({
      center: new TMap.LatLng(+properties.lat + 0.0035, properties.lon),
      zoom: exitEnterZoom,
    })
    setTimeout(() => {
      const stop = stopData.find(stop => stop.station_id === evt.geometry.id)
      const opt = { position: center, properties: { ...stop } }
      setStopInfoWindow(map, opt, onViewAnalysis)
    }, 200)
  }
  markerLayer.on('click', clickHandler)
  return () => {
    markerLayer.off('click', clickHandler)
    markerLayer.setMap(null)
  }
}
