/**
 * 角色类型选项框
 */
import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

import request from '@/utils/request'
import { useAppSelector } from '@/utils/hooks'
import { isNullish } from '@/utils/util'

import type { SelectProps } from 'antd/es/select'
import type { FormItemProps } from 'antd/es/form'

export interface RoleSelectProps extends SelectProps {
  formItemProps?: FormItemProps
  childProps?: SelectProps
  extraData?: Record<string, unknown>
}

const RoleTypeSelect: React.FC<RoleSelectProps> = ({
  childProps = {},
  formItemProps = {},
  extraData,
}) => {
  const [app] = useAppSelector(state => [state.app])
  const [options, setOptions] = useState<Options>([])

  const queryList = () => {
    request('commonQueryRoleOpts', {
      data: { entityId: app.currentEntity.entityId, ...extraData },
    }).then(res => {
      if (res.code === 0 && res.data) {
        setOptions(res.data)
      }
    })
  }

  useEffect(() => {
    if (extraData) {
      if (Object.values(extraData).filter(item => !isNullish(item)).length) {
        queryList()
      } else {
        setOptions([])
      }
    } else {
      queryList()
    }
  }, [extraData])

  return (
    <Form.Item {...formItemProps}>
      <Select
        allowClear
        showSearch
        optionFilterProp="label"
        placeholder="请选择"
        options={options}
        {...childProps}
      ></Select>
    </Form.Item>
  )
}

export default RoleTypeSelect
