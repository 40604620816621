import { useRef, useEffect, useMemo } from 'react'
import throttle from 'lodash/throttle'
import * as echarts from 'echarts'

import request from '@/utils/request'
import ContentBox from '../contentBox'
import { useAppSelector, useUserResources, useTimingRequest } from '@/utils/hooks'
import { isNumber } from '@/utils/util'

type EChartsOption = echarts.EChartsOption
const subwayCenterRidingCount = require('_root/mock/subwayCenter/subwayCenterRidingCount.json')

const RideCount = () => {
  const [app] = useAppSelector(state => [state.app])
  const [dataCenterRs] = useUserResources(resource => [resource.dataCenter])
  const containerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<any>(null)

  useTimingRequest(
    () => {
      containerRef.current!.style.height = 50 + subwaySeriesDataMap.enter.length * 60 + 'px'
      chartRef.current = echarts.dispose(containerRef.current as any)
      !chartRef.current && initChart()
      setOption()
    },
    [app],
    10000
  )

  const initChart = () => {
    chartRef.current = echarts.init(containerRef.current as any)
    window.addEventListener(
      'resize',
      throttle(function () {
        chartRef.current?.resize()
      }, 100)
    )
  }

  const subwaySeriesDataMap = useMemo(() => {
    const dataMap = { registerData: [], quotaData: [] as any, brandNames: [], exit: [], enter: [] }
    subwayCenterRidingCount.data?.forEach((item: any) => {
      dataMap.registerData.push({ ...item, value: item.registrationNum } as never)
      dataMap.quotaData.push({ ...item, value: item.quotaNum } as never)
      if (item.key === 'rideOutCount') {
        item.data.forEach((item: any) => {
          dataMap.brandNames.push(item.label as never)
        })
        dataMap.exit = dataMap.exit.concat([], item.data)
      }
      if (item.key === 'rideInCount') dataMap.enter = dataMap.enter.concat([], item.data)
    })
    return dataMap
  }, [subwayCenterRidingCount])

  const validateValue = (val: any) => {
    return isNumber(val) ? val : '--'
  }

  const setOption = () => {
    const option: EChartsOption = {
      title: {
        subtext: '单位(辆)',
        left: 'left',
        bottom: 25,
        subtextStyle: {
          fontSize: '12px',
          color: '#748399',
        },
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(66, 75, 92, 0.2)',
          },
        },
        backgroundColor: '#424B5C',
        className: 'custom-echart-tooltip',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
        formatter: function (params: any) {
          const s1 = params[0] || {}
          const s2 = params[1] || {}
          const s1Display = s1.name ? '' : 'none'
          const s2Display = s2.name ? '' : 'none'
          return `
            <div>
              <span style="display:block;margin-bottom:10px;font-size:14px;">${s1.name}</span>
              <div style="display: ${s1Display};">
                <span style="display:inline-block;margin-right:4px;width:3px;height:8px;background-color: ${
                  s1.color
                };"></span>
                <span style="color: #BCCBE0;">${s1.seriesName}</span>
                <span style="display:inline-block;margin-right:20px;"></span>
                <span style="float:right;font-family: AvenirLight;">
                  ${validateValue(s1.value)}
                </span>
                <span style="display:block;margin-bottom:8px;"></span>
              </div>
              <div style="display:${s2Display}">
                <span style="display:inline-block;margin-right:4px;width:3px;height:8px;background-color:#4a6d70;"></span>
                <span style="color: #BCCBE0;">${s2.seriesName}</span>
                <span style="display:inline-block;margin-right:20px;"></span>
                <span style="float:right;font-family: AvenirLight;">
                  ${validateValue(s2.value)}
                </span>
                <span style="display:block;"></span>
              </div>
            </div>`
        },
      },
      legend: {
        itemWidth: 8,
        itemHeight: 8,
        bottom: 0,
        textStyle: { color: '#BCCBE0' },
        data: [
          {
            name: '骑入量',
            itemStyle: {
              color: subwaySeriesDataMap.quotaData[0]?.color,
            },
          },
          {
            name: '骑出量',
            itemStyle: {
              color: '#4a6d70',
            },
          },
        ],
      },
      grid: {
        top: 'top',
        left: '-6%',
        right: '4%',
        bottom: 30,
        containLabel: true,
      },
      xAxis: [
        {
          type: 'value',
          name: '',
          splitNumber: 4,
          axisLabel: {
            show: true,
            color: '#BCCBE0',
            fontFamily: 'AvenirLight',
            formatter: function (value: any) {
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + '万'
              } else if (value > 10000000) {
                value = value / 10000000 + '千万'
              }
              return value
            },
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(116, 131, 153, 0.4)',
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'category',
          data: subwaySeriesDataMap.brandNames,
          axisLabel: {
            color: '#FFFFFF',
            align: 'center',
            margin: 50,
            padding: [-100, 0, 0, 0],
          },
        },
      ],
      // @ts-ignore
      series: [
        {
          name: '骑入量',
          type: 'bar',
          data: subwaySeriesDataMap.enter,
          itemStyle: {
            color: subwaySeriesDataMap.quotaData[0]?.color,
            borderColor: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
              {
                offset: 0,
                color: '#266373',
              },
              {
                offset: 1,
                color: '#192337',
              },
            ]),
            borderRadius: [8, 2, 2, 2],
          },
          label: {
            show: true,
            position: 'insideRight',
            fontFamily: 'AvenirLight',
          },
          barWidth: 20,
          barGap: 0,
          animationDuration: 3000,
        },
        dataCenterRs.registrationQuotaNum && {
          name: '骑出量',
          type: 'bar',
          symbolSize: [2, 40],
          data: subwaySeriesDataMap.exit,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(25,35,55,0.2)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(35, 79, 88, 0.8)', // 100% 处的颜色
                },
              ],
            },
            borderColor: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
              {
                offset: 0,
                color: '#266373',
              },
              {
                offset: 1,
                color: '#192337',
              },
            ]),
            borderRadius: [0, 2, 2, 0],
          },
          label: {
            show: true,
            position: 'insideRight',
            color: '#BCCBE0',
            fontFamily: 'AvenirLight',
          },
          barWidth: 20,
          animationDuration: 3000,
        },
        {
          type: 'scatter',
          symbolSize: [2, 40],
          symbol: 'rect',
          data: subwaySeriesDataMap.enter,
          itemStyle: {
            color: '#2965FF',
          },
          animationDuration: 3000,
        },
      ].filter(Boolean),
    }
    chartRef.current?.setOption(option)
    setTimeout(() => {
      chartRef.current?.resize()
    }, 3000)
  }

  return (
    <ContentBox
      showTitleDecoration={true}
      title="骑行量"
      style={{ display: dataCenterRs.registration ? '' : 'none' }}
    >
      <div
        ref={containerRef}
        style={{
          width: '100%',
          color: '#fff',
          marginTop: 20,
        }}
      ></div>
    </ContentBox>
  )
}

export default RideCount
