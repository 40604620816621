import React, { useState } from 'react'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

import request from '@/utils/request'

interface Props {
  rawInfo: any
  realInfo?: any
  data?: Record<string, any>
  url?: string
  resource?: boolean
}

const DesensitizeInfo: React.FC<Props> = ({
  rawInfo,
  realInfo,
  data,
  url = 'commonQueryDecryptionResult',
  resource = true,
}) => {
  const [showReal, setShowReal] = useState(false)
  const [info, setInfo] = useState(realInfo)

  const handleEyeClick = () => {
    if (!info) {
      request(url, { data }).then(res => {
        if (res.code === 0) {
          setShowReal(true)
          setInfo(res.data)
        }
      })
    } else {
      setShowReal(true)
    }
  }

  return showReal ? (
    <>
      {info}
      <EyeInvisibleOutlined
        className="ml5"
        onClick={() => setShowReal(false)}
      ></EyeInvisibleOutlined>
    </>
  ) : (
    <>
      {rawInfo}
      {resource && <EyeOutlined className="ml5" onClick={handleEyeClick}></EyeOutlined>}
    </>
  )
}

export default DesensitizeInfo
