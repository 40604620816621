/**
 * 实体tree筛选器
 * 该接口获取当前用户拥有权限的实体，不需要regionCode参数
 */
import React, { useEffect, useState } from 'react'
import { TreeSelect, Form } from 'antd'

import request from '../../utils/request'
import { transformTreeData } from '../../utils/util'

import type { FormItemProps } from 'antd/es/form'
import type { TreeSelectProps } from 'antd/es/tree-select'

export interface EntityTreeSelectProps {
  formItemProps?: FormItemProps
  childProps?: TreeSelectProps
  onQueryDataSuccess?(data: Entity[]): void
}

const EntityTreeSelect: React.FC<EntityTreeSelectProps> = ({
  formItemProps = {},
  childProps = {},
  onQueryDataSuccess,
}) => {
  const [treeData, setTreeData] = useState(childProps?.treeData)

  useEffect(() => {
    if (childProps?.treeData?.length) {
      setTreeData(treeData)
    }
  }, [childProps?.treeData])

  useEffect(() => {
    if (!treeData) {
      getTreeData()
    }
  }, [treeData])

  const getTreeData = () => {
    request('whiteListQueryEntities').then(res => {
      const { data = [], code } = res
      if (code === 0 && data?.length) {
        const treeData = transformTreeData(data as Entity[], item => {
          return { disabled: !item.entityId }
        })
        setTreeData(treeData)
        onQueryDataSuccess?.(treeData)
      }
    })
  }

  return (
    <Form.Item {...formItemProps}>
      <TreeSelect
        allowClear
        showSearch
        treeNodeFilterProp="title"
        placeholder="请选择"
        treeDefaultExpandAll={false}
        treeData={treeData}
        {...childProps}
      />
    </Form.Item>
  )
}

export default EntityTreeSelect
