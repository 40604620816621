import request from '../../utils/request'

export const getUserInfoService = (data: RequestData) => {
  return request('appGetUserInfo', {
    data,
  })
}

export const getUserResourcesService = (data: RequestData) => {
  return request('appGetUserResources', {
    data,
  })
}

export const logoutService = (data: RequestData) => {
  return request('appLogout', {
    data,
  })
}
export const getEntityIconService = (data: RequestData) => {
  return request('appQueryEntityIcon', { data })
}
