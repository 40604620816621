import React, { useState, useEffect } from 'react'

import OrderReality from './orderReality'
import Map from './map'
import ChinaMap from './chinaMap'
import { useAppSelector, useTimingRequest, useUserResources } from '@/utils/hooks'
import request from '@/utils/request'
import { districtLevelMap } from '@/utils/consts'
import { getEleHeightByClassName, listenResize } from '@/utils/util'

export const Context = React.createContext({
  statisticIndicatorHeight: 0,
  realityData: [],
})

const Center = () => {
  const [app] = useAppSelector(state => [state.app])
  const { currentArea: curArea } = app
  const [showMap, setShowMap] = useState(false)
  const [realityData, setRealityData] = useState<any>([])
  const [statisticIndicatorHeight, setStatisticIndicatorHeight] = useState(
    getEleHeightByClassName('order-reality-parent') ?? 0
  )
  useEffect(() => {
    const setHeight = () => {
      const height = getEleHeightByClassName('order-reality-parent') ?? 0
      setStatisticIndicatorHeight(height)
    }
    setHeight()
    return listenResize(() => {
      setHeight()
    })
  }, [realityData])

  const queryOrderReality = () => {
    const data = {
      entityId: app.currentEntity.entityId,
      fencingId: app.currentFence.selectFenceId || app.currentFence.fenceId,
      adCode: app.currentArea.adCode,
    }
    request('patrolCenterActualStatistics', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setRealityData(res.data)
      }
    })
  }

  useTimingRequest(() => {
    if (app.currentEntity.entityId && app.currentArea.adCode && app.currentBiz.businessType) {
      queryOrderReality()
    }
  }, [
    app.currentEntity.entityId,
    app.currentArea.adCode,
    app.currentFence.fenceId,
    app.currentFence.selectFenceId,
  ])

  useEffect(() => {
    // 显示地图有两种情况，一是左上角选择行政区到区以下，todo 这里待确定到市还是区，是否需要后端加个字段
    // 二是下钻到市或区(此时会设置showMap属性)
    if (curArea.level >= districtLevelMap.city || curArea.showMap) {
      setShowMap(true)
    } else {
      setShowMap(false)
    }
  }, [curArea])

  return (
    <Context.Provider value={{ statisticIndicatorHeight, realityData }}>
      <div className="columnStartStretch relative" style={{ margin: '0 12px', height: '100%' }}>
        <div style={{ position: 'relative' }}>
          <OrderReality></OrderReality>
        </div>
        {showMap ? <Map></Map> : <ChinaMap></ChinaMap>}
      </div>
    </Context.Provider>
  )
}

export default Center
