import { useEffect, useMemo, useState } from 'react'
import { Carousel, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import { useUserResources } from '@/utils/hooks'
import ContentBox from '../contentBox'

const subwayRealOrderWork = require('_root/mock/subwayCenter/subwayRealOrderWork.json')

const { Text } = Typography

const disposeStatus: any = {
  1: '处理中',
  2: '待处理',
  3: '已完成',
}

const disposeStatusColor: any = {
  1: '#CC3131',
  2: '#E0812D',
  3: '#07A75A',
}

const mockImg1 = require('_root/assets/img/mockImg/1699425918603.jpg')
const mockImg2 = require('_root/assets/img/mockImg/1699426258962.jpg')
const mockImg3 = require('_root/assets/img/mockImg/1699426333271.jpg')
const mockImg4 = require('_root/assets/img/mockImg/1699426392701 (1).jpg')
const mockImg5 = require('_root/assets/img/mockImg/1699426392701.jpg')
const mockImg6 = require('_root/assets/img/mockImg/1699427353508tiny-739-2023-11-08-15-08-25.jpg')
const mockImg7 = require('_root/assets/img/mockImg/1699427546254tiny-434-2023-11-08-15-11-38.jpg')
const mockImg8 = require('_root/assets/img/mockImg/image-11b39c1a-23bb-48c0-83b2-f9abda0fbd7e1699427618674.jpeg')
const mockImg9 = require('_root/assets/img/mockImg/image-93d78f94-4dde-45a9-b079-5ca2562810fc1699427569663.jpeg')
const mockImg10 = require('_root/assets/img/mockImg/image-01756421-d605-4ca1-9550-fc8ecbbb51731699427575187.jpeg')
const mockImg11 = require('_root/assets/img/mockImg/1699426334881.jpg')

const ImgArr = [
  mockImg1,
  mockImg2,
  mockImg3,
  mockImg4,
  mockImg5,
  mockImg6,
  mockImg7,
  mockImg8,
  mockImg9,
  mockImg10,
  mockImg11,
]
const RealOrderWork = () => {
  const history = useHistory()
  const [dataCenterRs] = useUserResources(resource => [resource.dataCenter])
  const [sliceData, setSliceData] = useState<any[]>([])
  const carouselDiv = (content: any) => {
    return (
      <div>
        {content.map((item: any) => {
          return (
            <div key={item.id} className="contentStyle betweenCenter">
              <span
                className="squareTips"
                style={{ background: disposeStatusColor[item.orderStatus] }}
              ></span>
              <span
                className="contentStatus"
                style={{ color: disposeStatusColor[item.orderStatus] }}
              >
                {disposeStatus[item.orderStatus]}
              </span>
              <span className="contentName">{item.companyName}</span>
              <span className="contentName">{item.content}</span>
              <span className="contentSubwayName">
                <Text className="white" ellipsis={{ tooltip: item.stationName }}>
                  {item.stationName}
                </Text>
              </span>
              <span className="contentTime">{item.complainTime}</span>
            </div>
          )
        })}
      </div>
    )
  }

  const bannerImgDiv = (content: any, img: string) => {
    return (
      <div className="bannerContent">
        <div className="relative pointer" onClick={() => history.push('/manageCenter/taskCenter')}>
          <img className="bannerContentImg" src={img} alt="" />
          <div className="bannerContentTime">{content.complainTime}</div>
        </div>
        <span className="bannerSmall"></span>
        <div className="bannerContentText">{content.userName}</div>
        <div className="bannerContentText">{content.stationName}</div>
      </div>
    )
  }
  const bannerTransferDiv = (contentArr: any, ind: number) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {contentArr.map((item: any, index: number) => {
          return bannerImgDiv(item, ImgArr[index + ind])
        })}
      </div>
    )
  }

  const orderList = useMemo(() => {
    const result = []
    let tempArr = []
    for (let i = 0; i < subwayRealOrderWork?.data?.orderList.length; i++) {
      tempArr.push(subwayRealOrderWork?.data?.orderList[i])
      if (tempArr.length === 3 || i === subwayRealOrderWork?.data?.orderList.length - 1) {
        result.push(tempArr)
        tempArr = []
      }
    }
    return result
  }, [subwayRealOrderWork])
  useEffect(() => {
    const completeOrderList = subwayRealOrderWork?.data?.completeOrderList || []
    const orderList = []
    for (let i = 0; i < completeOrderList.length; i += 2) {
      orderList.push([completeOrderList[i], completeOrderList[i + 1]])
    }
    setSliceData(orderList)
  }, [subwayRealOrderWork])
  return (
    <ContentBox
      title="实时工单"
      style={{ display: dataCenterRs.registration ? '' : 'none', marginTop: '4px' }}
    >
      <div className="mt20">
        <Carousel dotPosition="left" autoplay dots={false}>
          {orderList?.map((item: any) => {
            return carouselDiv(item)
          })}
        </Carousel>
      </div>
      <div className="mt10">
        <Carousel dotPosition="left" autoplay dots={false}>
          {sliceData.map((item: any, index) => {
            return <>{bannerTransferDiv(item, index)}</>
          })}
        </Carousel>
      </div>
    </ContentBox>
  )
}

export default RealOrderWork
