import cssVars from 'css-vars-ponyfill'
import { themeMap, mapThemeMap } from '@/pages/dataCenter/theme'

export const setTheme = (themeName: string, mapThemeName: string) => {
  document.documentElement.setAttribute('data-theme', themeName)
  const theme = themeMap[themeName as keyof typeof themeMap].css
  const mapTheme = mapThemeMap[mapThemeName as keyof typeof mapThemeMap].css
  const _theme = {
    ...theme,
    ...mapTheme,
  }
  // console.warn(theme, mapTheme, _theme)
  cssVars({
    watch: true,
    variables: _theme,
    onlyLegacy: false,
  })
}
