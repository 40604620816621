import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AppState {
  fullScreen: boolean
  windowResize: boolean
  loginResData: { token?: string }
  userInfo: UserInfo
  userResources: UserResources
  currentEntity: Entity
  currentArea: AreaData
  currentBiz: { businessType: BusinessType }
  entityIcon: string
  currentFence: {
    fenceId?: string | undefined
    selectFenceId?: string | undefined
  }
}

const initialState: AppState = {
  fullScreen: false,
  windowResize: false,
  loginResData: {} as { token: string },
  userInfo: {} as UserInfo,
  userResources: [] as UserResources,
  currentEntity: {} as Entity,
  currentArea: {} as AreaData,
  currentBiz: {} as { businessType: BusinessType },
  entityIcon: '',
  currentFence: {},
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    loginResData: (state, action: PayloadAction<{ token: string }>) => {
      state.loginResData = action.payload
    },
    userInfo: (state, action) => {
      state.userInfo = action.payload
    },
    userResources: (state, action) => {
      state.userResources = action.payload
    },
    currentEntity: (state, action: PayloadAction<Entity>) => {
      state.currentEntity = action.payload
    },
    currentArea: (state, action: PayloadAction<AreaData>) => {
      state.currentArea = action.payload
    },
    currentFence: (state, action: PayloadAction<AppState['currentFence']>) => {
      state.currentFence = { ...state.currentFence, ...action.payload }
    },
    currentBiz: (state, action: PayloadAction<{ businessType: BusinessType }>) => {
      state.currentBiz = action.payload
    },
    entityIcon: (state, action: PayloadAction<string>) => {
      state.entityIcon = action.payload
    },
    windowResize: state => {
      state.windowResize = !state.windowResize
    },
    updateFullScreen: (state, action: PayloadAction<boolean>) => {
      state.fullScreen = action.payload
    },
  },
})

export const {
  loginResData,
  userInfo,
  userResources,
  currentEntity,
  entityIcon,
  currentArea,
  currentBiz,
  currentFence,
  windowResize,
  updateFullScreen,
} = appSlice.actions

export default appSlice.reducer
