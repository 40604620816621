/**
 * 区域tree筛选器
 */
import React, { useEffect, useState } from 'react'
import { Form, TreeSelect } from 'antd'

import request from '@/utils/request'
import { transformArea } from '@/utils/util'
import { useAppSelector } from '@/utils/hooks'

import type { FormItemProps } from 'antd/es/form'
import type { TreeSelectProps } from 'antd/es/tree-select'

export interface RegionTreeSelectProps {
  formItemProps?: FormItemProps
  childProps?: TreeSelectProps
  onQueryDataSuccess?(data: TreeData): void
}

const RegionTreeSelect: React.FC<RegionTreeSelectProps> = ({
  formItemProps = {},
  childProps = {},
  onQueryDataSuccess,
}) => {
  const [app] = useAppSelector(state => [state.app])

  const [key, setKey] = useState(0)
  const [treeData, setTreeData] = useState<DataNode[]>([])

  useEffect(() => {
    if (childProps.treeData) {
      setKey(key => key + 1)
      setTreeData(childProps.treeData as DataNode[])
    }
  }, [childProps.treeData])

  useEffect(() => {
    if (!treeData?.length && !childProps.treeData) {
      queryRegions()
    }
  }, [treeData, childProps.treeData])

  const queryRegions = () => {
    const data = { entityId: app.currentEntity.entityId }
    request('commonQueryUserRegion', { data }).then(res => {
      if (res.code === 0 && res.data) {
        const regions = transformArea(res.data, node => ({ isLeaf: !node.hasChildren }))
        setTreeData(regions)
        onQueryDataSuccess?.(res.data)
      }
    })
  }

  return (
    <Form.Item {...formItemProps}>
      <TreeSelect
        showSearch
        allowClear
        key={key}
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        placeholder="请选择"
        treeNodeFilterProp="title"
        treeDefaultExpandAll={false}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        {...childProps}
        treeData={treeData}
      />
    </Form.Item>
  )
}

export default RegionTreeSelect
