import { districtLevelMap } from '@/utils/consts'

export const minZoom = 5
export const cityZoom = 10
export const areaZoom = 12
export const streetZoom = 14
export const bikeZoom = 16
export const stationZoom = 14
export const exitEnterZoom = 16

export const cityAreaLevel = [districtLevelMap.city, districtLevelMap.area]

export const iconMap = {
  mt_bike_riding: { label: '美团单车骑行', icon: require('_root/assets/img/mt_bike_riding.png') },
  mt_bike_parking: { label: '美团单车停放', icon: require('_root/assets/img/mt_bike_parking.png') },
  mt_spock_riding: {
    label: '美团电单车骑行',
    icon: require('_root/assets/img/mt_spock_riding.png'),
  },
  mt_spock_parking: {
    label: '美团电单车停放',
    icon: require('_root/assets/img/mt_spock_parking.png'),
  },
  hb_bike_riding: {
    label: '哈啰单车骑行',
    icon: require('_root/assets/img/hb_bike_riding.png'),
  },
  hb_bike_parking: {
    label: '哈啰单车停放',
    icon: require('_root/assets/img/hb_bike_parking.png'),
  },
  hb_spock_riding: {
    label: '哈啰电单车骑行',
    icon: require('_root/assets/img/hb_spock_riding.png'),
  },
  hb_spock_parking: {
    label: '哈啰电单车停放',
    icon: require('_root/assets/img/hb_spock_parking.png'),
  },
  qj_bike_riding: {
    label: '青桔单车骑行',
    icon: require('_root/assets/img/qj_bike_riding.png'),
  },
  qj_bike_parking: {
    label: '青桔单车停放',
    icon: require('_root/assets/img/qj_bike_parking.png'),
  },
  qj_spock_riding: {
    label: '青桔电单车骑行',
    icon: require('_root/assets/img/qj_spock_riding.png'),
  },
  qj_spock_parking: {
    label: '青桔电单车停放',
    icon: require('_root/assets/img/qj_spock_parking.png'),
  },
  dh_bike_riding: { label: '大哈单车骑行', icon: require('_root/assets/img/dh_bike_riding.png') },
  dh_bike_parking: { label: '大哈单车停放', icon: require('_root/assets/img/dh_bike_parking.png') },
  dh_spock_riding: {
    label: '大哈电单车骑行',
    icon: require('_root/assets/img/dh_spock_riding.png'),
  },
  dh_spock_parking: {
    label: '大哈电单车停放',
    icon: require('_root/assets/img/dh_spock_parking.png'),
  },
  qf_bike_riding: { label: '骑福单车骑行', icon: require('_root/assets/img/qf_bike_riding.png') },
  qf_bike_parking: { label: '骑福单车停放', icon: require('_root/assets/img/qf_bike_parking.png') },
  qf_spock_riding: {
    label: '骑福电单车骑行',
    icon: require('_root/assets/img/qf_spock_riding.png'),
  },
  qf_spock_parking: {
    label: '骑福电单车停放',
    icon: require('_root/assets/img/qf_spock_parking.png'),
  },
}

export const parkingIcon = {
  parking: {
    label: '停车点',
    icon: require('_root/assets/img/parking_spot.png'),
    color: 'rgba(43, 96, 255, 0.06)',
    border: 'rgba(43, 96, 255, 1)',
  },
  operateArea: {
    label: '运营区',
    icon: require('_root/assets/img/operation_area.png'),
    color: 'rgba(43, 96, 255, 0.06)',
    border: 'rgba(43, 96, 255, 1)',
  },
  noParking: {
    label: '禁停区',
    icon: require('_root/assets/img/no_parking_spot.png'),
    color: 'rgba(255, 41, 59, 0.06)',
    border: 'rgba(255, 41, 59, 1)',
    dashed: true,
  },
}

export const operationIcon = {
  operator: { label: '运维人员', icon: require('_root/assets/img/operator.png') },
  opration_car: { label: '运维车辆', icon: require('_root/assets/img/opration_car.png') },
}

export const stopStatusIcon = {
  online: { label: '运营中', icon: require('_root/assets/img/stop_online.png') },
  offline: { label: '未运营', icon: require('_root/assets/img/stop_offline.png') },
}

export const stationStatusIcon = {
  deposit: { label: '车辆淤积', icon: require('_root/assets/img/station_deposit.png') },
  shortage: { label: '车辆不足', icon: require('_root/assets/img/station_shortage.png') },
}

export const orderTypeMap = {
  bikeShortage: 1, // 车辆不足
  bikeDeposit: 2, // 车辆淤积
}
