import { useEffect, useRef } from 'react'
import { Modal, message, Form, Input, Radio, Select } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import request from '@/utils/request'
import { trimFormInput } from '@/utils/util'
import { regExpMap, fenceCapacitySourceMap } from '@/utils/consts'
import { useAppSelector } from '@/utils/hooks'

import type { ParkingArea } from '@/redux/reducers/dataCenter'

export interface ParkingAreaEditParam {
  type: ValueOf<typeof editType>
  parkingArea: ParkingArea
}

interface ParkingAreaEditModalProps {
  param: ParkingAreaEditParam
  onEditSuccess: (param: ParkingAreaEditParam) => void
}

const editType = {
  name: 'fence_name',
  capacity: 'fence_capacity',
  company: 'fence_park_company',
}

const ParkingAreaEditModal: React.FC<ParkingAreaEditModalProps> = ({ param, onEditSuccess }) => {
  const [form] = Form.useForm()
  const [app] = useAppSelector(state => [state.app])
  const isQueryCompanyOptsRef = useRef(false)
  const isQuerySystemCapacityRef = useRef(false)

  const { type, parkingArea } = param

  useEffect(() => {
    form.resetFields()
    if (type === editType.name) {
      showName()
    } else if (type === editType.capacity) {
      querySystemCapacity().then(data => showCapacity(data))
    } else if (type === editType.company) {
      if (!isQueryCompanyOptsRef.current) {
        queryParkCompanyOpts().then(data => showParkCompany(data || []))
      }
    }
  }, [param])

  const querySystemCapacity = () => {
    const data = {
      entityId: app.currentEntity.entityId,
      fencingGeoJson: parkingArea.rawRegion,
    }
    isQuerySystemCapacityRef.current = true
    return request('fenceManageCalculateCapacity', { data })
      .then(res => {
        if (res.code === 0) {
          return res.data
        }
      })
      .finally(() => {
        isQuerySystemCapacityRef.current = false
      })
  }

  const queryParkCompanyOpts = () => {
    const data = {
      entityId: app.currentEntity.entityId,
    }
    isQueryCompanyOptsRef.current = true
    return request('commonQueryParkCompanyOpts', { data })
      .then(res => {
        if (res.code === 0 && res.data) {
          return res.data
        }
      })
      .finally(() => {
        isQueryCompanyOptsRef.current = false
      })
  }

  const showName = () => {
    Modal.confirm({
      closable: true,
      closeIcon: <CloseOutlined style={{ color: '#BCCBE0', fontSize: 18 }} />,
      icon: null,
      className: 'parkingLayer-fence-edit-modal',
      onOk: close => {
        const fencingName = form.getFieldValue('fenceNameEditInput')
        if (!fencingName) {
          message.info('请输入围栏名称')
          return
        }
        if (fencingName.length > 100) {
          message.info('请输入不超过100个字符')
          return
        }
        const data = {
          fencingName: fencingName,
          entityId: app.currentEntity?.entityId,
          fencingId: parkingArea?.fencingId,
        }
        return request('dataCenterEditFence', { data }).then(res => {
          if (res.code === 0) {
            message.success('修改成功')
            close()
            onEditSuccess(param)
          }
        })
      },
      content: (
        <Form form={form} layout="vertical">
          <Form.Item
            label={<span style={{ color: '#fff', fontSize: 20, marginBottom: 6 }}>停车点名称</span>}
            name="fenceNameEditInput"
            initialValue={parkingArea?.fencingName}
            getValueFromEvent={trimFormInput}
          >
            <Input
              autoComplete="off"
              ref={input => {
                setTimeout(() => {
                  input?.focus()
                })
              }}
            ></Input>
          </Form.Item>
        </Form>
      ),
    })
  }

  const showCapacity = (capacity: number) => {
    Modal.confirm({
      closable: true,
      closeIcon: <CloseOutlined style={{ color: '#BCCBE0', fontSize: 18 }} />,
      icon: null,
      width: 450,
      className: 'parkingLayer-fence-edit-modal',
      onOk: close => {
        const { fencingCapacitySource, fencingCapacity } = form.getFieldsValue()
        if (!fencingCapacitySource) {
          message.info('请选择')
          return
        }
        if (fencingCapacitySource === fenceCapacitySourceMap.manual && !fencingCapacity) {
          message.info('请填写围栏容量')
          return
        }
        if (
          fencingCapacitySource === fenceCapacitySourceMap.manual &&
          !regExpMap.posIntAndZero.test(fencingCapacity)
        ) {
          message.info('请填写数字')
          return
        }
        if (+fencingCapacity > 10000) {
          message.info('不可超过10000')
          return
        }
        const data = {
          fencingCapacitySource,
          fencingCapacity:
            fencingCapacitySource === fenceCapacitySourceMap.manual ? fencingCapacity : capacity,
          entityId: app.currentEntity.entityId,
          fencingId: parkingArea?.fencingId,
        }
        return request('dataCenterEditFence', { data }).then(res => {
          if (res.code === 0) {
            message.success('修改成功')
            close()
            onEditSuccess(param)
          }
        })
      },
      content: (
        <Form form={form} layout="vertical">
          <Form.Item
            label={<span style={{ color: '#fff', fontSize: 20, marginBottom: 6 }}>停车点容量</span>}
            name="fencingCapacitySource"
            initialValue={parkingArea?.fencingCapacitySource}
          >
            <Radio.Group className="fence-edit-capacity-radio">
              <Radio value={fenceCapacitySourceMap.system}>系统自动计算{`（${capacity}）`}</Radio>
              <Radio value={fenceCapacitySourceMap.manual}>
                <span style={{ display: 'inline-block', lineHeight: '32px' }}>自行维护</span>
                <Form.Item noStyle dependencies={['fencingCapacitySource']}>
                  {({ getFieldValue }) =>
                    getFieldValue('fencingCapacitySource') === fenceCapacitySourceMap.manual ? (
                      <Form.Item
                        name="fencingCapacity"
                        style={{
                          display: 'inline-block',
                          marginBottom: 0,
                          marginLeft: 12,
                        }}
                        initialValue={
                          parkingArea.fencingCapacitySource === fenceCapacitySourceMap.manual
                            ? parkingArea?.fencingCapacity
                            : undefined
                        }
                      >
                        <Input
                          style={{ width: 100 }}
                          autoComplete="off"
                          ref={input => {
                            setTimeout(() => {
                              input?.focus()
                            })
                          }}
                        ></Input>
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      ),
    })
  }

  const showParkCompany = (parkCompanyOpts = []) => {
    Modal.confirm({
      closable: true,
      closeIcon: <CloseOutlined style={{ color: '#BCCBE0', fontSize: 18 }} />,
      icon: null,
      className: 'parkingLayer-fence-edit-modal',
      onOk: close => {
        const parkCompanyId = form.getFieldValue('parkCompanyId')
        if (!parkCompanyId) {
          message.info('请选择')
          return
        }
        const data = {
          parkCompanyId,
          entityId: app.currentEntity?.entityId,
          fencingId: parkingArea?.fencingId,
        }
        return request('dataCenterEditFence', { data }).then(res => {
          if (res.code === 0) {
            message.success('修改成功')
            close()
            onEditSuccess(param)
          }
        })
      },
      content: (
        <Form form={form} layout="vertical">
          <Form.Item
            label={<span style={{ color: '#fff', fontSize: 20, marginBottom: 6 }}>可停品牌</span>}
            name="parkCompanyId"
            rules={[{ required: true }]}
            initialValue={parkingArea.parkCompanyId}
          >
            <Select
              className="custom-select park-area-edit-custom-select"
              popupClassName="custom-select-dropdown"
              options={parkCompanyOpts}
            ></Select>
          </Form.Item>
        </Form>
      ),
    })
  }

  return null
}

export default ParkingAreaEditModal
