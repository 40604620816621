import { useState, useRef, useEffect, useMemo } from 'react'
import throttle from 'lodash/throttle'
import * as echarts from 'echarts'

import ContentBox from '../contentBox'
import { useAppSelector, useUserResources, useTimingRequest } from '@/utils/hooks'
import { isNumber } from '@/utils/util'

type EChartsOption = echarts.EChartsOption
const subwayViolationStatistics = require('_root/mock/subwayCenter/subwayViolationStatistics.json')

const OrderStatistics = () => {
  const [app] = useAppSelector(state => [state.app])
  const [dataCenterRs] = useUserResources(resource => [resource.dataCenter])
  const containerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<any>(null)
  const [curBtn, setCurBtn] = useState<string>('parkingLackBike')

  useTimingRequest(
    () => {
      containerRef.current!.style.height =
        50 + subwayViolationStatisticsDataMap.brandNames.length * 50 + 'px'
      chartRef.current = echarts.dispose(containerRef.current as any)
      !chartRef.current && initChart()
      setOption()
    },
    [app, curBtn],
    10000
  )

  const initChart = () => {
    chartRef.current = echarts.init(containerRef.current as any)
    window.addEventListener(
      'resize',
      throttle(function () {
        chartRef.current?.resize()
      }, 100)
    )
  }

  const subwayViolationStatisticsDataMap = useMemo(() => {
    const dataMap = {
      brandNames: [],
      gross: [] as any,
      completedGross: [] as any,
      subwayViolationStatistics: [],
      parkingLackBike: [],
      parkingDeposit: [],
      statisticsList: [],
    }
    dataMap.parkingLackBike.push(subwayViolationStatistics.data.parkingLackBike as never)
    dataMap.parkingDeposit.push(subwayViolationStatistics.data.parkingDeposit as never)
    dataMap.statisticsList.push(subwayViolationStatistics.data.parkingLackBike as never)
    dataMap.statisticsList.push(subwayViolationStatistics.data.parkingDeposit as never)
    subwayViolationStatistics.data[curBtn].data.forEach((item: any) => {
      if (item.key === 'performanceOrder') {
        dataMap.completedGross.push(item)
        item.data.forEach((ite: any) => {
          dataMap.brandNames.push(ite.label as never)
        })
      }
      if (item.key === 'grossOrder') {
        dataMap.gross.push(item as never)
      }
    })
    return dataMap
  }, [subwayViolationStatistics, curBtn])

  const validateValue = (val: any) => {
    return isNumber(val) ? val : '--'
  }

  const setOption = () => {
    const option: EChartsOption = {
      title: {
        subtext: '单位(辆)',
        left: 'left',
        bottom: 25,
        subtextStyle: {
          fontSize: '12px',
          color: '#748399',
        },
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(66, 75, 92, 0.2)',
          },
        },
        backgroundColor: '#424B5C',
        className: 'custom-echart-tooltip',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
        formatter: function (params: any) {
          const s1 = params[0] || {}
          const s2 = params[1] || {}
          const s1Display = s1.name ? '' : 'none'
          const s2Display = s2.name ? '' : 'none'
          return `
            <div>
              <span style="display:block;margin-bottom:10px;font-size:14px;">${s1.name}</span>
              <div style="display: ${s1Display};">
                <span style="display:inline-block;margin-right:4px;width:3px;height:8px;background-color: 
            ${s1.color};"></span>
                <span style="color: #BCCBE0;">${s1.seriesName}</span>
                <span style="display:inline-block;margin-right:20px;"></span>
                <span style="float:right;font-family: AvenirLight;">
                  ${validateValue(s1.value)}
                </span>
                <span style="display:block;margin-bottom:8px;"></span>
              </div>
              <div style="display: ${s2Display}">
                <span style="display:inline-block;margin-right:4px;width:3px;height:8px;background-color: 
            ${s2.color};"></span>
                <span style="color: #BCCBE0;">${s2.seriesName}</span>
                <span style="display:inline-block;margin-right:20px;"></span>
                <span style="float:right;font-family: AvenirLight;">
                  ${validateValue(s2.value)}
                </span>
                <span style="display:block;"></span>
              </div>
           </div>`
        },
      },
      legend: {
        itemWidth: 8,
        itemHeight: 8,
        bottom: 0,
        textStyle: { color: '#BCCBE0' },
        data: [
          {
            name: '完成工单量',
            itemStyle: {
              color: subwayViolationStatisticsDataMap.completedGross[0].color,
            },
          },
          {
            name: '工单总量',
            itemStyle: {
              color: subwayViolationStatisticsDataMap.gross[0].color + '60',
            },
          },
        ],
      },
      grid: {
        top: 'top',
        left: '-3%',
        right: '4%',
        bottom: 30,
        containLabel: true,
      },
      xAxis: [
        {
          type: 'value',
          name: '',
          splitNumber: 4,
          axisLabel: {
            show: true,
            color: '#BCCBE0',
            fontFamily: 'AvenirLight',
            formatter: function (value: any) {
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + '万'
              } else if (value > 10000000) {
                value = value / 10000000 + '千万'
              }
              return value
            },
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(116, 131, 153, 0.4)',
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'category',
          data: subwayViolationStatisticsDataMap.brandNames,
          axisLabel: {
            color: '#FFFFFF',
            align: 'center',
            margin: 50,
            padding: [-100, 0, 0, 0],
          },
        },
      ],
      // @ts-ignore
      series: [
        {
          name: '完成工单量',
          type: 'bar',
          data: subwayViolationStatisticsDataMap.completedGross[0].data,
          // stack: 'total',
          itemStyle: {
            borderRadius: [8, 2, 2, 2],
            color: subwayViolationStatisticsDataMap.completedGross[0].color,
          },
          label: {
            show: true,
            position: 'insideRight',
            fontFamily: 'AvenirLight',
            color: '#fff',
          },
          barWidth: 20,
          animationDuration: 3000,
          barGap: '-100%',
          z: 2,
        },
        dataCenterRs.registrationQuotaNum && {
          name: '工单总量',
          // stack: 'total',
          type: 'bar',
          data: subwayViolationStatisticsDataMap.gross[0].data,
          itemStyle: {
            borderRadius: 2,
            color: subwayViolationStatisticsDataMap.gross[0].color + '20',
          },
          label: {
            show: true,
            position: 'insideRight',
            color: '#BCCBE0',
            fontFamily: 'AvenirLight',
          },
          barWidth: 20,
          z: 1,
          animationDuration: 3000,
        },
      ].filter(Boolean),
    }
    chartRef.current?.setOption(option)
    setTimeout(() => {
      chartRef.current?.resize()
    }, 3000)
  }

  const renderBtns = () => {
    const btns = Object.values(subwayViolationStatisticsDataMap.statisticsList).map(
      (item: { key: string; name: string }) => ({
        id: item.key,
        name: item.name,
      })
    )

    return (
      <div
        className="mt20 mb20 betweenCenter violate-btns"
        style={{
          background: 'rgba(255, 255, 255, 0.04)',
          borderRadius: '8px',
          width: '158px',
          color: 'rgba(188, 203, 224, 1)',
          position: 'absolute',
          top: '-10px',
          right: '22px',
        }}
      >
        {btns.map(item => (
          <div
            key={item.id}
            className="pointer"
            style={
              curBtn === item.id
                ? {
                    background: 'rgba(255, 255, 255, 0.08)',
                    borderRadius: 6,
                    padding: '5px 8px',
                    color: '#00E0E0',
                    fontWeight: 500,
                  }
                : { padding: '5px 8px' }
            }
            onClick={() => setCurBtn(item.id)}
          >
            {item.name}
          </div>
        ))}
      </div>
    )
  }

  return (
    <ContentBox
      title="工单统计"
      style={{
        display: dataCenterRs.registration ? '' : 'none',
        marginTop: '4px',
        width: '100%',
      }}
    >
      {renderBtns()}
      <div
        ref={containerRef}
        style={{
          width: '100%',
          color: '#fff',
          marginTop: 20,
        }}
      ></div>
    </ContentBox>
  )
}

export default OrderStatistics
