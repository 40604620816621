import { AimOutlined } from '@ant-design/icons'

import { useAppSelector } from '@/utils/hooks'

import './index.less'

interface Props {
  map: any
  zoom?: number
  center?: LngLat
  className?: string
  style?: React.CSSProperties
  iconStyle?: React.CSSProperties
}

const MapPoiReset: React.FC<Props> = ({
  map,
  zoom = 10,
  center,
  className = '',
  style = {},
  iconStyle = {},
}) => {
  const [app] = useAppSelector(state => [state.app])

  const handlePosClick = () => {
    let centerPoint = new TMap.LatLng(
      app.currentArea?.lat || 39.910993,
      app.currentArea?.lng || 116.397617
    )
    if (center?.length) {
      centerPoint = new TMap.LatLng(center[1], center[0])
    }
    map.setZoom(zoom)
    setTimeout(() => map.setCenter(centerPoint), 0)
  }

  return (
    <div
      className={`map-poi-reset pointer centerCenter ${className}`}
      style={{ ...style }}
      title="返回初始地图范围"
      onClick={handlePosClick}
    >
      <AimOutlined style={{ fontSize: 20, ...iconStyle }}></AimOutlined>
    </div>
  )
}

export default MapPoiReset
