import { useState, useRef, useEffect } from 'react'
import throttle from 'lodash/throttle'
import * as echarts from 'echarts'

import request from '@/utils/request'
import ContentBox from '../contentBox'
import { useAppSelector, useTimingRequest } from '@/utils/hooks'
import { isNumber } from '@/utils/util'

type EChartsOption = echarts.EChartsOption

const PassengerCount = () => {
  const [app] = useAppSelector(state => [state.app])
  const containerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<any>(null)

  useTimingRequest(
    () => {
      if (app.currentEntity.entityId) {
        queryRegistrationAndQuota()
      }
    },
    [app.currentEntity.entityId],
    10000
  )

  const initChart = () => {
    chartRef.current = echarts.init(containerRef.current as any)
    window.addEventListener(
      'resize',
      throttle(function () {
        chartRef.current?.resize()
      }, 100)
    )
  }

  const queryRegistrationAndQuota = () => {
    const subwayCenterPassengerCount = require('_root/mock/subwayCenter/subwayCenterPassengerCount.json')
    if (containerRef.current) {
      containerRef.current!.style.height = '240px'
    }
    chartRef.current = echarts.dispose(containerRef.current as any)
    !chartRef.current && initChart()
    const seriesDataMap: any = {
      legendData: [],
      seriesData: [],
      registerData: [],
      quotaData: [],
      brandNames: [],
    }
    setOption(subwayCenterPassengerCount.data || [], seriesDataMap)
  }

  const validateValue = (val: any) => {
    return isNumber(val) ? val : '--'
  }

  const setOption = (data: any, seriesDataMap: any) => {
    const arr: any[] = []
    const scatterArr: number[] = []
    data.forEach((item: any, index: number) => {
      seriesDataMap.legendData.push({
        name: item.name,
        itemStyle: {
          color: index === 0 ? '#4BD2DE' : '#4a6d70',
        },
      })
      seriesDataMap.seriesData.push({
        name: item.name,
        type: 'bar',
        animationDuration: 3000,
        data: item.data,
        itemStyle: {
          color:
            index === 0
              ? '#4BD2DE'
              : {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(25,35,55,0.2)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(35, 79, 88, 0.8)', // 100% 处的颜色
                    },
                  ],
                },
          borderColor: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            {
              offset: 0,
              color: '#266373',
            },
            {
              offset: 1,
              color: '#192337',
            },
          ]),
          borderRadius: index === 0 ? [8, 2, 2, 2] : [0, 2, 2, 0],
        },
        label: {
          show: true,
          color: index === 0 ? '#0D1321' : '#BCCBE0',
          position: 'insideRight',
          fontFamily: 'AvenirLight',
        },
        barWidth: 20,
        barGap: 0,
      })
      item.data.forEach((row: any) => {
        arr.push(row.value)
      })
    })
    // 找到对应地铁站最小值
    const fillArr: number[] = new Array(data[0].data.length).fill(1)
    fillArr.forEach((item: number, index: number) => {
      const infoArr: number[] = []
      arr.forEach((row: number, idx: number) => {
        if (idx % fillArr.length === index) {
          infoArr.push(row)
        }
      })
      scatterArr.push(Math.min(...infoArr))
    })
    seriesDataMap.seriesData.push({
      type: 'scatter',
      symbolSize: [2, 40],
      symbol: 'rect',
      data: scatterArr,
      itemStyle: {
        color: '#2965FF',
      },
      animationDuration: 3000,
    }),
      data?.length &&
        data[0].data.forEach((row: { label: string }) => {
          seriesDataMap.brandNames.push(row.label as never)
        })
    const option: EChartsOption = {
      title: {
        subtext: '单位(辆)',
        left: 'left',
        bottom: 32,
        padding: 0,
        subtextStyle: {
          fontSize: '12px',
          color: '#DEDEDE',
        },
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(66, 75, 92, 0.2)',
          },
        },
        backgroundColor: '#424B5C',
        className: 'custom-echart-tooltip',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
        formatter: function (params: any) {
          const s1 = params[0] || {}
          const s2 = params[1] || {}
          return (
            '<div>' +
            '<span style="display:block;margin-bottom:10px;font-size:14px;">' +
            s1.name +
            '</span>' +
            '<div>' +
            '<span style="display:inline-block;margin-right:4px;width:3px;height:8px;border-radius:1px;background-color:' +
            s1.color +
            ';"></span>' +
            ' ' +
            '<span style="color: #BCCBE0;">' +
            s1.seriesName +
            '</span>' +
            '<span style="display:inline-block;margin-right:20px;"></span>' +
            '<span style="float:right;font-family: AvenirLight;">' +
            validateValue(s1.value) +
            '</span>' +
            '<span style="display:block;margin-bottom:8px;"></span>' +
            '</div>' +
            '<div>' +
            '<span style="display:inline-block;margin-right:4px;width:3px;height:8px;background-color:#4a6d70;"></span>' +
            ' ' +
            '<span style="color: #BCCBE0;">' +
            s2.seriesName +
            '</span>' +
            '<span style="display:inline-block;margin-right:20px;"></span>' +
            '<span style="float:right;font-family: AvenirLight;">' +
            validateValue(s2.value) +
            '</span>' +
            '</div>' +
            '</div>'
          )
        },
      },
      legend: {
        itemWidth: 8,
        itemHeight: 8,
        bottom: 0,
        textStyle: { color: '#BCCBE0' },
        data: seriesDataMap.legendData,
      },
      grid: {
        top: 0,
        left: 1,
        right: 15,
        bottom: 30,
        containLabel: true,
      },
      xAxis: [
        {
          type: 'value',
          name: '',
          splitNumber: 4,
          axisLabel: {
            show: true,
            color: '#DEDEDE',
            fontFamily: 'AvenirLight',
            formatter: function (value: any) {
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + '万'
              } else if (value >= 10000000) {
                value = value / 10000000 + '千万'
              }
              return value
            },
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(116, 131, 153, 0.4)',
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'category',
          data: seriesDataMap.brandNames,
          axisLabel: {
            color: '#FFFFFF',
          },
          axisTick: {
            show: false,
          },
        },
      ],
      series: seriesDataMap.seriesData,
    }
    chartRef.current?.setOption(option)
    setTimeout(() => {
      chartRef.current?.resize()
    }, 3000)
  }

  return (
    <ContentBox title="地铁站客流量" showTitleDecoration={true}>
      <div
        ref={containerRef}
        style={{
          width: '100%',
          color: '#fff',
          marginTop: 20,
        }}
      ></div>
    </ContentBox>
  )
}

export default PassengerCount
