import { districtLevelMap, orderlyTypeMap } from '@/utils/consts'

export const minZoom = 5
export const cityZoom = 10
export const areaZoom = 12
export const streetZoom = 14
export const bikeZoom = 16
export const clusterMaxZoom = 18

export const cityAreaLevel = [districtLevelMap.city, districtLevelMap.area]

export const iconMap = {
  mt_bike_riding: { label: '美团单车骑行', icon: require('_root/assets/img/mt_bike_riding.png') },
  mt_bike_parking: { label: '美团单车停放', icon: require('_root/assets/img/mt_bike_parking.png') },
  mt_spock_riding: {
    label: '美团电单车骑行',
    icon: require('_root/assets/img/mt_spock_riding.png'),
  },
  mt_spock_parking: {
    label: '美团电单车停放',
    icon: require('_root/assets/img/mt_spock_parking.png'),
  },
  hb_bike_riding: {
    label: '哈啰单车骑行',
    icon: require('_root/assets/img/hb_bike_riding.png'),
  },
  hb_bike_parking: {
    label: '哈啰单车停放',
    icon: require('_root/assets/img/hb_bike_parking.png'),
  },
  hb_spock_riding: {
    label: '哈啰电单车骑行',
    icon: require('_root/assets/img/hb_spock_riding.png'),
  },
  hb_spock_parking: {
    label: '哈啰电单车停放',
    icon: require('_root/assets/img/hb_spock_parking.png'),
  },
  qj_bike_riding: {
    label: '青桔单车骑行',
    icon: require('_root/assets/img/qj_bike_riding.png'),
  },
  qj_bike_parking: {
    label: '青桔单车停放',
    icon: require('_root/assets/img/qj_bike_parking.png'),
  },
  qj_spock_riding: {
    label: '青桔电单车骑行',
    icon: require('_root/assets/img/qj_spock_riding.png'),
  },
  qj_spock_parking: {
    label: '青桔电单车停放',
    icon: require('_root/assets/img/qj_spock_parking.png'),
  },
  dh_bike_riding: { label: '大哈单车骑行', icon: require('_root/assets/img/dh_bike_riding.png') },
  dh_bike_parking: { label: '大哈单车停放', icon: require('_root/assets/img/dh_bike_parking.png') },
  dh_spock_riding: {
    label: '大哈电单车骑行',
    icon: require('_root/assets/img/dh_spock_riding.png'),
  },
  dh_spock_parking: {
    label: '大哈电单车停放',
    icon: require('_root/assets/img/dh_spock_parking.png'),
  },
  qf_bike_riding: { label: '骑福单车骑行', icon: require('_root/assets/img/qf_bike_riding.png') },
  qf_bike_parking: { label: '骑福单车停放', icon: require('_root/assets/img/qf_bike_parking.png') },
  qf_spock_riding: {
    label: '骑福电单车骑行',
    icon: require('_root/assets/img/qf_spock_riding.png'),
  },
  qf_spock_parking: {
    label: '骑福电单车停放',
    icon: require('_root/assets/img/qf_spock_parking.png'),
  },
  cy_bike_riding: { label: '车云单车骑行', icon: require('_root/assets/img/cy_bike_riding.png') },
  cy_bike_parking: { label: '车云单车停放', icon: require('_root/assets/img/cy_bike_parking.png') },
  cy_spock_riding: {
    label: '车云电单车骑行',
    icon: require('_root/assets/img/cy_spock_riding.png'),
  },
  cy_spock_parking: {
    label: '车云电单车停放',
    icon: require('_root/assets/img/cy_spock_parking.png'),
  },
  qtcx_bike_riding: {
    label: '蜻蜓单车骑行',
    icon: require('_root/assets/img/qtcx_bike_riding.png'),
  },
  qtcx_bike_parking: {
    label: '蜻蜓单车停放',
    icon: require('_root/assets/img/qtcx_bike_parking.png'),
  },
  qtcx_spock_riding: {
    label: '蜻蜓电单车骑行',
    icon: require('_root/assets/img/qtcx_spock_riding.png'),
  },
  qtcx_spock_parking: {
    label: '蜻蜓电单车停放',
    icon: require('_root/assets/img/qtcx_spock_parking.png'),
  },
  qd_bike_riding: {
    label: '骑电单车骑行',
    icon: require('_root/assets/img/hb_bike_riding.png'),
  },
  qd_bike_parking: {
    label: '骑电单车停放',
    icon: require('_root/assets/img/hb_bike_parking.png'),
  },
  qd_spock_riding: {
    label: '骑电电单车骑行',
    icon: require('_root/assets/img/hb_spock_riding.png'),
  },
  qd_spock_parking: {
    label: '骑电电单车停放',
    icon: require('_root/assets/img/hb_spock_parking.png'),
  },
  lsm_bike_riding: {
    label: '锂市民单车骑行',
    icon: require('_root/assets/img/lsm_bike_riding.png'),
  },
  lsm_bike_parking: {
    label: '锂市民单车停放',
    icon: require('_root/assets/img/lsm_bike_parking.png'),
  },
  lsm_spock_riding: {
    label: '锂市民电单车骑行',
    icon: require('_root/assets/img/lsm_spock_riding.png'),
  },
  lsm_spock_parking: {
    label: '锂市民电单车停放',
    icon: require('_root/assets/img/lsm_spock_parking.png'),
  },
  xl_bike_riding: {
    label: '小遛单车骑行',
    icon: require('_root/assets/img/xl_bike_riding.png'),
  },
  xl_bike_parking: {
    label: '小遛单车停放',
    icon: require('_root/assets/img/xl_bike_parking.png'),
  },
  xl_spock_riding: {
    label: '小遛电单车骑行',
    icon: require('_root/assets/img/xl_spock_riding.png'),
  },
  xl_spock_parking: {
    label: '小遛电单车停放',
    icon: require('_root/assets/img/xl_spock_parking.png'),
  },
  bd_bike_riding: {
    label: '北斗禹州单车骑行',
    icon: require('_root/assets/img/bd_bike_riding.png'),
  },
  bd_bike_parking: {
    label: '北斗禹州单车停放',
    icon: require('_root/assets/img/bd_bike_parking.png'),
  },
  bd_spock_riding: {
    label: '北斗禹州电单骑行',
    icon: require('_root/assets/img/bd_spock_riding.png'),
  },
  bd_spock_parking: {
    label: '北斗禹州电单停放',
    icon: require('_root/assets/img/bd_spock_parking.png'),
  },
  yzx_bike_riding: {
    label: '禹州行单车骑行',
    icon: require('_root/assets/img/yzx_bike_riding.png'),
  },
  yzx_bike_parking: {
    label: '禹州行单车停放',
    icon: require('_root/assets/img/yzx_bike_parking.png'),
  },
  yzx_spock_riding: {
    label: '禹州行电单骑行',
    icon: require('_root/assets/img/yzx_spock_riding.png'),
  },
  yzx_spock_parking: {
    label: '禹州行电单停放',
    icon: require('_root/assets/img/yzx_spock_parking.png'),
  },
  yjn_bike_riding: {
    label: '禹见你单车骑行',
    icon: require('_root/assets/img/yjn_bike_riding.png'),
  },
  yjn_bike_parking: {
    label: '禹见你单车停放',
    icon: require('_root/assets/img/yjn_bike_parking.png'),
  },
  yjn_spock_riding: {
    label: '禹见你电单骑行',
    icon: require('_root/assets/img/yjn_spock_riding.png'),
  },
  yjn_spock_parking: {
    label: '禹见你电单停放',
    icon: require('_root/assets/img/yjn_spock_parking.png'),
  },
}

export const violationIconMap = Object.keys(iconMap).reduce((prev, key) => {
  const newKey = key + '_violation'
  const value = iconMap[key as keyof typeof iconMap]
  prev[newKey] = {
    label: (value.label.includes('骑行') ? '开锁' : '关锁') + '-告警',
    icon: require(`_root/assets/img/bike_violation/${newKey}.png`),
  }
  return prev
}, {} as any)

// 用于图例和停车点围栏样式
export const parkingIcon = {
  parking: {
    label: '停车点',
    icon: require('_root/assets/img/parking_spot.png'),
    color: 'rgba(43, 96, 255, 0.06)',
    border: 'rgba(43, 96, 255, 1)',
  },
  parkingDeposit: {
    label: '停车点(淤积点)',
    icon: require('_root/assets/img/parking_spot_deposit.png'),
    color: 'rgba(43, 96, 255, 0.06)',
    border: 'rgba(43, 96, 255, 1)',
  },
  operateArea: {
    label: '运营区',
    icon: require('_root/assets/img/operation_area.png'),
    color: 'rgba(43, 96, 255, 0.06)',
    border: 'rgba(43, 96, 255, 1)',
  },
  noParking: {
    label: '禁停区',
    icon: require('_root/assets/img/no_parking_spot.png'),
    color: 'rgba(255, 41, 59, 0.06)',
    border: 'rgba(255, 41, 59, 1)',
    dashed: true,
  },
}

// 用于停车点区分icon
export const noParkingAreaIcon = {
  no_parking_all: { icon: require('_root/assets/img/noParkingArea/no_parking_all.png') },
  no_parking_default: { icon: require('_root/assets/img/noParkingArea/no_parking_default.png') },
  no_parking_hb: { icon: require('_root/assets/img/noParkingArea/no_parking_hb.png') },
  no_parking_qd: { icon: require('_root/assets/img/noParkingArea/no_parking_hb.png') },
  no_parking_mt: { icon: require('_root/assets/img/noParkingArea/no_parking_mt.png') },
  no_parking_qj: { icon: require('_root/assets/img/noParkingArea/no_parking_qj.png') },
  no_parking_qf: { icon: require('_root/assets/img/noParkingArea/no_parking_qf.png') },
  no_parking_dh: { icon: require('_root/assets/img/noParkingArea/no_parking_dh.png') },
  no_parking_cy: { icon: require('_root/assets/img/noParkingArea/no_parking_cy.png') },
  no_parking_qtcx: { icon: require('_root/assets/img/noParkingArea/no_parking_qtcx.png') },
  no_parking_lsm: { icon: require('_root/assets/img/noParkingArea/no_parking_lsm.png') },
  no_parking_xl: { icon: require('_root/assets/img/noParkingArea/no_parking_xl.png') },
  no_parking_bd: { icon: require('_root/assets/img/noParkingArea/no_parking_bd.png') },
  no_parking_yzx: { icon: require('_root/assets/img/noParkingArea/no_parking_yzx.png') },
  no_parking_yjn: { icon: require('_root/assets/img/noParkingArea/no_parking_yjn.png') },
}

export const parkingAreaIcon = {
  parking_all: { icon: require('_root/assets/img/parkingArea/parking_all.png') },
  parking_default: { icon: require('_root/assets/img/parkingArea/parking_default.png') },
  parking_hb: { icon: require('_root/assets/img/parkingArea/parking_hb.png') },
  parking_qd: { icon: require('_root/assets/img/parkingArea/parking_hb.png') },
  parking_mt: { icon: require('_root/assets/img/parkingArea/parking_mt.png') },
  parking_qj: { icon: require('_root/assets/img/parkingArea/parking_qj.png') },
  parking_qf: { icon: require('_root/assets/img/parkingArea/parking_qf.png') },
  parking_dh: { icon: require('_root/assets/img/parkingArea/parking_dh.png') },
  parking_cy: { icon: require('_root/assets/img/parkingArea/parking_cy.png') },
  parking_qtcx: { icon: require('_root/assets/img/parkingArea/parking_qtcx.png') },
  parking_lsm: { icon: require('_root/assets/img/parkingArea/parking_lsm.png') },
  parking_xl: { icon: require('_root/assets/img/parkingArea/parking_xl.png') },
  parking_bd: { icon: require('_root/assets/img/parkingArea/parking_bd.png') },
  parking_yzx: { icon: require('_root/assets/img/parkingArea/parking_yzx.png') },
  parking_yjn: { icon: require('_root/assets/img/parkingArea/parking_yjn.png') },
}

export const parkingDepositIcon = {
  parking_deposit_all: { icon: require('_root/assets/img/parkingDeposit/parking_deposit_all.png') },
  parking_deposit_default: {
    icon: require('_root/assets/img/parkingDeposit/parking_deposit_default.png'),
  },
  parking_deposit_hb: { icon: require('_root/assets/img/parkingDeposit/parking_deposit_hb.png') },
  parking_deposit_qd: { icon: require('_root/assets/img/parkingDeposit/parking_deposit_hb.png') },
  parking_deposit_mt: { icon: require('_root/assets/img/parkingDeposit/parking_deposit_mt.png') },
  parking_deposit_qj: { icon: require('_root/assets/img/parkingDeposit/parking_deposit_qj.png') },
  parking_deposit_qf: { icon: require('_root/assets/img/parkingDeposit/parking_deposit_qf.png') },
  parking_deposit_dh: { icon: require('_root/assets/img/parkingDeposit/parking_deposit_dh.png') },
  parking_deposit_cy: { icon: require('_root/assets/img/parkingDeposit/parking_deposit_cy.png') },
  parking_deposit_qtcx: {
    icon: require('_root/assets/img/parkingDeposit/parking_deposit_qtcx.png'),
  },
  parking_deposit_lsm: {
    icon: require('_root/assets/img/parkingDeposit/parking_deposit_lsm.png'),
  },
  parking_deposit_xl: {
    icon: require('_root/assets/img/parkingDeposit/parking_deposit_xl.png'),
  },
  parking_deposit_bd: {
    icon: require('_root/assets/img/parkingDeposit/parking_deposit_bd.png'),
  },
  parking_deposit_yzx: {
    icon: require('_root/assets/img/parkingDeposit/parking_deposit_yzx.png'),
  },
  parking_deposit_yjn: {
    icon: require('_root/assets/img/parkingDeposit/parking_deposit_yjn.png'),
  },
}

export const operationLegendIcon = {
  operator: {
    rs: 'operationOperatorInfo',
    label: '运维人员',
    icon: require('_root/assets/img/operationLegend/legend_operator.png'),
  },
  operation_car: {
    rs: 'operationOperateCarInfo',
    label: '运维车辆',
    icon: require('_root/assets/img/operationLegend/legend_operation_car.png'),
  },
}

export const operatorIcon = {
  operator_mt: { icon: require('_root/assets/img/operator/operator_mt.png') },
  operator_qj: { icon: require('_root/assets/img/operator/operator_qj.png') },
  operator_hb: { icon: require('_root/assets/img/operator/operator_hb.png') },
  operator_qd: { icon: require('_root/assets/img/operator/operator_hb.png') },
  operator_qf: { icon: require('_root/assets/img/operator/operator_qf.png') },
  operator_dh: { icon: require('_root/assets/img/operator/operator_dh.png') },
  operator_cy: { icon: require('_root/assets/img/operator/operator_cy.png') },
  operator_qtcx: { icon: require('_root/assets/img/operator/operator_qtcx.png') },
  operator_lsm: { icon: require('_root/assets/img/operator/operator_lsm.png') },
  operator_xl: { icon: require('_root/assets/img/operator/operator_xl.png') },
  operator_bd: { icon: require('_root/assets/img/operator/operator_bd.png') },
  operator_yzx: { icon: require('_root/assets/img/operator/operator_yzx.png') },
  operator_yjn: { icon: require('_root/assets/img/operator/operator_yjn.png') },
}

export const operationCarIcon = {
  operation_car_mt: { icon: require('_root/assets/img/operationCar/operation_car_mt.png') },
  operation_car_qj: { icon: require('_root/assets/img/operationCar/operation_car_qj.png') },
  operation_car_hb: { icon: require('_root/assets/img/operationCar/operation_car_hb.png') },
  operation_car_qd: { icon: require('_root/assets/img/operationCar/operation_car_hb.png') },
  operation_car_qf: { icon: require('_root/assets/img/operationCar/operation_car_qf.png') },
  operation_car_dh: { icon: require('_root/assets/img/operationCar/operation_car_dh.png') },
  operation_car_cy: { icon: require('_root/assets/img/operationCar/operation_car_cy.png') },
  operation_car_qtcx: { icon: require('_root/assets/img/operationCar/operation_car_qtcx.png') },
  operation_car_lsm: { icon: require('_root/assets/img/operationCar/operation_car_lsm.png') },
  operation_car_xl: { icon: require('_root/assets/img/operationCar/operation_car_xl.png') },
  operation_car_bd: { icon: require('_root/assets/img/operationCar/operation_car_bd.png') },
  operation_car_yzx: { icon: require('_root/assets/img/operationCar/operation_car_yzx.png') },
  operation_car_yjn: { icon: require('_root/assets/img/operationCar/operation_car_yjn.png') },
}

export const layerControlIcon = {
  layer_control: require('_root/assets/img/layer_control.png'),
  layer_legend: require('_root/assets/img/layer_legend.png'),
  bike_search: require('_root/assets/img/bike_search.png'),
  layer_time: require('_root/assets/img/layer_time.png'),
  layer_arrow: require('_root/assets/img/alarm_arrow.png'),
}

export const mapControlIcon = {
  map_full: require('_root/assets/img/map_full.png'),
  map_unfull: require('_root/assets/img/map_unfull.png'),
  map_pos: require('_root/assets/img/map_pos.png'),
  map_increase: require('_root/assets/img/map_increase.png'),
  map_minus: require('_root/assets/img/map_minus.png'),
  // map_reload: require('_root/assets/img/map_reload.png'),
}

export const parkingSpotEditIcon = {
  nameEdit: {
    label: '名称编辑',
    icon: require('_root/assets/img/parking_spot_name_edit.png'),
  },
}

export const handleIcon = {
  eye_open: {
    icon: require('_root/assets/img/handleIcon/eye_open.png'),
  },
}

export const areaDonutIcon = {
  donut_operator: {
    icon: require('_root/assets/img/areaDonutIcon/donut_operator.png'),
  },
  donut_operation_car: {
    icon: require('_root/assets/img/areaDonutIcon/donut_operation_car.png'),
  },
}

export const patrolAreaOrderlyIcon = {
  orderly: {
    icon: require('_root/assets/img/patrol/patrol_orderly_area.png'),
    color: 'rgba(0, 159, 212, 0.08)',
    border: 'rgba(0, 133, 178, 0.7)',
  },
  unorderly: {
    icon: require('_root/assets/img/patrol/patrol_unorderly_area.png'),
    color: 'rgba(218, 76, 54, 0.08)',
    border: 'rgba(218, 76, 54, 0.7)',
  },
}

export const cameraIcon = {
  [orderlyTypeMap.orderly]: {
    icon: require('_root/assets/img/camera_orderly.png'),
    label: '秩序整齐',
  },
  [orderlyTypeMap.unorderly]: {
    icon: require('_root/assets/img/camera_unorderly.png'),
    label: '秩序散乱',
  },
  [orderlyTypeMap.offline]: {
    icon: require('_root/assets/img/camera_offline.png'),
    label: '不在线',
  },
}

export const alarmIcon = {
  alarm_notice: {
    icon: require('_root/assets/img/alarm_notice.png'),
  },
  alarm_arrow: {
    icon: require('_root/assets/img/alarm_arrow.png'),
  },
}
