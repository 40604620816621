import request from '@/utils/request'

export const queryCityInfo = (data: RequestData) => {
  return request('patrolCenterQueryCityInfo', {
    data,
  })
}

export const queryAreaRegion = (data: RequestData) => {
  return request('dataCenterQueryAreaRegion', {
    data,
  })
}

export const queryKeyAreaInfo = (data: RequestData) => {
  return request('patrolCenterQueryKeyAreaInfo', {
    data,
  })
}

export const queryParkingAreaInfo = (data: RequestData) => {
  return request('dataCenterParkingAreaInfo', {
    data,
  })
}

export const querySearchParkingArea = (data: RequestData) => {
  return request('patrolCenterSearchParkingArea', {
    data,
  })
}
