import React, { useState } from 'react'

import StatisticIndicator from './statisticIndicator'
import Map from './map'
import { useAppSelector, useTimingRequest, useUserResources } from '@/utils/hooks'
import request from '@/utils/request'

import type { MapAreaData, StatisticInfo } from './type'

const defMapInfo = {
  bike: {} as Record<string, MapAreaData>,
  spock: {} as Record<string, MapAreaData>,
}

const defStatisticInfo = {
  bike: {} as StatisticInfo,
  spock: {} as StatisticInfo,
}

export const Context = React.createContext({
  mapInfo: defMapInfo,
  statisticInfo: defStatisticInfo,
})

const Center = () => {
  const [app] = useAppSelector(state => [state.app])
  const [mapInfo, setMapInfo] = useState(defMapInfo)
  const [statisticInfo, setStatisticInfo] = useState(defStatisticInfo)
  const [dataCenterRs] = useUserResources(resource => [resource.dataCenter])

  useTimingRequest(() => {
    if (app.currentEntity.entityId && app.currentArea.adCode && app.currentBiz.businessType) {
      dataCenterRs.todayStatistic && queryStatisticInfo()
      queryMapInfo()
    }
  }, [
    app.currentEntity.entityId,
    app.currentArea.adCode,
    app.currentBiz.businessType,
    dataCenterRs,
  ])

  // useEffect(() => {
  //   // 显示地图有两种情况，一是左上角选择行政区到区以下，todo 这里待确定到市还是区，是否需要后端加个字段
  //   // 二是下钻到市或区(此时会设置showMap属性)
  //   if (curArea.level >= districtLevelMap.city || curArea.showMap) {
  //     setShowMap(true)
  //   } else {
  //     setShowMap(false)
  //   }
  // }, [curArea])

  const queryStatisticInfo = () => {
    const data = {
      entityId: app.currentEntity.entityId,
      adCode: app.currentArea.adCode,
      businessType: app.currentBiz.businessType,
    }
    request('dataCenterQueryStatisticInfo', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setStatisticInfo(res.data)
      }
    })
  }

  const queryMapInfo = () => {
    const data = {
      entityId: app.currentEntity.entityId,
      adCode: app.currentArea.adCode,
      businessType: app.currentBiz.businessType,
    }
    request('dataCenterQueryMapInfo', { data }).then(res => {
      if (res.code === 0 && res.data) {
        setMapInfo(res.data)
      }
    })
  }

  return (
    <div style={{ height: '100%' }} className="nobg">
      <Context.Provider value={{ mapInfo, statisticInfo }}>
        <div className="columnStartStretch" style={{ margin: '0 12px', height: '100%' }}>
          <StatisticIndicator></StatisticIndicator>
          {/* {dataCenterRs.todayStatistic && <div style={{ marginTop: '140px' }}></div>} */}
          {/* {showMap ? <Map></Map> : <ChinaMap></ChinaMap>} */}
          <Map></Map>
        </div>
      </Context.Provider>
    </div>
  )
}

export default Center
