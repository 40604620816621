import { useEffect, useState, useRef } from 'react'
import { Select, Tooltip, Popover } from 'antd'
import debounce from 'lodash/debounce'

const PlaceSearch = ({ map }) => {
  const searchRef = useRef(null)
  const suggestRef = useRef(null)
  const markersRef = useRef(null)
  const [suggestionList, setSuggestionList] = useState([])
  const [value, setValue] = useState()

  const infoWindowListRef = useRef(Array(10))

  useEffect(() => {
    if (map) {
      searchRef.current = new TMap.service.Search({ pageSize: 10 })
      suggestRef.current = new TMap.service.Suggestion({
        pageSize: 10,
        // region: '北京', // 限制城市范围
        // regionFix: true, // 搜索无结果时是否固定在当前城市
      })
      markersRef.current = new TMap.MultiMarker({
        map: map,
        geometries: [],
      })
      return () => {
        clearLayer()
      }
    }
  }, [map])

  const clearLayer = () => {
    infoWindowListRef.current.forEach(infoWindow => {
      infoWindow.close()
    })
    infoWindowListRef.current.length = 0
    markersRef.current.setGeometries([])
  }

  const querySuggestions = keyword => {
    suggestRef.current
      .getSuggestions({ keyword, location: map.getCenter() })
      .then(result => {
        // 以当前所输入关键字获取输入提示
        const data = result.data?.map(item => ({
          ...item,
          value: item.id,
          label: (
            <Popover placement="left" content={`${item.title}, ${item.address}`}>
              <div
                className="singleHidden"
                style={{ maxWidth: 260 }}
              >{`${item.title}, ${item.address}`}</div>
            </Popover>
          ),
        }))
        setSuggestionList(data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleSearch = newValue => {
    if (newValue) {
      querySuggestions(newValue)
    } else {
      setSuggestionList([])
    }
  }

  const setSuggestion = opt => {
    infoWindowListRef.current.forEach(infoWindow => {
      infoWindow.close()
    })
    infoWindowListRef.current.length = 0
    markersRef.current.setGeometries([])
    markersRef.current.updateGeometries([
      {
        id: '0', // 点标注数据数组
        position: opt.location,
      },
    ])
    const infoWindow = new TMap.InfoWindow({
      map: map,
      position: opt.location,
      content: `<h3>${opt.title}</h3><p>地址：${opt.address}</p>`,
      offset: { x: 0, y: -50 },
    })
    infoWindowListRef.current.push(infoWindow)
    map.setCenter(opt.location)
    markersRef.current.on('click', e => {
      infoWindowListRef.current[Number(e.geometry.id)].open()
    })
  }

  const handleChange = (newValue, opt) => {
    setValue(newValue)
    setSuggestion(opt)
  }

  return (
    <Select
      showSearch
      value={value}
      className="input-item map-search-input"
      placeholder="请输入地点搜索"
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={debounce(handleSearch, 300)}
      onChange={handleChange}
      notFoundContent={null}
      options={suggestionList}
    />
  )
}

export default PlaceSearch
