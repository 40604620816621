import { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react'
import { message } from 'antd'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

import MapControl from './MapControl'
import AreaDonut from './AreaDonut'
import KeyAreaLayer from './KeyAreaLayer'
import LayerControl from './LayerControl'
import RegionLayer from './RegionLayer'
import { Context } from '../index'
import { getBoundsPolygon } from '@/utils/util'
import { PATROLCENTER } from '@/redux/actionTypes'
import { businessLineMap, districtLevelMap } from '@/utils/consts'
import { useAppSelector, useAppDispatch, useUserResources } from '@/utils/hooks'
import {
  cityZoom,
  minZoom,
  streetZoom,
  bikeZoom,
  cityAreaLevel,
} from '@/pages/dataCenter/center/map/consts'
import {
  updateCityInfo,
  updateParkingAreaInfo,
  updateSearchParkingAreaInfo,
  initParkingAreaInfo,
  initCityInfo,
  initKeyAreaInfo,
  updateKeyAreaInfo,
  updateSearchKeyAreaInfo,
} from '@/redux/reducers/patrolCenter'
import { updateFullScreen } from '@/redux/reducers/app'
import { searchType } from './LayerControl'
import ParkingAreaLayer from './ParkingAreaLayer'

import type { LayerParam, SearchParam } from './LayerControl'
import type { KeyArea, ParkingArea } from '@/redux/reducers/patrolCenter'

import './index.less'

const Map = () => {
  const mapRef = useRef<any>()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const dispatch = useAppDispatch()
  const [app, patrolCenter] = useAppSelector(state => [state.app, state.patrolCenter])
  const [patrolCenterRs] = useUserResources(resource => [resource.patrolCenter])
  const { statisticIndicatorHeight } = useContext(Context)

  const [layerParam, setLayerParam] = useState<LayerParam>({} as LayerParam)
  const [searchParam, setSearchParam] = useState<SearchParam>({} as SearchParam)

  const baseParam = useMemo(() => {
    return {
      entityId: app.currentEntity.entityId,
      adCode: app.currentArea.adCode,
      businessType: app.currentBiz.businessType,
    }
  }, [app])

  const getPolygon = useCallback(() => {
    return mapRef.current ? getBoundsPolygon(mapRef.current) : ''
  }, [mapRef.current])

  const getZoom = useCallback(() => {
    return mapRef.current.getZoom()
  }, [mapRef.current])

  const setZoom = useCallback(
    zoom => {
      mapRef.current.setZoom(zoom)
    },
    [mapRef.current]
  )

  useEffect(() => {
    if (containerRef.current && app.currentArea && !mapRef.current) {
      mapRef.current = new TMap.Map(containerRef.current, {
        center: new TMap.LatLng(
          app.currentArea?.lat || 43.38398750167718,
          app.currentArea?.lng || 87.3419600982555
        ),
        zoom: 10,
        // @ts-ignore
        zooms: [10, 20],
        scrollable: true,
        showControl: false,
        mapStyleId: 'style 6',
      })
    }
  }, [containerRef, app.currentArea])

  // 监听实体、监管范围、业务线，重置搜索信息
  useEffect(() => {
    return () => {
      clearSearchInfo()
    }
  }, [app.currentEntity.entityId, app.currentArea.adCode, app.currentBiz.businessType])

  // 监听行政区变化，重置中心点
  useEffect(() => {
    let isSubscribed = true
    if (app.currentArea.lat && app.currentArea.lng && mapRef.current) {
      const center = new TMap.LatLng(app.currentArea.lat, app.currentArea.lng)
      isSubscribed && mapRef.current.setCenter(center)
    }
    return () => {
      isSubscribed = false
    }
  }, [app.currentArea])

  useEffect(() => {
    if (mapRef.current) {
      const handleZoomChange = debounce(viewChange, 500)
      mapRef.current.on('dragend', handleZoomChange)
      mapRef.current.on('zoom', handleZoomChange)
      window.addEventListener('resize', handleZoomChange)
      return () => {
        mapRef.current.off('dragend', handleZoomChange)
        mapRef.current.off('zoom', handleZoomChange)
        window.removeEventListener('resize', handleZoomChange)
      }
    }
  }, [app.currentArea, layerParam, searchParam, patrolCenterRs])

  // =================================== 请求数据 start ===================================

  // 搜索框输入条件搜索
  useEffect(() => {
    if (searchParam?.value) {
      paramSearch()
    } else {
      clearSearchInfo()
    }
  }, [searchParam])

  useEffect(() => {
    if (app.currentArea.level === districtLevelMap.city) {
      if (getZoom() !== cityZoom) {
        setZoom(cityZoom)
      } else {
        viewChange()
      }
    } else if (app.currentArea.level >= districtLevelMap.area) {
      if (app.currentArea.level === districtLevelMap.area && getZoom() !== streetZoom) {
        setZoom(app.currentArea.zoom || streetZoom)
      } else if (app.currentArea.level === districtLevelMap.street && getZoom() !== bikeZoom) {
        setZoom(app.currentArea.zoom || bikeZoom)
      } else {
        viewChange()
      }
    }
  }, [app.currentArea])

  useEffect(() => {
    if (layerParam) {
      viewChange()
    }
  }, [layerParam])

  // 点击图层区域点位
  useEffect(() => {
    if (!isEmpty(layerParam.areaTypeList)) {
      queryParkingAreaInfo()
    } else {
      clearParkingInfo()
    }
  }, [layerParam.areaTypeList])

  // 区域范围
  useEffect(() => {
    if (app.currentArea.adCode) {
      queryAreaRegion()
    }
  }, [app.currentArea])

  // 搜索关键点位
  useEffect(() => {
    if (app.currentFence.fenceId) {
      querySearchKeyAreaInfo()
    } else if (patrolCenter.searchKeyAreaInfo.length && !app.currentFence.fenceId) {
      clearSearchKeyAreaInfo()
    }
  }, [app.currentFence.fenceId])

  // =================================== 清除图层区域 ===================================

  const clearParkingInfo = () => {
    dispatch(updateParkingAreaInfo(initParkingAreaInfo))
  }

  const clearSearchParkingInfo = () => {
    dispatch(updateSearchParkingAreaInfo(initParkingAreaInfo))
  }

  const clearCityInfo = () => {
    dispatch(updateCityInfo(initCityInfo))
  }

  const clearKeyAreaInfo = () => {
    dispatch(updateKeyAreaInfo(initKeyAreaInfo))
  }

  const clearSearchKeyAreaInfo = () => {
    dispatch(updateSearchKeyAreaInfo(initKeyAreaInfo))
  }

  const clearSearchInfo = () => {
    clearSearchParkingInfo()
    clearSearchKeyAreaInfo()
  }

  // =================================== 执行请求数据方法 ===================================

  const queryKeyAreaOrCityInfo = () => {
    // 当前zoom大于bikeZoom，查询重点区域信息
    if (getZoom() >= bikeZoom) {
      clearCityInfo()
      queryKeyAreaInfo()
    } else {
      // 当前zoom小于bikeZoom，分两种情况
      // 一是当前行政区为市、区查询聚合信息
      // 二是当前行政区为街道，需要查询重点区域信息
      if (cityAreaLevel.includes(app.currentArea.level)) {
        clearKeyAreaInfo()
        queryCityInfo()
      } else if (getZoom() > minZoom) {
        clearCityInfo()
        queryKeyAreaInfo()
      }
    }
  }

  // 执行条件搜索
  const paramSearch = () => {
    if ([searchType.parkingId, searchType.parkingName].includes(searchParam?.type)) {
      searchParkingArea()
    }
  }

  const viewChange = () => {
    queryKeyAreaOrCityInfo()
    queryParkingAreaInfo()
  }

  const queryParkingAreaInfo = () => {
    const polygon = getPolygon()
    if (polygon && !isEmpty(layerParam.areaTypeList)) {
      dispatch({
        type: PATROLCENTER.QUERY_PARKING_AREA_INFO,
        payload: {
          ...baseParam,
          layerParam,
          polygon,
          zoom: getZoom() >= streetZoom ? 1 : 0,
        },
      })
    }
  }

  const queryCityInfo = () => {
    if (!isEmpty(layerParam)) {
      dispatch({
        type: PATROLCENTER.QUERY_CITY_INFO,
        payload: {
          ...baseParam,
          layerParam,
        },
      })
    }
  }

  const querySearchKeyAreaInfo = () => {
    const polygon = getPolygon()
    if (polygon && !isEmpty(layerParam)) {
      dispatch({
        type: PATROLCENTER.QUERY_KEY_AREA_INFO,
        payload: {
          ...baseParam,
          polygon,
          layerParam,
          searchString: app.currentFence.fenceId,
        },
        cb: (keyAreas?: KeyArea[]) => {
          if (!keyAreas?.length) {
            message.warning('暂无搜索结果')
          }
        },
      })
    }
  }

  const queryKeyAreaInfo = () => {
    const polygon = getPolygon()
    if (polygon && !isEmpty(layerParam)) {
      dispatch({
        type: PATROLCENTER.QUERY_KEY_AREA_INFO,
        payload: {
          ...baseParam,
          polygon,
          layerParam,
        },
      })
    }
  }

  const queryAreaRegion = () => {
    if (app.currentArea.adCode) {
      dispatch({
        type: PATROLCENTER.QUERY_AREA_REGION,
        payload: {
          adCode: app.currentArea.adCode,
          entityId: app.currentEntity.entityId,
        },
      })
    }
  }

  const searchParkingArea = (parkingArea?: ParkingArea) => {
    dispatch({
      type: PATROLCENTER.QUERY_SEARCH_PARKING_AREA,
      payload: {
        ...baseParam,
        parkingPointValue: searchParam.value,
      },
      extra: { parkingArea },
      cb: (parkingArea?: ParkingArea) => {
        if (parkingArea) {
          mapRef.current.setCenter(
            new TMap.LatLng(parkingArea.centerPoint[1], parkingArea.centerPoint[0])
          )
          mapRef.current.setZoom(20)
          setTimeout(() => {
            viewChange()
          }, 0)
        } else {
          message.warning('暂无搜索结果')
        }
      },
    })
  }

  const handleLayerControlChange = (state: LayerParam) => {
    setLayerParam(state)
  }

  const handleSearch = (param: SearchParam) => {
    setSearchParam(param)
  }

  return (
    <div className="patrol-center-map">
      <div className="map-container" ref={containerRef}></div>
      <div
        className={classnames('layer-control-container', {
          'layer-control-container-double': app.currentBiz.businessType === businessLineMap.double,
        })}
        style={{ top: statisticIndicatorHeight + 12 + 'px' }}
      >
        <LayerControl
          map={mapRef.current}
          onSearch={handleSearch}
          onChange={handleLayerControlChange}
        ></LayerControl>
      </div>
      <div className="bottom-control betweenEnd">
        <MapControl
          fullScreen={app.fullScreen}
          map={mapRef.current}
          onFullScreenClick={isFull => {
            dispatch(updateFullScreen(isFull))
          }}
        ></MapControl>
      </div>
      <RegionLayer map={mapRef.current} areaInfo={patrolCenter.areaInfo}></RegionLayer>
      <AreaDonut map={mapRef.current} cityInfo={patrolCenter.cityInfo} />
      {!!patrolCenter.keyAreaInfo.length && (
        <KeyAreaLayer map={mapRef.current} keyAreaInfo={patrolCenter.keyAreaInfo} />
      )}
      {!!patrolCenter.searchKeyAreaInfo.length && (
        <KeyAreaLayer searched map={mapRef.current} keyAreaInfo={patrolCenter.searchKeyAreaInfo} />
      )}
      {!!layerParam.areaTypeList?.length && (
        <ParkingAreaLayer
          map={mapRef.current}
          parkingAreaInfo={patrolCenter.parkingAreaInfo}
          searchParkingArea={searchParkingArea}
          queryParkingAreaInfo={queryParkingAreaInfo}
        ></ParkingAreaLayer>
      )}
    </div>
  )
}

export default Map
